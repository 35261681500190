/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid,
} from '@material-ui/core';

import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import TablaDinamica from '../../componentes/TablaDinamica';

import axios from '../../configuraciones/axios';
import endpoints, { SECCION, PERFILES } from '../../configuraciones/endpoints';

import { findPropertysEmpty, requiredFIle, trim } from '../../utilidades/functions';

import estatus from '../../constantes/estatus';

import styles from './styles';
import { LETTERSSPECIALCHARACTER } from '../../utilidades/regex';

const cabeceros = [
  { label: 'Sección', key: 'Seccion' },
  { label: 'Módulo', key: 'Modulo' },
];

const Perfil = ({ history, match }) => {
  const classes = styles();

  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [perfil, setPerfil] = useState({});
  const [seccion, setSeccion] = useState([])
  const { params: { _id } } = match;

  // esta funcion cumple como regreso a la pagina anterior [Pathname]
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/perfiles',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };
  // llama la funcion regresar
  const cancelar = useCallback(regresar, []);

  // funcion que sirve para guardar y hacer la creacion o edicion de los perfiles en la base de datos
  const guardar = useCallback(() => {
    setIsSubmit(true);
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmPerfil'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0)
      return;


    const metodo = _id ? 'put' : 'post';
    const obj = trim(perfil);
    const nuevoObj = {
      ...obj, PerfilModulos: seccion
        .flatMap(({ Modulos }) => Modulos
          .filter(Modulos => Modulos.checked))
        .map(({ Enum, checked }) => ({ Enum, Activo: checked }))
    };
    axios[metodo](PERFILES, nuevoObj).then(regresar);
  }, [perfil, seccion]);

  // carga de base de datos Usuario nuevo: [Tabla], Edicion: [Nombre, descripcion, permisos] 
  const consultarDatosIniciales = async () => {
    const promesas = [
      axios.get(endpoints.base.url(SECCION)),
    ];
    const [secciones] = await Promise.all(promesas)
    setSeccion(secciones)
    if (_id) {
      const promesasPerfil = [
        axios.get(endpoints.base.url(PERFILES, _id)),
      ];
      const [perfiles] = await Promise.all(promesasPerfil)
      setPerfil(perfiles)

      const permisos = perfiles.PerfilModulos.map(({ Enum, Activo, }) => ({ ID: Enum, checked: Activo, }))

      // Cargar valores a tabla desde base de datos
      const cargaEdicion = secciones.map((element) => {
        let moduloPermiso = [];
        element.Modulos.forEach((mod) => {
          const permiso = permisos.find((permiso) => permiso.ID === mod.Enum)
          moduloPermiso.push({
            ...mod,
            ...permiso,
          })
        })
        return {
          ...element,
          Modulos: [...moduloPermiso]
        }
      })
      setSeccion(cargaEdicion)
    }
  }
  useEffect(() => {
    consultarDatosIniciales();
  }, [_id]);
  return <Default
    titulo={Boolean(_id) ? 'Editar perfil' : 'Nuevo perfil'} //INTENTO
    placeHolder={''}
    mostrarCabeceroFormulario={false}
    guardar={guardar}
    cancelar={cancelar}>

    <Grid container spacing={2} id="frmPerfil">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4} >
          <Grid item xs={12}>
            <TextField
              label="Nombre de perfil"
              onChange={setPerfil}
              name="Nombre"
              value={perfil.Nombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.Nombre}
              helperText={requiredFIle(isSubmit, formErrors, 'Nombre')}
              required
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              label="Descripción"
              type="textarea"
              rows={6}
              onChange={setPerfil}
              name="Descripcion"
              value={perfil.Descripcion ?? ''}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 200, regexonchange: 'true' }}
              error={formErrors.Descripcion}
            />
          </Grid>

          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setPerfil}
              name="Habilitado"
              value={perfil.Habilitado}
              error={formErrors.Habilitado}
              placeHolder={'Seleccione'}
              helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8} className={classes.columnaIzq}>
        <TablaDinamica
          headers={cabeceros}
          secciones={seccion}
          onChange={setSeccion}
          isHandleChange
        />
      </Grid>

    </Grid>
  </Default>;
};

export default React.memo(Perfil);