import { useCallback, useEffect, useState } from 'react';

/**
 * Custom hook para realizar un scroll infinito en una lista
 * @param {Promise<{{
 *  docs: any[],
 *  hasNextPage: boolean,
 * }}>} fetch
 * @param {{
 *  registrosPorPagina?: number,
 *  reverso?: boolean
 * }} registrosPorPagina
 * @returns {{
 *  data: { rows: any[], count: number },
 *  setData: () => void,
 *  cambioPagina: () => void,
 *  reiniciarScroll: () => void,
 * }}
 */
const useInfiniteScroll = (fetch, { registrosPorPagina = 20, reverso = false }) => {
  const [finScroll, setFinScroll] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [data, setData] = useState({ rows: [], count: 0 });

  /**
   * Función que realiza la consulta de información
   */
  const consultar = useCallback(async () => {
    if (finScroll) return;
    const { docs, hasNextPage } = await fetch(pagina, registrosPorPagina);
    if (!hasNextPage) {
      setFinScroll(true);
    }
    if (reverso) {
      setData((prev) => ({ count: prev.count + docs.length, rows: [...docs, ...prev.rows]}));
    } else setData((prev) => ({ count: prev.count + docs.length, rows: [...prev.rows, ...docs]}));
  }, [fetch, pagina, registrosPorPagina, finScroll, reverso]);

  /**
   * Función que reinicia el scroll
   */
  const reiniciarScroll = useCallback(() => {
    setData({ rows: [], count: 0 });
    setFinScroll(false);
    setPagina(1);
  }, []);

  /**
   * Función que reacciona al cambio de página
   * @param {() => void} callback función que se va a ejecutar en caso de cambio de página
   */
  const cambioPagina = useCallback((callback) => {
    if (!finScroll) {
      setPagina((prev) => prev + 1);
      callback && callback();
    }
  }, [finScroll]);

  /**
   * Carga de la primera página de datos
   */
  const consultaInicial = useCallback(async () => {
    const { docs, hasNextPage } = await fetch(1, registrosPorPagina)
    setData({ count: docs.length, rows: docs });
    if (!hasNextPage) {
      setFinScroll(true);
    }
  }, [fetch, registrosPorPagina]);

  useEffect(() => {
    if (pagina !== 1) consultar();
  }, [pagina, fetch, consultar]);

  useEffect(() => {
    consultaInicial();
  }, [consultaInicial]);

  return {
    data,
    setData,
    cambioPagina,
    reiniciarScroll,
  };
};

export default useInfiniteScroll;
