import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    marginTop: 5,
  },
  '@global': {
    '.MuiPopover-paper': {
      border: `1px solid ${paleta.textField.border}`,
      justifyContent: 'flex-end',
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    minHeight: 45,
    height: 45,
    marginTop: 10,
    backgroundColor: paleta.textField.backgroundColor,
    borderRadius: 8,
    outline: 'none',
    width: '95%',

    '& fieldset': {
      border: `1px solid ${paleta.textField.border} !important`,
    },
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
      '& fieldset': {
        border: `1px solid ${paleta.textField.border} !important`,
      },
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${paleta.textField.border}`,
    },
    '& .MuiSelect-root': {
      minHeight: 45,
      height: 45,
      padding: '0px !important',
      display: 'flex',
      alignItems: 'center',
    },
  },
  chips: {
    width: 83,
    maxWidth: 83,
    backgroundColor: '#409a8e',
    fontWeight: 'bold',
    color: '#FFF',
    borderRadius: 15,
    height: 25,
    marginLeft: 2,
    padding: 0,
    cursor: 'pointer',
  },
}));

export default styles;
