
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from "react-webcam";
import ReactCrop from 'react-image-crop'
import styles from './styles';
import { IconButton, Dialog, DialogContent, DialogActions, DialogTitle, CircularProgress } from '@material-ui/core';
import { MdOutlineCameraAlt } from 'react-icons/md';
import { FiArrowLeft } from 'react-icons/fi';

import Typography from '../Typography';
import Boton from '../Button';
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from '../../hooks/useDebounceEffect';
import canvasPreview from '../../utilidades/canvasPreview';
import propTypes from 'prop-types';




export const PhotoCrop = ({
  onChange,
}) => {
  const [saved, setSaved] = useState();                               //Flag de guardado 
  const [avatarImg, setAvatarImg] = useState()                        //Imagen que se enviará al padre
  const [camara, setCamara] = useState(false);                        //Abrir ventana de camara
  const [camaraLoading, setCamaraLoading] = useState(false)           //Flag para cuando abre la camara

  const imgRef = useRef(null)
  const imageCropRef = useRef(null)                                   //Imagen con crop
  const [completedCrop, setCompletedCrop] = useState()                //Crop elegido
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);                         //Foto tomada 
  const [crop, setCrop] = useState({                                  //Dimensiones del crop
    unit: 'px',
    x: 75,
    y: 0,
    width: 450,
    height: 450
  });

  const classes = styles();

  /** Activar Camara */
  const photo = () => {
    setImgSrc(null);
    setCamaraLoading(true);
    setCamara(true);
    setCompletedCrop(crop)
  }

  /** Cerrar camara */
  const closeCamara = () => {
    setCamara(!camara);
  }

  /**Guardar foto de camara */
  const saveCropPhoto = () => {
    const canvas = document.getElementById('my-canvas');
    const img = new Image();
    img.src = canvas.toDataURL();

    // Agregar el elemento imagen al DOM
    setAvatarImg(img.src);
    setSaved(true)
    setCamara(!camara);
  }

  /** Manejador de camara */
  useEffect(() => {
    webcamRef.current = new Webcam();
  }, []);

  /** Tomar fotografia con camara */
  const capture = useCallback((e) => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setCompletedCrop(crop)
    setAvatarImg();
  }, [webcamRef, setImgSrc, crop]);


  /** Crop de imagen */
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        imageCropRef.current
      ) {
        canvasPreview(
          imgRef.current,
          imageCropRef.current,
          completedCrop,
        )
      }
    },
    100,
    [completedCrop],
  )

  /** Llenar variable que se enviara al padre (Guardado) */
  useEffect(() => {
    if(saved){
    onChange(avatarImg);
    setCamara(false);
    setSaved(false)
    };
  }, [avatarImg, onChange, saved]);

  return (
    <div>
      <IconButton
        className={classes.botonDentro}
        onClick={photo}
      >
        <MdOutlineCameraAlt style={{ marginRight: '10px' }} />

        <Typography variant='subtitle1' className={classes.textoCentrado}>
          Tomar una foto
        </Typography>
      </IconButton>
      <Dialog
        open={camara}
        onClose={closeCamara}
        maxWidth='sm'
      >
        <DialogTitle
          style={{ textAlign: 'center', padding: '16px 0px 20px 0px', }}
        >
          <IconButton
            onClick={closeCamara}
          >
            <FiArrowLeft />
          </IconButton>
          Cámara
          <hr />
        </DialogTitle>
        <DialogContent
          style={{ padding: 0 }}
        >
          {!imgSrc ?
            <div className={classes.spinnerBox}>
              {camaraLoading && <CircularProgress className={classes.spinner} />}
              <Webcam
                ref={webcamRef}
                onUserMedia={() => setCamaraLoading(false)}
                screenshotFormat="image/jpeg"
                width={600}
                mirrored />
            </div>
            :
            <div>
              <ReactCrop
                maxWidth={450}
                circularCrop
                crop={crop}
                onChange={c => setCrop(c)}
                aspect={1}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img
                  ref={imgRef}
                  src={imgSrc}
                  alt=''
                />
              </ReactCrop>
            </div>
          }
        </DialogContent>
        <DialogActions
          className={classes.botonCentrado}
        >
          {!imgSrc ?
            <Boton
              label="Tomar Foto"
              onClick={capture}
            />
            :
            <Boton
              label="Guardar foto"
              onClick={saveCropPhoto}
            />
          }
          {completedCrop && (
            <div>

              <canvas
                hidden
                id={'my-canvas'}
                ref={imageCropRef}
              />
            </div>)
          }
        </DialogActions>
      </Dialog>
      </div>
  );
}

PhotoCrop.propTypes = {
    /* Mandará la imagen al padre en base 64*/
    onChange: propTypes.func
  
}

export default React.memo(PhotoCrop);