import { makeStyles } from "@material-ui/core";
import paleta from "../../configuraciones/paleta";

 const styles = makeStyles(() => ({
  HeaderBox:{
    display: 'grid',
    gridTemplateColumns: '1fr 110px',
  },
  boton:{
    borderColor: paleta.bar.primary,
    backgroundColor: paleta.bar.primary,
    width: '49px',
    marginTop: 10,
    color: 'white',
    borderRadius: '0px 8px 8px 0px',
    '&:hover': {
      backgroundColor: paleta.bar.secondary,
      borderColor: paleta.bar.secondary,
    },
  },
  tableBox:{
    paddingBottom: '300px'
  },
  buttonbox:{
    paddingLeft: '225px'
  },
  SiNoButtons:{
    display: 'flex',
    justifyContent: 'flex-end'
  },
  BotonNo:{
    marginLeft:'10px !important'
  }
}))
export default styles;