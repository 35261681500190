/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';

import queryString from 'query-string';
import Default from '../Default';
import endpoints from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import general from '../../configuraciones/general';
import Tabla from '../../componentes/Table';
import CabeceroReporte from '../../componentes/CabeceroReporte'
import { setRegistros, setLimpiar } from '../../ducks/listados';

const FORMULARIO = 'formulario';

const ReportesGrupal = forwardRef(({
  titulo, subtitulo, mostrarLista,
  datosLista, history, location, rowSelect,
  placeHolder, cabeceros, apiKey, iconDetail, isChecked,
  parametros, customActions, mostrarCabeceroListado,
  mostrarCabeceroFormulario, guardar, cancelar, showActions,
  customClass, rowErrors, customCabecero, filtrosBusqueda,
  actionDetail, mostrar, ID, customDetailAction, params, toDo,
  cabeceroReporte, seleccionable, onSelect, onSelectAll, busquedaPorEmpleado,
  pdf, excel, onFiltrosChange, customPdfAction, onClearSelections,
  apiKeyExcel,
}, ref) => {
  const dispatch = useDispatch();
  const { registros } = useSelector(store => store.listados);
  const [filtros, setFiltros] = useState({
    empresas: [],
    sucursales: [],
    departamentos: [],
    puestos: [],
    fechaInicio: '',
    fechaFin: ''
  });
  const paginaRef = useRef(1);

  const consultarListado = () => {
    const endpointUrl = ID ? endpoints.base.url(apiKey, ID) : endpoints.base.url(apiKey);
    axios
      .get(endpointUrl, {
        params: {
          ...filtros,
          ...parametros,
          registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
          pagina: paginaRef.current || 1,
        },
      })
      .then((e) => {
        if (e.docs.length === 0 && paginaRef.current > 1) {
          history.push({ search: `?pagina=${paginaRef.current - 1}` });
        }
        dispatch(setRegistros(e));
      });
    if (onClearSelections) onClearSelections();
  };

  const buscar = useCallback((txtBusqueda, nuevosFiltros) => {
    history.replace({ search: `?pagina=${1}` });
    setFiltros((current) => ({ ...current, txtBusqueda, ...nuevosFiltros }));
  }, [history]);

  const obtenerPath = (id = "") => `${location.pathname}/${FORMULARIO}/${id}`;

  const irAFormulario = useCallback(({ _id }) => {
    const query = queryString.parse(window.location.hash.split("?")[1] || "");
    history.push({
      pathname: obtenerPath(_id || ""),
      state: { pagina: query.pagina || 1 },
    });
  }, []);

  const eliminar = useCallback(({ _id }) => {
    axios.delete(endpoints.base.url(apiKey, _id)).then(consultarListado);
  }, []);

  const handleSelectAll = useCallback((todos) => {
    const docs = registros.docs.map(
      (elem) => ({ ...elem, isSelected: !todos }),
    );
    dispatch(setRegistros({ ...registros, docs }));
    if (onSelectAll) onSelectAll(docs);
  }, [registros]);

  const handleSelect = useCallback((row, indexSelected) => {
    const docs = registros.docs.map(
      (elem, index) => {
        if (indexSelected !== index) return elem;
        const estaSeleccionado = !elem.isSelected;
        return { ...elem, isSelected: estaSeleccionado }
      },
    );
    dispatch(setRegistros({ ...registros, docs }));
    if (onSelect) onSelect(row, indexSelected);
  }, [registros])

  useImperativeHandle(ref, () => ({
    refrescarListado() {
      consultarListado();
    },
  }), []);

  useEffect(() => {
    const query = queryString.parse(location.search);
    paginaRef.current = query.pagina;
    consultarListado();
    if (onFiltrosChange) onFiltrosChange(filtros);
    return () => {
      dispatch(setLimpiar());
    }
  }, [filtros, location]);

  return (
    <Default
      titulo={titulo}
      placeHolder={placeHolder}
      buscar={buscar}
      guardar={guardar}
      cancelar={cancelar}
      agregar={irAFormulario}
      subtitulo={subtitulo || `${registros?.totalDocs || 0} elemento(s)`}
      mostrarLista={mostrarLista}
      datosLista={datosLista}
      customCabecero={customCabecero}
      mostrarCabeceroListado={mostrarCabeceroListado}
      mostrarCabeceroFormulario={mostrarCabeceroFormulario}
      filtrosCabeceros={filtrosBusqueda}
      mostrar={mostrar}
      params={params}
      toDo={toDo}
    >
      {
        cabeceroReporte && (
          <CabeceroReporte
            reporteVacio={!registros?.docs?.length}
            onChange={setFiltros}
            busquedaPorEmpleado={busquedaPorEmpleado}
            pdf={pdf}
            excel={excel}
            apiKeyExcel={apiKeyExcel}
            customPdfAction={customPdfAction}
          />
        )
      }
      <Tabla
        headers={cabeceros}
        rows={registros?.docs || []}
        count={registros?.totalDocs || 0}
        onDelete={actionDetail ? false : !mostrarCabeceroFormulario && eliminar}
        onEdit={actionDetail ? false : !mostrarCabeceroFormulario && irAFormulario}
        onDetail={actionDetail && !mostrarCabeceroFormulario && (customDetailAction ? customDetailAction : irAFormulario)}
        moreActions={customActions}
        customClass={customClass}
        showActions={showActions}
        rowErrors={rowErrors}
        iconDetail={iconDetail}
        isChecked={isChecked}
        rowSelect={rowSelect}
        showCheckbox={seleccionable}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
      />
    </Default>
  );
})

ReportesGrupal.propTypes = {
  /** Titulo que llevara el listado */
  titulo: propTypes.string,
  /**  */
  history: propTypes.object.isRequired,
  /** */
  location: propTypes.object.isRequired,
  /** Texto default que llevara el buscador */
  placeHolder: propTypes.string,
  /** Titulo de las columnas junto con su informacion */
  cabeceros: propTypes.array.isRequired,
  /** Apikey donde realizará la consulta de datos */
  apiKey: propTypes.string.isRequired,
  /** Flag para mostrar los cabeceros*/
  mostrarCabeceroFormulario: propTypes.bool,
  /** Flag para los estilos de cabecero reporte*/
  cabeceroReporte: propTypes.bool,
  /** Parametros para realizar la busqueda*/
  parametros: propTypes.object,
  /** Agregar acciones*/
  customActions: propTypes.arrayOf(propTypes.shape({
    onClick: propTypes.string,
    icon: propTypes.string,
    validate: propTypes.func,
    transform: propTypes.func
  })),
  /** Flag para mosrar el cabecero listado*/
  mostrarCabeceroListado: propTypes.bool,
  /** Accion de guardado*/
  guardar: propTypes.func,
  /** Accion para cancelar*/
  cancelar: propTypes.func,
  /** Mostrar acciones */
  showActions: propTypes.bool,
  /** Subtitulo a mostrar */
  subtitulo: propTypes.string,
  /** Mostrar mas filtros de busqueda*/

  filtrosBusqueda: propTypes.shape({
    /** Filtro para rango de fecha (inicio -> fin) */
    fechaInicioFinal: propTypes.bool,
    /** Filtro para los tipos de evaluacion (puntual, retardo, etc..) */
    evaluacion: propTypes.bool,
    /** Filtro para los tipos de estatus (incidencia, normal) */
    estatus: propTypes.bool,
    /** Filtro para busqueda por campo de texto */
    busqueda: propTypes.bool,
    /** Boton Agregar */
    btnAgregar: propTypes.bool,
  }),

  /** Mostrar complementos de la tabla */
  mostrar: propTypes.oneOfType([propTypes.bool, propTypes.shape({
    /** Filtro para busqueda por campo de texto */
    txtBusqueda: propTypes.bool,
    /**  Subtitulo */
    Subtitulo: propTypes.bool,
    /** Boton Agregar */
    Agregar: propTypes.bool,
    /** Barra lateral (menu desplegable) */
    SideBar: propTypes.bool,
  })]),
  /** ID para realizar consultas en base del ID del usuario */
  ID: propTypes.string,
  /** Parametros para realizar la busqueda */
  params: propTypes.oneOfType([propTypes.string, propTypes.shape({
    Estatus: propTypes.string,
    Evaluacion: propTypes.string,
  })]),
  /** Funcion para realizar un accion personalizada, viene desde el padre */
  toDo: propTypes.func,
  /** Función que se va a realizar cuando se dé click al botón info */
  customDetailAction: propTypes.func,
  /** Bandera que indica si la tabla es seleccionable */
  seleccionable: propTypes.bool,
  /** Callback que se ejecuta cuando se selecciona una fila */
  onSelect: propTypes.func,
  /** Callback que se ejecuta cuando se selecciona todas las filas */
  onSelectAll: propTypes.func,
  /** Bandera que indicia si el campo de búsqueda por empleado será visible */
  busquedaPorEmpleado: propTypes.bool,
  /** Bandera que indicia si el botón para genera pdf será visible */
  pdf: propTypes.bool,
  /** Bandera que indicia si el botón para generar excel será visible */
  excel: propTypes.bool,
  /** Callback que se ejecuta cuando cambian los filtros de búsqueda */
  onFiltrosChange: propTypes.func,
  /** Acción personalizada para el PDF */
  customPdfAction: propTypes.func,
  /** Callback que se ejecuta cuando se limpian los elementos seleccionados */
  onClearSelections: propTypes.func,
  /** url de la api para generar el archivo*/
  apiKeyExcel: propTypes.string,
};

ReportesGrupal.defaultProps = {
  customActions: [],
  mostrarCabeceroListado: true,
  mostrarCabeceroFormulario: false,
  guardar: null,
  cancelar: null,
  showActions: false,
  actionDetail: false,
  cabeceroReporte: false,
  filtrosBusqueda: {
    /** Filtro para los tipos de movimiento (Entrada, salida) */
    movimiento: false,
    /** Filtro para rango de fecha (inicio -> fin) */
    fechaInicioFinal: false,
    /** Filtro para los tipos de evaluacion (puntual, retardo, etc..) */
    evaluacion: false,
    /** Filtro para los tipos de estatus (incidencia, normal) */
    estatus: false,
    /** Filtro para busqueda por campo de texto */
    busqueda: false,
    /** Boton Agregar */
    btnAgregar: false,
  },
  mostrar: {
    /** Filtro para busqueda por campo de texto */
    txtBusqueda: true,
    /**  Subtitulo */
    Subtitulo: true,
    /** Boton Agregar */
    Agregar: true,
    /** Barra lateral (menu desplegable) */
    SideBar: true,
  },
  showInfo: false,
  /** url de la api para generar el archivo*/
  apiKeyExcel: null,
};

export default ReportesGrupal;
