import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
    scrollableListContainer: {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        display: 'flex',
    },
    error: {
        color: 'red'
    },
    addButton: {
        'color': '#4ea197 !important',
        'backgroundColor': '#fff !important',
        border: 'solid 2px#4ea197 !important',
    },
    iconoAdd: {
        'color': '#4ea197 !important',
    },
}));

export default styles;