import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
    periodoItem: {
        width: '320px',
        height: '100%',
        minWidth: '320px',
    },
    error: {
        marginTop: '0.25rem',
        color: 'red',
    },
    'border': {
        borderRight: '2px solid #9bb1c4',
        marginRight: '0.5rem',
    },
}));

export default styles;