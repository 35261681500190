import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  accordion: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
}));

export default styles;