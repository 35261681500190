import { makeStyles } from "@material-ui/core";
import paleta from "../../configuraciones/paleta";

const styles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      paddingRight: theme.spacing(5),
    },
    marginTop: 5,
    width: '95%',
  },
  inputFile: {
    display: "none",
  },
  image: {
    marginTop: "10px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    // condición para establecer el tamaño máximo de la imagen en 100px
    maxWidth: ({ width }) => (width > "100px" ? "100px" : "40%"),
    maxHeight: ({ height }) => (height > "100px" ? "100px" : "40%"),
  },
  box: {
    borderColor: paleta.bar.primary,
    color: 'black',
    justifyContent: 'left',
    alignItems: 'flex-end',
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'flex',
    paddingRight: '10px',
  },
  iconDelete: {
    position: 'absolute',
    top: -25,
    bottom: 0,
    left: 2,
    fontSize: '25px',
    width: '49px',
    height: '49px',
    borderRadius: '0px 8px 8px 0px',
    color: 'white',
    background: paleta.bar.primary,
    '&:hover': {
      backgroundColor: paleta.bar.secondary,
    },
  },
  iconAdd: {
    position: 'absolute',
    top: -25,
    bottom: 0,
    left: -46,
    fontSize: '25px',
    width: '49px',
    height: '49px',
    borderRadius: '0px',
    color: 'white',
    background: paleta.bar.primary,
    '&:hover': {
      backgroundColor: paleta.bar.secondary,
    },
  },
  endAdornment: {
    position: 'relative',
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    minHeight: 45,
    marginTop: 10,
    padding: '10px',
    backgroundColor: paleta.textField.backgroundColor,
    borderRadius: 8,
    textOverflow: 'ellipsis',
    width: '100%',
    border: `1px solid ${paleta.textField.border}`,
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
    },
    '&.disabled': {
      backgroundColor: paleta.textField.backgroundColorDisabled,
    },
  },
}));
export default styles;