import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
  },
  contenedorMapa: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  mapa: {
    width: '100%',
    minHeight: 550,
    height: '100%',
    borderRadius: 8,
  },
  btnDelete: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    position: 'absolute',
    top: 70,
    right: 8,
    zIndex: 1,
    minHeight: 20,
    height: 40,
    minWidth: 18,
    width: 18,
    borderRadius: 2,
  },
  txtDireccion: {
    position: 'absolute',
    top: 7,
    left: 190,
    zIndex: 1,
    width: 250,
  },
  loadingMap: {
    display: 'grid',
    borderRadius: 8,
    height: '100%',
    backgroundColor: paleta.sidebar.active,
    border: `1px solid ${paleta.textField.border}`,
  },
  animation: {
    animation: `$myEffect 10000ms linear`,
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },

    "100%": {
      opacity: 1,
    },
  },
}));

export default styles;
