import React, { useState, useCallback, useEffect } from 'react';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import Default from '../../contenedores/Default';

import axios from '../../configuraciones/axios';

import styles from './styles';
import { TabsHeader, TabContainer } from '../../componentes/TabControl';
import endpoints, { EMPLEADOS } from '../../configuraciones/endpoints';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';
import { toast } from 'react-toastify';
import { RiCloseLine } from 'react-icons/ri';
import FormEmpleado from './EmpleadoPerfil/index'
import EmpleadoCheck from './EmpleadoCheck';
import EmpleadoAusencia from './EmpleadoAusencia';
import EmpleadoDispositivo from './EmpleadoDispositivo';
import AsistenciaEmpleado from './EmpleadoAsistencia';
import HorarioEmpleado from './EmpleadoHorario';

const Empleado = ({ history, match, location }) => {
  const classes = styles();

  const [empleado, setEmpleado] = useState({});
  const { params: { _id } } = match;

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabs = [
    { id: 0, label: 'Perfil' },
    { id: 1, label: 'Check' },
    { id: 2, label: 'Asistencia' },
    { id: 3, label: 'Ausencia' },
    { id: 4, label: 'Horario' },
    { id: 5, label: 'Dispositivo' },
  ];

  //Método para regresar a la pantalla principal de puestos (tabla)
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/check/empleados',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };
  //Método para cancelar la operacion de agregar/editar un regristro
  const cancelar = useCallback(regresar, [regresar]);

  //Método para cargar los datos de un registro
  const consultaDatosIniciales = () => new Promise((resolve, reject) => {
    //Petición para cargar los datos del registro puestos
    axios.get(endpoints.base.url(EMPLEADOS, _id))
      .then(({ empleado }) => {
        //Si el _id no existe, quiere decir que es un registro nuevo, retornamos
        if (_id === undefined) {
          return
        }
        //Si puesto no existe, mandamos un warning del elemento no encontrado
        if (empleado === null) {
          toast.warning(ELEMENTO_NO_ENCONTRADO);
          regresar();
        }
        //cargar los datos al formulario
        setEmpleado(empleado);
        resolve();
      })
      .catch(reject);
  });

  /** Consultar los datos necesarios para operar desde el back */
  useEffect(() => {
    consultaDatosIniciales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const containerVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const hidden = {
    opacity: 0,
    duration: 0.5,
  }

  const show = {
    duration: 0.5,
    ease: [0, 0.71, 0.2, 1.01]
  }

  return (
    <Default placeHolder={''} mostrarCabeceroFormulario={true} titulo="">
      <Grid container spacing={4} id="frmEmpleado">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={0} direction='column' className={classes.listados}>
            {_id && (
              <>
                <Box className={classes.cabecero}>
                  <Avatar src={empleado.Foto?.secure_url} className={classes.avatar} />
                  <p className={classes.empleado}> {empleado.NombreCompleto} </p>
                  <TabsHeader value={tabValue} handleChange={handleChangeTab} tabs={tabs} />
                  <IconButton onClick={cancelar} className={classes.iconButton}>
                    <RiCloseLine />
                  </IconButton>
                </Box>
                <AnimatePresence exitBeforeEnter>
                  {tabValue === 0 && (
                    <motion.div
                      key={0}
                      variants={containerVariants}
                      exit={hidden}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ show }}>
                      <TabContainer value={tabValue} index={0}>
                        <FormEmpleado match={{ params: { _id: _id } }} history={{ history }} />
                      </TabContainer>
                    </motion.div>
                  )}
                  {tabValue === 1 && (
                    <motion.div
                      key={1}
                      variants={containerVariants}
                      exit={hidden}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={show}>
                      <TabContainer value={tabValue} index={1}>
                        <EmpleadoCheck match={{ params: { _id: _id } }} history={history} location={ history.location } />
                      </TabContainer>
                    </motion.div>
                  )}
                  {tabValue === 2 && (
                    <motion.div
                      key={2}
                      variants={containerVariants}
                      exit={hidden}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{show}}>
                      <TabContainer value={tabValue} index={2}>
                        <AsistenciaEmpleado history={{ history } } location={ history.location } match={match}/>
                      </TabContainer>
                    </motion.div>
                  )}
                  {tabValue === 3 && (
                    <motion.div
                      key={3}
                      variants={containerVariants}
                      exit={hidden}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={show}>
                      <TabContainer value={tabValue} index={3}>
                        <EmpleadoAusencia match={{ params: { _id: _id } }} history={history} location={ history.location } />
                      </TabContainer>
                    </motion.div>
                  )}
                  {tabValue === 4 && (
                    <motion.div
                      key={4}
                      variants={containerVariants}
                      exit={hidden}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={show}>
                      <TabContainer value={tabValue} index={4}>
                        <HorarioEmpleado history={{ history } } location={ history.location } match={match}/>
                      </TabContainer>
                    </motion.div>
                  )}
                  {tabValue === 5 && (
                    <motion.div
                      key={5}
                      variants={containerVariants}
                      exit={hidden}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={show}>
                      <TabContainer value={tabValue} index={5}>
                      <EmpleadoDispositivo match={{ params: { _id } }} history={history} location={ history.location } />
                      </TabContainer>
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            )}
            {!_id && (
              <FormEmpleado match={{ params: { _id: _id } }} history={history} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Default>
  );
};

export default React.memo(Empleado);
