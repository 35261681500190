import React from 'react';
import { AppBar, Grid } from '@material-ui/core';
import styles from './styles';

const Footer = () => {
  const classes = styles();

  return (
    <AppBar className={classes.appBar}>
      <Grid container className={classes.footer}>

        <Grid item  >
          {/* <Grid item md={3} className={classes.Logo} > */}

          <img className={classes.Logo}
            alt='Logo'
            src='../../../../assets/images/Logo_check_assistance.svg'
            />
          {/* </Grid> */}
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Footer;
