export const ERROR = 'Ocurrió un error.';
export const SUCCESS = 'Acción realizada con éxito.';
export const USUARIO_NO_PERMISO = 'No cuenta con los permisos necesarios.'
export const PERFILE_SIN_PERMISOS = 'Es necesario seleccionar al menos un módulo.';
export const ELEMENTO_NO_ENCONTRADO = 'El elemento no ha sido encontrado.';
export const NO_MODIFICAR_PERMISO_SITIO = 'No se puede modificar el permiso de un sitio si el usuario es administrador.';
export const CLIMA_ERROR = 'No fue posible obtener el clima';
export const CLIMA_HISTORIAL_ERROR = 'No fue posible obtener el historial del clima';
export const CAMPOS_REQUERIDOS = 'Ambos campos son requeridos';
export const CAMPO_REQUERIDO = 'El campo es requerido';
export const PERIODO_HORARIO_INTERCEPTADO = 'Existen periodos en el horario que se interceptan';
export const CONFIGURACION_HORARIO_INVALIDA = 'Existen errores en la configuración de horario.';
export const HORARIO_SIN_PERIODOS = 'El horario debe tener al menos un periodo configurado.';
export const ERROR_HORA_ENTRADA_MAYOR = 'La hora de entrada no puede ser mayor o igual a la salida';
export const ERROR_RANGO_FECHAS = "Rango de fechas no válido";
export const ERROR_HORA_SALIDA_MENOR = 'La hora de entrada debe ser mayor a la hora de salida'
export const LIMITE_MAYOR_A_1_MB = 'Archivo mayor a 1 MB';
export const DIA_MENOR_A_ACTUAL = 'Seleccione una fecha mayor a la actual';
export const DIA_INICIO_MAYOR_A_FINAL = 'El rango entre las fechas no es válido';
export const EMPLEADO_DESHABILITADO = 'El empleado está deshabilitado';
export const ERROR_CAMBIO_HORARIO = 'No se puede cambiar el horario hasta finalizar la asistencia del día';
export const HORARIO_ELIMINADO_EMPLEADO = 'El horario del empleado no se encuentra disponible';
export const SIN_EMPLEADOS_SELECCIONADOS = 'Aún no ha seleccionado ningún empleado.';
export const SIN_REGISTROS_PARA_EXPORTAR = 'Sin registros para exportar archivo.';
export const CONTRASENAS_NO_COINCIDEN = 'Las contraseñas no coinciden';
export const CONTRASENA_INVALIDA = 'La contraseña no es valida';
