import React, { useCallback, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';

import moment from 'moment-timezone';

import Estatus from '../../../componentes/Estatus';
import Listados from '../../../contenedores/Listados';
import tipoEvaluaciones from '../../../constantes/Evaluaciones';
import DetalleAsistencia from '../../../componentes/EmpleadoInfo/DetalleAsistencia';

import { ASISTENCIA } from '../../../configuraciones/endpoints';
import { tipoEstatus } from '../../../constantes/estatus';
import { capitalizar } from '../../../utilidades/functions';

const cabeceros = [
  {
    label: 'Día', transform: ({ FechaAsistencia }) => FechaAsistencia && capitalizar(moment(FechaAsistencia).format('dddd'))
  },
  {
    label: 'Fecha', transform: ({ FechaAsistencia }) => FechaAsistencia && capitalizar(moment(FechaAsistencia).format('DD MMMM YYYY'))
  },
  {
    label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion ? Evaluacion.Nombre : null
  },
  {
    label: 'Estatus', transform: ({ Estatus: estatusRegistro }) => estatusRegistro ? <Estatus Cadena={estatusRegistro.Nombre} Habilitado={false} /> : null
  },
];

const AsistenciaEmpleado = ({ history, location, match }) => {

  const [isAsistOpen, setIsAsistOpen] = useState(false);
  const [asistencia, setAsistencia] = useState('');

  const handleInfoClick = useCallback(({ _id }) => {
    setAsistencia(_id);
    setIsAsistOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsAsistOpen(false);
  }, []);

  return (
    <>
      <Listados
        titulo=""
        ID={match.params._id}
        placeHolder=""
        history={history.history}
        location={location}
        showActions
        filtrosBusqueda={{
          fechaInicioFinal: true,
          evaluacion: true,
          estatus: true,
          tipoEvaluacion: tipoEvaluaciones.Asistencia,
          tipoEstatus: tipoEstatus.RegistroCheck,
          fechaTitulo: true,
        }}
        cabeceros={cabeceros || []}
        apiKey={ASISTENCIA}
        actionDetail
        iconDetail={MdInfoOutline}
        detailDrawer={true}
        tipoEvaluacion={'asistencia'}
        mostrar={{
          txtBusqueda: false,
          Subtitulo: false,
          Agregar: false,
          SideBar: false,
        }}
        customDetailAction={handleInfoClick}
      />
      <DetalleAsistencia
        cabeceros={cabeceros}
        isOpen={isAsistOpen}
        asistenciaId={asistencia}
        onClose={handleDrawerClose}
      />
    </>
  );
};

export default AsistenciaEmpleado;
