import { Box } from '@material-ui/core';

import propTypes from 'prop-types';

import Typography from '../../Typography';

import styles from "../styles"

const EmpleadoDetalle  = ({
    atributo, valor
}) => {
    const classes = styles();
    return (
        <Box className={classes.column}>
            <Typography className={classes.textBlue}>{atributo}</Typography>
            <Typography className={classes.textGray}>{valor}</Typography>
        </Box>
    )
}

EmpleadoDetalle.propTypes = {
    /** Propiedad que indica el nombre del átributo */
    atributo: propTypes.string,
    /** El valor del átributo */
    valor: propTypes.string
}

export default EmpleadoDetalle
