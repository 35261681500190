import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { SUCURSALES } from "../../configuraciones/endpoints";

const cabeceros = [
  { label: 'Nombre', key: 'Nombre' },
  { label: 'Empresa', transform: ({ Empresa }) => Empresa ? Empresa.Nombre : null},
  { label: 'Dirección', key: 'Direccion'},
  { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
];
const Sucursales = ({ history, location }) => {

  return <Listados
    titulo="Sucursales"
    placeHolder="Búsqueda por nombre y empresa"
    history={history}
    location={location}
    showActions
    filtrosBusqueda={{
      btnAgragar: true,
    }}
    cabeceros={cabeceros || []}
    apiKey={SUCURSALES}
  />;
};

export default Sucursales;