import React from 'react';

import moment from 'moment-timezone';

import ReportesGrupal from '../../contenedores/Reporte';
import endpoints, { REPORTE_CHECKS_GRUPAL } from '../../configuraciones/endpoints';
import { capitalizar } from '../../utilidades/functions';

const cabeceros = [
  { label: 'Empleado', transform: ({ Empleado }) => Empleado ? Empleado.NombreCompleto : null },
  { label: 'Empresa', transform: ({ Empleado }) => Empleado ? Empleado.Empresa.Nombre : null },
  { label: 'Sucursal', transform: ({ Empleado }) => Empleado ? Empleado.Sucursal.Nombre : null },
  { label: 'Departamento', transform: ({ Empleado }) => Empleado ? Empleado.Departamento.Nombre : null },
  { label: 'Puesto', transform: ({ Empleado }) => Empleado ? Empleado.Puesto.Nombre : null },
  { label: 'Fecha', transform: ({ FechaCheck }) => FechaCheck && capitalizar(moment(FechaCheck).format('DD MMMM YYYY')) },
  { label: 'Tipo check', key: 'Movimiento' },
  { label: 'Hora check', key: 'HoraCheck' },
  { label: 'Hora horario', key: 'HoraHorario' },
  { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion ? Evaluacion.Nombre : null },
];

const CheckGrupal = ({ history, location }) => {

  return (
    <>
      <ReportesGrupal
        titulo="Reporte de checks grupal"
        history={history}
        location={location}
        showActions={false}
        filtrosBusqueda={{
          empresa: true,
        }}
        mostrar={{
          txtBusqueda: false,
          Agregar: false,
          SideBar: true,
        }}
        cabeceros={cabeceros || []}
        apiKey={REPORTE_CHECKS_GRUPAL}
        apiKeyExcel={endpoints.base.url(REPORTE_CHECKS_GRUPAL, 'excel')}
        cabeceroReporte
        excel
      />
    </>
  );
};

export default CheckGrupal;

