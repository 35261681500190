import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Paper, IconButton, Checkbox, Container } from '@material-ui/core';
import { motion } from "framer-motion";

import clsx from 'clsx';
import { FiTrash } from 'react-icons/fi';
import { BsPencil } from 'react-icons/bs';
import { HiArrowRight } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import Confirmacion from '../Confirmacion';
import Typography from '../Typography';
import Paginador from '../Paginador';

import general from '../../configuraciones/general';

import styles from './styles';

const Table = ({
  headers, rows, showActions, onEdit,
  onDelete, onDetail, moreActions, showEmptyLabel, hideDelete,
  count, hidePaginator, showCheckbox, onSelectAll, rowSelect,
  onSelect, customStyles, customClass, cellStyles, isChecked,
  rowErrors, emptyMessage, showRootBorder, iconDetail, disableStyles,
  onPageChange, pagina,
}) => {
  const classes = styles({ disableStyles });
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const ajax = useSelector(({ ajax }) => (ajax));
  const [isPaginatorVisible, setIsPaginatorVisible] = useState(false);
  const Icon = iconDetail ? iconDetail : HiArrowRight; // Por defecto será el icono HiArrowRight

  useEffect(() => {
    if (rows.length === 0 && showEmptyLabel && ajax === 0) {
      setIsPaginatorVisible(false);
    } else {
      setIsPaginatorVisible(true);
    }
  }, [rows.length, showEmptyLabel, ajax])

  const customDelete = (event, row, index) => {
    setItemSelected({ ...row, index });
    setAnchorConfirmacion(event.currentTarget);
  };

  const cancelDelete = () => {
    setAnchorConfirmacion(null);
    setItemSelected(null);
  };

  const acceptDelete = () => {
    onDelete({ ...itemSelected });
    cancelDelete();
  };

  // Añade dos filas debajo de una previamente seleccionada, con la informacion de la fila
  const renderAdditionalRows = (row) => {
    if (isChecked && rowSelect === row._id) {
      // Ordenamiento de días de la semana
      const horarioDetalleOrdenado = row.Horario.HorarioDetalle
      .sort((a, b) => {
        if (a.DiaNumero === 0) return 1; // Mueve el elemento con DiaNumero 0 al final
        if (b.DiaNumero === 0) return -1; // Mantiene el orden de los demás elementos
        return a.DiaNumero - b.DiaNumero; // Ordena los elementos restantes de forma ascendente
      });
      return (
        <>
          <motion.tr
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ borderBottom: 'none' }}
          >
            <td colSpan={headers.length + 1} style={{ backgroundColor: '#DAEBE8', borderBottom: 'none !important' }}>
              {horarioDetalleOrdenado.map((detalle, index) => (
                <React.Fragment key={index}>
                  <td className={classes.rowsAdicional}>
                    <p style={{ fontWeight: 'bold', fontSize: '13px', margin: 'auto' }}>
                      {detalle.Dia.toUpperCase()}
                    </p>
                    <p style={{ fontWeight: 'lighter', fontSize: '9px', marginTop: '5px' }}>
                      ENTRADA | SALIDA
                    </p>
                  </td>
                </React.Fragment>
              ))}
            </td>
          </motion.tr>
          <motion.tr
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ borderBottom: 'none' }}
          >
            <td colSpan={headers.length + 1}>
              {horarioDetalleOrdenado.map((detalle, index) => (
                <React.Fragment key={index}>
                  <td className={classes.rowsAdicional}>
                    {detalle.Periodos.length !== 0 ? (
                      detalle.Periodos.map((periodo) => (
                        <pre key={periodo.Dia} style={{ fontWeight: 'lighter', fontSize: '9px', marginTop: '5px' }}>
                          {`${periodo.Entrada.padEnd(10)} | ${periodo.Salida.padStart(10)}`}
                        </pre>
                      ))
                    ) : (
                      <pre style={{ fontWeight: 'lighter', fontSize: '9px', marginTop: '5px' }}>
                        {`${('--:--').padEnd(13)} | ${('--:--').padStart(13)}`}
                      </pre>
                    )}
                  </td>
                </React.Fragment>
              ))}
            </td>
          </motion.tr>
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <Paper className={clsx(classes.root, customClass, hidePaginator && classes.noPaginator, showRootBorder && classes.border)} style={customStyles}>
        <Confirmacion
          anchor={anchorConfirmacion}
          onClose={cancelDelete}
          onAccept={acceptDelete}
        />
        <table className={clsx(classes.table)}>
          <thead>
            <tr>
              {showCheckbox && <th style={{ textAlign: 'center' }}>
                <Checkbox
                  checked={rows.every(({ isSelected }) => isSelected)}
                  value={rows.every(({ isSelected }) => isSelected)}
                  onClick={() => onSelectAll(rows.every(({ isSelected }) => isSelected))}
                />
              </th>}
              {headers.map((head, index) => (
                typeof head === 'object' && <th key={index} className={head.className || ''} style={{ textAlign: head.align || 'left' }}>
                  <Typography className={classes.label}>
                    {head.label}
                  </Typography>
                </th>
              ))}
              {showActions && <th>
                <Typography className={classes.label}>
                  Opciones
                </Typography>
              </th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index, arr) => (
              <>
                <tr key={index} className={
                  clsx(isChecked && rowSelect === row._id && (classes.rowAdicional),
                    classes.rowHover, {
                    [classes.rowError]: rowErrors.includes(index),
                    [classes.border]: !showRootBorder,
                  })
                }>
                  {showCheckbox && (
                    <td style={{ textAlign: 'center' }}>
                      <Checkbox
                        checked={!!row.isSelected}
                        value={!!row.isSelected}
                        onClick={() => onSelect(row, index)}
                      />
                    </td>
                  )}
                  {headers.map((head, hIndex) => (
                    typeof head === 'object' && (
                      <td
                        key={hIndex}
                        style={{
                          textAlign: head.align || 'left',
                          border: (arr.length === index + 1) && (arr.length === general.ELEMENTOS_POR_PAGINA) && 'none',
                          ...cellStyles
                        }}
                        className={clsx({
                          [classes.showActionsFalse]: !showActions,
                        })}
                      >
                        {!head.transform ? (
                          <Typography>
                            {row && row[head.key] && row[head.key] !== '' ? row[head.key] : '- - -'}
                          </Typography>
                        ) : (
                          <Typography>
                            {head.transform(row, index) || '- - -'}
                          </Typography>
                        )}
                      </td>
                    )
                  ))}
                  {showActions && (
                    <td className={classes.actions} style={{ border: (arr.length === index + 1) && (arr.length === general.ELEMENTOS_POR_PAGINA) && 'none' }}>
                      {moreActions.map(({ onClick, icon, validate, transform }, mIndex) => (
                        <div key={mIndex}>
                          {!validate || validate(row) ? (
                            <>
                              {icon ? (
                                <IconButton
                                  key={mIndex}
                                  size="small"
                                  className={clsx(classes.btnAdd, classes.btnAction)}
                                  onClick={() => onClick(row, index)}
                                >
                                  {icon}
                                </IconButton>
                              ) : (
                                <>
                                  {transform(row, index)}
                                </>
                              )}
                            </>
                          ) : null}
                        </div>
                      ))}
                      {onEdit && (
                        <IconButton
                          size="small"
                          className={clsx(classes.btnAdd, classes.btnAction)}
                          onClick={() => onEdit(row, index)}
                        >
                          <BsPencil />
                        </IconButton>
                      )}
                      {onDelete && (!hideDelete || !hideDelete(row)) && (
                        <IconButton
                          size="small"
                          className={clsx(classes.btnAdd, classes.btnAction)}
                          onClick={(e) => customDelete(e, row, index)}
                        >
                          <FiTrash />
                        </IconButton>
                      )}
                      {onDetail && (
                        <IconButton
                          size="small"
                          className={clsx(classes.btnAdd, classes.btnAction)}
                          onClick={() => onDetail(row, index)}
                        >
                          <Icon />
                        </IconButton>
                      )}
                    </td>
                  )}
                </tr>
                {isChecked && rowSelect === row._id && (renderAdditionalRows(row))}
              </>
            ))}
          </tbody>
        </table>
        {
          !isPaginatorVisible && rows.length <= 0 && (
            <div style={{
              position: 'absolute',
              top: '50%',
              width: '100%',
            }}>
              <Typography
                component="p"
                className={classes.label}
                style={{ textAlign: 'center' }}>
                {emptyMessage}
              </Typography>
            </div>
          )
        }
      </Paper>
      {
        isPaginatorVisible &&
        <Container style={{ padding: 0, marginTop: 10 }} className={classes.table}>
          {!hidePaginator && <Paginador count={count} onChange={onPageChange} pagina={pagina} />}
        </Container>
      }
    </div >
  );
};

Table.propTypes = {
  headers: propTypes.arrayOf(propTypes.shape({
    label: propTypes.string.isRequired,
    key: propTypes.string,
    transform: propTypes.func,
    align: propTypes.string,
  })),
  rows: propTypes.array,
  showActions: propTypes.bool,
  onEdit: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  onDelete: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  moreActions: propTypes.arrayOf(propTypes.shape({
    onClick: propTypes.string,
    icon: propTypes.string,
    validate: propTypes.func,
    transform: propTypes.func
  })),
  showEmptyLabel: propTypes.bool,
  hideDelete: propTypes.func,
  count: propTypes.number,
  hidePaginator: propTypes.bool,
  showCheckbox: propTypes.bool,
  onSelectAll: propTypes.func,
  onSelect: propTypes.func,
  customStyles: propTypes.object,
  cellStyles: propTypes.object,
  rowErrors: propTypes.array,
  emptyMessage: propTypes.string,
  showCustomActions: propTypes.bool,
  showInfo: propTypes.bool,
  onInfo: propTypes.func,
  disableStyles: propTypes.bool,
  onPageChange: propTypes.func,
  pagina: propTypes.number,
};

Table.defaultProps = {
  headers: [],
  rows: [],
  showActions: true,
  onEdit: null,
  onDelete: null,
  moreActions: [],
  showEmptyLabel: true,
  hideDelete: null,
  count: 0,
  hidePaginator: false,
  showCheckbox: false,
  onSelectAll: null,
  onSelect: null,
  rowErrors: [],
  emptyMessage: 'NO HAY REGISTROS PARA MOSTRAR',
  showCustomActions: false,
  showRootBorder: true,
  disableStyles: false,
};

export default React.memo(Table);
