import React, { useCallback, useEffect, useState } from 'react';

import md5 from 'md5';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import Button from '../../componentes/Button';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Typography from '../../componentes/Typography';

import axios from '../../configuraciones/axios';
import styles from './styles';
import TiposUsuario from '../../constantes/tiposUsuario';
import { findPropertysEmpty } from '../../utilidades/functions';
import endpoints, { USUARIOS } from '../../configuraciones/endpoints';
import {
  EMAIL_REGEX,
  NUMBER_REGEX,
  contrasenaRegex
} from '../../utilidades/regex';
import {
  CAMPO_REQUERIDO,
  CONTRASENAS_NO_COINCIDEN,
  CONTRASENA_INVALIDA,
} from '../../configuraciones/mensajes';

const MiCuenta = ({ history, path }) => {
  const { user: { id } } = useSelector(({ user }) => user);

  const [usuario, setUsuario] = useState({});
  const [errores, setErrores] = useState({});
  const [zonaHoraria, setZonaHoraria] = useState(null);
  const classes = styles();

  const guardar = useCallback(async () => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmCuenta'), true);
    setErrores({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0)
      return;

    const usuarioCopia = { ...usuario };

    delete usuarioCopia.Contrasena;
    delete usuarioCopia.ContrasenaRepeticion;
    delete usuarioCopia.Nombre;
    delete usuarioCopia.TipoUsuario;
    delete usuarioCopia.ZonaHoraria;

    await axios.put(USUARIOS, {
      _id: id,
      ...usuarioCopia,
    });

  }, [id, usuario]);

  const cambiarContrasena = useCallback(async () => {
    if (!usuario.Contrasena || !usuario.ContrasenaRepeticion) {
      setErrores((prev) => ({
        ...prev,
        Contrasena: !usuario.Contrasena ? CAMPO_REQUERIDO : '',
        ContrasenaRepeticion: !usuario.ContrasenaRepeticion ? CAMPO_REQUERIDO : '',
      }));
      return;
    }

    if (usuario.Contrasena !== usuario.ContrasenaRepeticion) {
      setErrores((prev) => ({
        ...prev,
        ContrasenaRepeticion: CONTRASENAS_NO_COINCIDEN,
        Contrasena: '',
      }));
      return;
    }

    if (!usuario.Contrasena.match(contrasenaRegex)) {
      setErrores((prev) => ({
        ...prev,
        Contrasena: CONTRASENA_INVALIDA,
        ContrasenaRepeticion: '',
      }));
      return;
    }

    setErrores((prev) => ({ ...prev, Contrasena: '', ContrasenaRepeticion: '' }));

    await axios.put(USUARIOS, {
      Contrasena: md5(usuario.Contrasena),
      _id: id,
    });

    setUsuario((prev) => ({ ...prev, Contrasena: '', ContrasenaRepeticion: '' }));
  }, [id, usuario.Contrasena, usuario.ContrasenaRepeticion]);

  const obtenerUsuario = useCallback(async () => {
    const usuarioResponse = await axios.get(endpoints.base.url(USUARIOS, id));

    if (usuarioResponse.Cliente) {
      setZonaHoraria(usuarioResponse.Cliente.ZonaHoraria)
    }

    setUsuario(usuarioResponse);
  }, [id]);

  useEffect(() => {
    obtenerUsuario();
  }, [obtenerUsuario]);

  return (
    <Default
      titulo={'Mi cuenta'}
      mostrarCabeceroFormulario={false}
      guardar={guardar}
    >
      <Grid container rowSpacing={2} id="frmCuenta" >
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            name="Nombre"
            label='Nombre'
            value={usuario.Nombre}
            classNameInput={classes.txtDesactivado}
            disabled
          />
        </Grid>
        {usuario.TipoUsuario === TiposUsuario.suscriptor && <Grid item xs={12} md={6} lg={4}>
          <TextField
            label="Zona horaria"
            name="ZonaHoraria"
            value={zonaHoraria}
            placeHolder="Seleccione"
            required
            disabled
          />
        </Grid>}
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            name="TipoUsuario"
            label='Tipo usuario'
            value={usuario.TipoUsuario}
            classNameInput={classes.txtDesactivado}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            name="Celular"
            label='Teléfono'
            value={usuario.Celular}
            onChange={setUsuario}
            inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true' }}
            error={errores.Celular}
            helperText={errores.Celular}
            required
            isHandleChange
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="Usuario"
              label='Correo'
              value={usuario.Usuario}
              onChange={setUsuario}
              inputProps={{ regex: EMAIL_REGEX, regexonsubmit: 'true', maxLength: 50 }}
              error={errores.Usuario}
              helperText={errores.Usuario}
              required
              isHandleChange
            />
          </Grid>

        <Grid item xs={12} mt={4}>
          <Grid container item xs={12} className={classes.contenedorContrasena} padding={4} rowSpacing={2}>
            <Grid item xs={12} mb={4}>
              <Typography component='h2'>Cambiar mi contraseña</Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                name="Contrasena"
                label='Escribe tu nueva contraseña'
                type='password'
                value={usuario.Contrasena}
                onChange={setUsuario}
                error={!!errores.Contrasena}
                helperText={errores.Contrasena}
                isHandleChange
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                name="ContrasenaRepeticion"
                label='Repite tu nueva contraseña'
                type='password'
                value={usuario.ContrasenaRepeticion}
                onChange={setUsuario}
                error={!!errores.ContrasenaRepeticion}
                helperText={errores.ContrasenaRepeticion}
                isHandleChange
              />
            </Grid>
            <Grid item xs={12} lg={6} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                classesCustom={{
                  boton: classes.btnAplicar,
                }}
                onClick={cambiarContrasena}
                label='Aplicar'
                border
              />
            </Grid>
            {(usuario.Contrasena || usuario.ContrasenaRepeticion) && <Grid item xs={12} mt={2}>
              <Typography>* 8 Caracteres mínimo, al menos una mayúscula, al menos una minúscula</Typography>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Default>
  );
}

export default MiCuenta;
