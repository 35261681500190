import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { EMPRESAS } from '../../configuraciones/endpoints';

const cabeceros = [
    { label: 'Nombre', key: 'Nombre'},
    { label: 'Razón Social', key: 'RazonSocial'},
    { label: 'Dirección', key: 'Direccion'},
    { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> }
]

const Empresas = ( { history, location} ) => {

    return <Listados
    titulo="Empresas"
    placeHolder="Búsqueda por nombre o razón s."
    history={history}
    location={location}
    showActions
    filtrosBusqueda={{
        btnAgragar: true,
    }}
    cabeceros={cabeceros}
    apiKey={EMPRESAS}
    />
}

export default Empresas;