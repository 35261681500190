import React, { useCallback, useState } from 'react';

import moment from 'moment-timezone';

import Estatus from '../../../componentes/Estatus';
import Listados from '../../../contenedores/Listados';
import { MdInfoOutline } from 'react-icons/md';
import { HORARIOEMPLEADO } from '../../../configuraciones/endpoints';
import { capitalizar } from '../../../utilidades/functions';


const cabeceros = [
  { label: 'Horario', transform: ({ Horario }) => Horario ? Horario.Nombre : null },
  { label: 'Tipo', transform: ({ Horario }) => Horario ? Horario.TipoHorario.Nombre : null },
  {
    label: 'Inicio', transform: ({ FechaInicio }) => FechaInicio && capitalizar(moment(FechaInicio).format('DD MMMM YYYY'))
  },
  {
    label: 'Fin', transform: ({ FechaFin }) => FechaFin && capitalizar(moment(FechaFin).format('DD MMMM YYYY'))
  },
  {
    label: 'Estatus',
    transform: ({ Horario }) => {
      const estatusActivo = Horario?.Habilitado === false || Horario?.Activo === false;
      const activo = !estatusActivo ? Horario?.Habilitado : null;
      return <Estatus activo={activo} />;
    }
  },
];

const HorarioEmpleado = ({ history, location, match }) => {

  const [isChecked, setIsChecked] = useState(false);
  const [horario, setHorario] = useState('');


  const handleInfoClick = useCallback(({ _id }, index) => {
    if (horario !== _id) {
      setIsChecked(false); // Cerrar las filas anteriores
      setHorario(_id); // Establecer el nuevo horario seleccionado
      setIsChecked(true); // Abrir las nuevas filas
    } else {
      setIsChecked(!isChecked); // Cambiar el estado de isChecked para abrir o cerrar las filas actuales
    }
  }, [horario, isChecked]);

  return (
    <>
      <Listados
        titulo=""
        ID={match.params._id}
        placeHolder=""
        history={history.history}
        location={location}
        showActions
        cabeceros={cabeceros || []}
        apiKey={HORARIOEMPLEADO}
        actionDetail
        iconDetail={MdInfoOutline}
        detailDrawer={true}
        mostrar={{
          txtBusqueda: false,
          Subtitulo: false,
          Agregar: false,
          SideBar: false,
        }}
        customDetailAction={handleInfoClick}
        isChecked={isChecked}
        rowSelect={horario}
      />
    </>
  );
};

export default HorarioEmpleado;
