import { useCallback, useEffect, useState, useRef, memo } from 'react';
import { HiDownload } from 'react-icons/hi';

import moment from 'moment-timezone';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';

import styles from './styles';

import axios from '../../configuraciones/axios'
import SelectChip from '../SelectChip';
import Button from '../../componentes/Button';
import TextField from '../../componentes/TextField';
import endpoints, { DEPARTAMENTOS, EMPRESAS, PUESTOS, SUCURSALES } from '../../configuraciones/endpoints';
import { DescargarDocumento } from '../../utilidades/functions';
import { ERROR_RANGO_FECHAS, SIN_REGISTROS_PARA_EXPORTAR } from '../../configuraciones/mensajes';

const CabeceroReporte = ({ onChange, busquedaPorEmpleado, pdf, excel, customPdfAction, apiKeyExcel, reporteVacio }) => {
  const classes = styles();

  const filtrosCopia = useRef({
    empresas: [],
    sucursales: [],
    departamentos: [],
    puestos: [],
  });
  const [isFilterDelete, setIsFilterDelete] = useState(false);
  // Carga inicial de filtros, este se encarga de almacenar los datos de las peticiones
  const [filtros, setFiltros] = useState({
    empresas: [],
    sucursales: [],
    departamentos: [],
    puestos: [],
  });
  // Variable encargada de almacenar los valores de los filtros seleccionados
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useState({
    empresas: [],
    sucursales: [],
    departamentos: [],
    puestos: [],
    fechaInicio: '',
    fechaFin: '',
  });
  const [txtBusqueda, setTxtBusqueda] = useState('');

  // Filtrar elementos no vacíos del array
  const RemoveEmpties = (array) => Object.values(array.filter((element) => element !== ''));

  // Consulta inicial para filtros
  const consultarFiltros = useCallback(async () => {
    // Variable para almacenar las promesas o peticiones http
    const promesas = [
      axios.get(endpoints.base.url(EMPRESAS, 'obtener-Activos')),
      axios.get(endpoints.base.url(SUCURSALES, 'obtener-Activos')),
      axios.get(endpoints.base.url(DEPARTAMENTOS, 'obtener-Activos')),
      axios.get(endpoints.base.url(PUESTOS, 'obtener-Activos')),
    ];
    // almacenamiento de respuesta de la variable promesas
    const [empresas, sucursales, departamentos, puestos] = await Promise.all(promesas);

    const mapItems = (items, empresaKey) => {
      return items.map(({ Nombre, _id, Empresa }) => ({
        label: Nombre,
        value: _id,
        ...(empresaKey ? { [empresaKey]: Empresa } : {}), // Si no existe empresaKey no se agrega atributo
      }));
    };

    // Almacenamiento de opciones, para las selecciones de filtrado
    const opcionesData = {
      empresas: mapItems(empresas.empresa),
      sucursales: mapItems(sucursales.sucursal, 'Empresa'),
      departamentos: mapItems(departamentos.departamentos, 'Empresa'),
      puestos: mapItems(puestos.puesto, 'Empresa'),
    };
    setFiltros((current) => ({ ...current, empresas: opcionesData.empresas }));
    filtrosCopia.current = opcionesData;
  }, [])

  // Función para actualizar los valores en filtro de Fechas
  const onChangeFiltros = useCallback((e) => {
    const { name, value } = e.target;
    setFiltrosSeleccionados((current) => ({ ...current, [name]: value }));
  }, []);

  // Carga inicial al dar click eliminar filtros
  const handleDeleteFilters = useCallback(() => {
    setIsFilterDelete(true);
    setFiltrosSeleccionados({
      empresas: [''],
      sucursales: [''],
      departamentos: [''],
      puestos: [''],
      fechaInicio: '',
      fechaFin: '',
    });
    setTxtBusqueda('');
    onChange({});
  }, [onChange]);

  // Función para agregar los filtros, este es pasado a un componente hijo (SelectChip)
  const handleAddFilters = useCallback((data) => {
    if (isFilterDelete) {
      setIsFilterDelete(false);
    }
    setFiltrosSeleccionados(data);
  }, [isFilterDelete])

  const generateFile = useCallback(async (tipo) => {
    if (reporteVacio) {
      return toast.warning(SIN_REGISTROS_PARA_EXPORTAR);
    }

    const empresa = RemoveEmpties(filtrosSeleccionados.empresas);
    const sucursal = RemoveEmpties(filtrosSeleccionados.sucursales);
    const departamento = RemoveEmpties(filtrosSeleccionados.departamentos);
    const puesto = RemoveEmpties(filtrosSeleccionados.puestos);
    const filter = {
      empresas: empresa,
      sucursales: sucursal,
      departamentos: departamento,
      puestos: puesto,
      fechaInicio: filtrosSeleccionados.fechaInicio,
      fechaFin: filtrosSeleccionados.fechaFin
    }

    if (customPdfAction) {
      customPdfAction(filter);
      return;
    }
    // Consulta a la API con los filtros
    const file = await axios.get(endpoints.base.url(apiKeyExcel), {
      params: filter,
      responseType: 'arraybuffer',
    });

    DescargarDocumento({
      response: file,
      tipoArchivo: tipo,
    });

  }, [apiKeyExcel, customPdfAction, filtrosSeleccionados, reporteVacio]);

  const handleGenerateFile = useCallback((tipo) => {
    return () => {
      generateFile(tipo);
    }
  }, [generateFile]);

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode !== 13) return;

    event.preventDefault();
    onChange((current) => ({ ...current, txtBusqueda }));
  }, [onChange, txtBusqueda]);

  const validarFechas = useCallback(() => {
    const { fechaFin, fechaInicio } = filtrosSeleccionados;
    if (!fechaFin || !fechaInicio) return;
    const inicio = moment(fechaInicio);
    const fin = moment(fechaFin);
    if (inicio.isAfter(fin)) return toast.error(ERROR_RANGO_FECHAS);
  }, [filtrosSeleccionados]);

  const filtrarYEnviarFiltros = useCallback((filtrosSeleccionados, onChange, validarFechas) => {
    validarFechas();
    // Almacenar filtros donde el contenido sea diferente a una cadena vacia
    const empresa = RemoveEmpties(filtrosSeleccionados.empresas);
    const sucursal = RemoveEmpties(filtrosSeleccionados.sucursales);
    const departamento = RemoveEmpties(filtrosSeleccionados.departamentos);
    const puesto = RemoveEmpties(filtrosSeleccionados.puestos);
    // Enviar filtros seleccionados al componente padre

    onChange((current) => ({
      ...current,
      empresas: empresa,
      sucursales: sucursal,
      departamentos: departamento,
      puestos: puesto,
      fechaInicio: filtrosSeleccionados.fechaInicio,
      fechaFin: filtrosSeleccionados.fechaFin,
    }));
  }, []);

  useEffect(() => {
    filtrarYEnviarFiltros(filtrosSeleccionados, onChange, validarFechas);
  }, [filtrarYEnviarFiltros, filtrosSeleccionados, onChange, validarFechas]);

  useEffect(() => {
    const empresa = Object.values(filtrosSeleccionados.empresas.filter((element) => element !== ''));
    const { current: { empresas, sucursales, departamentos, puestos } } = filtrosCopia;

    const nuevosFiltros = {};

    if (empresas?.length === 1) {
      nuevosFiltros.sucursales = sucursales;
      nuevosFiltros.departamentos = departamentos;
      nuevosFiltros.puestos = puestos;
    } else {
      const filterByEmpresa = (array) => array.filter(({ Empresa }) => empresa.includes(Empresa));
      nuevosFiltros.sucursales = filterByEmpresa(sucursales);
      nuevosFiltros.departamentos = filterByEmpresa(departamentos);
      nuevosFiltros.puestos = filterByEmpresa(puestos);
    }

    setFiltros((current) => ({ ...current, ...nuevosFiltros }));
  }, [filtrosSeleccionados.empresas])

  useEffect(() => {
    consultarFiltros();
  }, [consultarFiltros]);

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item container xs={12} lg={10}>
        <Grid item container>
          <Grid item xs={12} md={6} lg={4}>
            <SelectChip
              options={filtros.empresas}
              label="Empresa"
              nombreFiltro="empresas"
              onChange={handleAddFilters}
              filterValues={filtrosSeleccionados.empresas}
              isFilterDelete={isFilterDelete}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SelectChip
              options={filtros.sucursales}
              label="Sucursal"
              nombreFiltro="sucursales"
              onChange={handleAddFilters}
              filterValues={filtrosSeleccionados.sucursales}
              isFilterDelete={isFilterDelete}
              disabled={!filtros.sucursales.length}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SelectChip
              options={filtros.departamentos}
              label="Departamentos"
              nombreFiltro="departamentos"
              onChange={handleAddFilters}
              filterValues={filtrosSeleccionados.departamentos}
              isFilterDelete={isFilterDelete}
              disabled={!filtros.departamentos.length}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12} md={6} lg={4}>
            <SelectChip
              options={filtros.puestos}
              label="Puesto"
              nombreFiltro="puestos"
              onChange={handleAddFilters}
              filterValues={filtrosSeleccionados.puestos}
              isFilterDelete={isFilterDelete}
              disabled={!filtros.puestos.length}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={4}>
            <Grid item xs={6}>
              <TextField
                label="Fecha Inicio"
                name="fechaInicio"
                type="date"
                value={filtrosSeleccionados.fechaInicio}
                onChange={onChangeFiltros}
                inputClassName={classes.fecha}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Fecha Fin"
                name="fechaFin"
                type="date"
                value={filtrosSeleccionados.fechaFin}
                onChange={onChangeFiltros}
                inputClassName={classes.fecha}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {busquedaPorEmpleado && <TextField
              name="txtBusqueda"
              label="Empleado"
              value={txtBusqueda}
              onTextChange={setTxtBusqueda}
              onKeyDown={handleKeyDown}
            />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} lg={2} alignContent='flex-end' spacing={4}>
        <Grid item xs={6} lg={12} className={classes.actions}>
          <Button
            label="Limpiar"
            onClick={handleDeleteFilters}
            classesCustom={{ boton: classes.buttons }}
          />
        </Grid>
        <Grid item xs={6} lg={12} className={classes.actions}>
          {excel && <Button
            label="Excel"
            icono={<HiDownload />}
            onClick={handleGenerateFile('excel')}
            classesCustom={{ boton: classes.buttons }}
          />}
          {pdf && <Button
            label="PDF"
            icono={<HiDownload />}
            onClick={handleGenerateFile('pdf')}
            classesCustom={{ boton: classes.buttons }}
          />}
        </Grid>
      </Grid>
    </Grid>
  );
};

CabeceroReporte.propTypes = {
  /** Callback que se va a ejecutar cuando los filtros cambien */
  onChange: propTypes.func,
  /** Bandera que indica si el cabecero tendrá búsqueda por empleado */
  busquedaPorEmpleado: propTypes.bool,
  /** Bandera que indica si se desplegará el botón pdf */
  pdf: propTypes.bool,
  /** Bandera que indica si se desplegará el botón excel */
  excel: propTypes.bool,
  /** Callback personalizado que se va ejecutar cuando se dé click en el botón excel */
  customPdfAction: propTypes.func,
  /** Direccion URL del endpoint que genera el archivo excel */
  apiKeyExcel: propTypes.string,
  /** Validacion de registros vacios */
  reporteVacio: propTypes.bool,
};

export default memo(CabeceroReporte);
