import React, { useCallback, useEffect, useState } from 'react';
import Estatus from '../../../componentes/Estatus';
import Table from '../../../componentes/Table';
import RightDrawer from '../../../componentes/RightDrawer';
import { Box, Grid } from '@material-ui/core';
import Typography from '../../../componentes/Typography';
import Boton from '../../../componentes/Button';
import axios from '../../../configuraciones/axios';
import endpoints, { REGISTROS } from '../../../configuraciones/endpoints';

const cabecerosDrawer = [
  { label: 'Check', key: 'HoraCheck' },
  { label: 'Horario', key: 'HoraHorario' },
  { label: 'Movimiento', key: 'Movimiento' },
  { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion && Evaluacion.Nombre },
  { label: 'Estatus', transform: ({ Estatus: estatusRegistro }) => estatusRegistro && <Estatus Cadena={estatusRegistro.Nombre} Habilitado={false} /> },
]

const DetalleAsistencia = ({
    cabeceros, onClose, isOpen, asistenciaId
}) => {

  const [rowsCheck, setRowsCheck] = useState(null);
  const [rowsAsist, setRowsAsist] = useState(null);

  const consultarDatos = useCallback(async () => {
    const { registrosCheck, FechaAsistencia } = await axios.get(endpoints.base.url(REGISTROS, asistenciaId));
    setRowsCheck(registrosCheck);
    setRowsAsist(FechaAsistencia);
  }, [asistenciaId]);
  
  useEffect(() => {
    if (isOpen) consultarDatos();
  }, [consultarDatos, isOpen]);
  
  return (
    <>
      <RightDrawer
        isOpen={isOpen}
        header={
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}  >
              <Box flex={1} style={{ marginTop: '22px', display: 'flex', flexDirection: 'inherit', justifyContent: 'space-between', }}>
                <Typography
                  component="h2"
                >
                  {'Detalle de asistencia'}
                </Typography>
                <Boton
                  onClick={onClose}
                  label="Cancelar"
                  border
                />
              </Box>
            </Grid>
          </Grid>
        }
        children={
          <>
            <Table
              headers={cabeceros}
              showActions={false}
              rows={rowsAsist?.docs || []}
              count={rowsAsist?.totalDocs || 0}
              hidePaginator
              disableStyles={true}
            />
            <Table
              headers={cabecerosDrawer}
              showActions={false}
              rows={rowsCheck?.docs || []}
              count={rowsCheck?.totalDocs || 0}
              hidePaginator
            />
          </>
        }
      />
    </>
  );
};

export default DetalleAsistencia;