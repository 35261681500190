import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { EMPLEADOS } from "../../configuraciones/endpoints";
import { Avatar, Grid  } from "@material-ui/core";

const cabeceros = [
  {
    label: 'Nombre', transform: ({ NombreCompleto, Foto }) => <Grid style={{ display: 'flex' }}><Avatar src={Foto?.secure_url}
      style={{ marginRight: '20px', marginTop: 'auto', marginBottom: 'auto' }} />
      <p>{NombreCompleto}</p></Grid>
  },
  { label: 'Empresa', transform: ({ Empresa }) => Empresa ? Empresa.Nombre : null },
  { label: 'Sucursal', transform: ({ Sucursal }) => Sucursal ? Sucursal.Nombre : null },
  { label: 'Puesto', transform: ({ Puesto }) => Puesto ? Puesto.Nombre : null },
  { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
];

const Puestos = ({ history, location }) => {


  return (
    <Listados
      titulo="Empleados"
      placeHolder="Búsqueda por nombre"
      history={history}
      location={location}
      actionDetail
      showActions
      filtrosBusqueda={{
        btnAgragar: true,
      }}
      cabeceros={cabeceros || []}
      apiKey={EMPLEADOS}
    />
  );
};

export default Puestos;
