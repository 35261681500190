/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from 'react';
import moment from 'moment-timezone';

import Estatus from '../../../componentes/Estatus';
import Listados from '../../../contenedores/Listados';
import { DISPOSITIVOS } from '../../../configuraciones/endpoints';
import { EmpleadoDispositivoDrawer } from '../../../componentes/EmpleadoDispositivoDrawer';
import { capitalizar } from '../../../utilidades/functions';


const cabeceros = [

  { label: 'Modelo', transform: ({ Modelo }) => Modelo ? Modelo : null },
  { label: 'Mac Addres', transform: ({ MacAdress }) => MacAdress ? MacAdress : null },
  {
    label: 'Período activo', transform: ({ FechaInicio, FechaFin }) => {
      if (FechaInicio && FechaFin) {
        return (`${capitalizar(moment(FechaInicio).format('DD MMMM YYYY'))} /
        ${capitalizar(moment(FechaFin).format('DD MMMM YYYY'))}`
      );
      } else if (FechaInicio) {
        return (`${capitalizar(moment(FechaInicio).format('DD MMMM YYYY'))} / Actual`)
      } else
        return null;
    }
  },
  { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },

];

const EmpleadoDispositivo = ({ history, location, match }) => {
  const { params: { _id } } = match;

  const listadoRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dispositivo, setDispositivo] = useState('');

  const handleInfoClick = useCallback((id) => {
    setDispositivo(id);
    setDrawerOpen(true);
  },
    [],
  )

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    listadoRef.current.refrescarListado();
  };

  return (
    <>
      <Listados
        ref={listadoRef}
        ID={_id}
        history={history}
        location={location}
        mostrar={{
          txtBusqueda: false,
          Subtitulo: false,
          Agregar: false,
          SideBar: false,
        }}
        filtrosBusqueda={{
          habilitado: true,
        }}
        cabeceros={cabeceros || []}
        apiKey={DISPOSITIVOS}
        customDetailAction={handleInfoClick}
        showActions
        actionDetail
      />
      <EmpleadoDispositivoDrawer
        listadoRef={listadoRef}
        isOpen={drawerOpen}
        dispositivo={dispositivo}
        onClose={handleDrawerClose} />
    </>
  );
};

export default EmpleadoDispositivo;
