import React, { useEffect, useState, useCallback } from 'react';

import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';

import estatus from '../../constantes/estatus';
import Default from '../../contenedores/Default';

import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import axios from '../../configuraciones/axios';
import Typography from '../../componentes/Typography';
import TextfieldWithActions from '../../componentes/TextfieldWithActions';
import UploadImage from '../../componentes/ViewImage/UploadImage';

import endpoints, { SUCURSALES, EMPRESAS } from '../../configuraciones/endpoints';
import { EMAIL_REGEX, LETTERSSPECIALCHARACTER, NUMBER_REGEX } from '../../utilidades/regex';
import { findPropertysEmpty, objToFormData, requiredFIle } from '../../utilidades/functions';

import styles from './styles';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';

const Sucursal = ({ history, match }) => {
  const classes = styles();

  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [sucursal, setSucursal] = useState({
    Nombre: '',
    Geolocalizacion: {
      latitud: null,
      longitud: null,
      radio: 0,
    },
  });
  const [image, setImage] = useState(null);
  const [empresa, setEmpresa] = useState([]);
  const { params: { _id } } = match;

  // esta funcion cumple como regreso a la pagina anterior [Pathname]
  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/sucursales',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  }, [history]);

  // funcion que sirve para guardar y hacer la creacion o edicion de los perfiles en la base de datos
  const guardar = useCallback(async () => {
    setIsSubmit(true);

    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmSucursal'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0)
      return;

    const metodo = _id ? 'put' : 'post';

    const formData = objToFormData(sucursal);
    //Se agrega la imagen al formData
    formData.append('Imagen', image);

    await axios[metodo](endpoints.base.url(SUCURSALES), formData)
    regresar();

  }, [_id, sucursal, image, regresar]);

  //Carga los valores iniciales desde base de datos cuando un perfil se va a editar
  const consultarDatosIniciales = useCallback(async () => {
    const promesas = [
      axios.get(endpoints.base.url(EMPRESAS, '/obtener-Activos')),
    ];
    const [empresas] = await Promise.all(promesas);
    setEmpresa(empresas.empresa.map(({ Nombre, id }) => ({ label: Nombre, EmpresaID: id })));
    if (_id) {
      const sucursalResponse = await axios.get(endpoints.base.url(SUCURSALES, _id))
      if (!sucursalResponse) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
        return;
      }

      const { Empresa } = sucursalResponse;
      sucursalResponse.Empresa = (Empresa.Habilitado && Empresa.Activo) ? Empresa._id : null;
      setSucursal(sucursalResponse);
    }
  }, [_id, regresar])

  useEffect(() => {
    consultarDatosIniciales();
  }, [_id, consultarDatosIniciales]);

  const handleUpload = (logo) => {
    setImage(logo);
    //Si logo es una cadena vacia, el usuario ha eliminado la imagen
    if (logo === '') {
      //hacemos que LogoURL sea '' para eliminar el archivo que existe en el registro
      setSucursal({ ...sucursal, LogoURL: logo });
    }
  }

  return <Default
    titulo={Boolean(_id) ? 'Editar sucursal' : 'Nueva sucursal'}
    placeHolder={''}
    mostrarCabeceroFormulario={false}
    guardar={guardar}
    cancelar={regresar}>

    <Grid container spacing={6} id="frmSucursal"  >
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container className={classes.principales} spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de sucursal"
              onChange={setSucursal}
              name="Nombre"
              value={sucursal.Nombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.Nombre}
              helperText={requiredFIle(isSubmit, formErrors, 'Nombre')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Empresa"
              required
              options={empresa}
              onChange={setSucursal}
              name="Empresa"
              value={sucursal.Empresa || ''}
              valueProp={'EmpresaID'}
              error={formErrors.Empresa}
              placeHolder={'Seleccione'}
              helperText={requiredFIle(isSubmit, formErrors, 'Empresa')}
              isHandleChange
            />
          </Grid>
          <Grid item xs={12}>
            <UploadImage
              onChange={handleUpload}
              label="Logo"
              url={sucursal?.Logo?.secure_url || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextfieldWithActions
              texto="Geolocalización"
              titulo="Configuración de geolocalización"
              value={sucursal.Geolocalizacion}
              inicio={sucursal.Geolocalizacion}
              onChange={setSucursal}
              name="Geolocalizacion"
              placeHolder={'Coordenadas'}
              error={formErrors.Geolocalizacion}
              helperText={requiredFIle(isSubmit, formErrors, 'Geolocalizacion')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              options={estatus}
              onChange={setSucursal}
              name="Habilitado"
              value={sucursal.Habilitado}
              error={formErrors.Habilitado}
              helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
              placeHolder={'Seleccione'}
              isHandleChange
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid item>
          <Typography variant='subtitle1' className={classes.smallText}> Datos de contacto </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.contenedorContacto}>
          <Grid item xs={12}>
            <TextField
              label="Calle y número"
              onChange={setSucursal}
              name="CalleNumero"
              value={sucursal.CalleNumero}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.CalleNumero}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Colonia"
              onChange={setSucursal}
              name="Colonia"
              value={sucursal.Colonia}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.Colonia}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Ciudad / Municipio / Estado"
              onChange={setSucursal}
              name="CiudadMunicipioEstado"
              value={sucursal.CiudadMunicipioEstado}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.CiudadMunicipioEstado}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Teléfono"
              onChange={setSucursal}
              name="Telefono"
              value={sucursal.Telefono}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true' }}
              error={formErrors.Telefono}
              helperText={formErrors.Telefono}

            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo Electrónico"
              onChange={setSucursal}
              name="CorreoElectronico"
              value={sucursal.CorreoElectronico}
              isHandleChange
              inputProps={{ maxLength: 50, regexonsubmit: 'true', regex: EMAIL_REGEX }}
              error={formErrors.CorreoElectronico}
              helperText={formErrors.CorreoElectronico}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid item>
          <Typography variant='subtitle1' className={classes.smallText}> Responsable </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.contenedorResponsable}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setSucursal}
              name="ResponsableNombre"
              value={sucursal.ResponsableNombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.ResponsableNombre}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Puesto"
              onChange={setSucursal}
              name="ResponsablePuesto"
              value={sucursal.ResponsablePuesto}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.ResponsablePuesto}

            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Celular"
              onChange={setSucursal}
              name="ResponsableCelular"
              value={sucursal.ResponsableCelular}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true' }}
              error={formErrors.ResponsableCelular}
              helperText={formErrors.ResponsableCelular}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo Electrónico"
              onChange={setSucursal}
              name="ResponsableCorreo"
              value={sucursal.ResponsableCorreo}
              isHandleChange
              inputProps={{ maxLength: 50, regexonsubmit: 'true', regex: EMAIL_REGEX }}
              error={formErrors.ResponsableCorreo}
              helperText={formErrors.ResponsableCorreo}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Sucursal);
