/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Grid,
} from '@material-ui/core';
import md5 from 'md5';
import { toast } from 'react-toastify';

import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import Typography from '../../componentes/Typography';

import axios from '../../configuraciones/axios';
import estatus from '../../constantes/estatus';
import styles from './styles';
import TiposUsuario from '../../constantes/tiposUsuario';
import { findPropertysEmpty, requiredFIle, trim } from '../../utilidades/functions';
import { CONTRASENA_REGEX, EMAIL_REGEX, LETTERSSPECIALCHARACTER, NUMBER_REGEX } from '../../utilidades/regex';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';
import endpoints, {
  USUARIOS, PERFILES,
} from '../../configuraciones/endpoints';

const Usuario = ({ history, match }) => {
  const classes = styles();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [usuario, setUsuario] = useState({
    Nombre: '',
    Perfil: '',
    Celular: '',
  });
  const [perfiles, setPerfiles] = useState([]);
  const { params: { _id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/usuarios',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    setIsSubmit(true);
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmUsuario'), true);
    setFormErrors({ ...errors, ...regex });

    if (totalErrors > 0 || totalRegex > 0) return;

    let usuarioLogin = {
      ...usuario
    }
    if (!_id || usuario.Contrasena) {
      usuarioLogin = {
        ...usuario, Contrasena: md5(usuario.Contrasena)
      };
    }

    const metodo = _id ? 'put' : 'post';

    const nuevoUsuario = trim(usuarioLogin);
    const nuevoObj = { ...nuevoUsuario };
    axios[metodo](USUARIOS, nuevoObj).then(regresar);
  }, [usuario]);

  const handleCelularChange = useCallback((e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setUsuario({ ...usuario, Celular: e.target.value });
    }
  }, [usuario]);

  const consultaDatosIniciales = useCallback(async () => {
    axios.get(endpoints.base.url(PERFILES, '/obtener-Activos'))
      .then(async ({ perfil }) => {

        setPerfiles(perfil);

        if (_id) {
          const cargaUsuario = [
            axios.get(endpoints.base.url(USUARIOS, _id))
          ]
          const [usuarioEdicion] = await Promise.all(cargaUsuario)
          if (!usuarioEdicion) {
            toast.warning(ELEMENTO_NO_ENCONTRADO);
            regresar();
            return;
          }
          setUsuario(usuarioEdicion)
        }

      });
  }, [_id]);

  const perfilDescripcion = useMemo(() => {
    const perfil = perfiles.find(perfil => perfil._id === usuario.Perfil);
    return perfil ? perfil.Descripcion : '';

  }, [usuario.Perfil, perfiles]);


  useEffect(() => {
    consultaDatosIniciales();
  }, [consultaDatosIniciales])

  return <Default
    titulo={Boolean(_id) ? 'Editar usuario' : 'Nuevo usuario'}
    placeHolder={''}
    mostrarCabeceroFormulario={false}
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmUsuario">
      <Grid className={classes.datosGrid} item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setUsuario}
              name="Nombre"
              value={usuario.Nombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.Nombre}
              helperText={requiredFIle(isSubmit, formErrors, 'Nombre')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo electrónico"
              onChange={setUsuario}
              name="Usuario"
              value={usuario.Usuario}
              isHandleChange
              inputProps={{ regex: EMAIL_REGEX, regexonsubmit: 'true', maxLength: 50 }}
              error={formErrors.Usuario}
              helperText={usuario.Usuario ? formErrors.Usuario : requiredFIle(isSubmit, formErrors, 'Usuario')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contraseña"
              onChange={setUsuario}
              name="Contrasena"
              value={usuario.Contrasena}
              isHandleChange
              type="password"
              inputProps={{ maxLength: 20, regex: CONTRASENA_REGEX, regexonsubmit: 'true' }}
              helperText={typeof formErrors.Contrasena === 'string' ? formErrors.Contrasena : requiredFIle(isSubmit, formErrors, 'Contrasena')}
              error={formErrors.Contrasena}
              required={!_id}
            />
          </Grid>
          {usuario.Contrasena && <Grid item xs={12} mt={2}>
            <Typography>* 8 Caracteres mínimo, al menos una mayúscula, al menos una minúscula</Typography>
          </Grid>}
          <Grid item xs={12}>
            <TextField
              label="Celular"
              onChange={handleCelularChange}
              name="Celular"
              value={usuario.Celular || ''}
              inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true' }}
              error={formErrors.Celular}
              helperText={formErrors.Celular}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              options={estatus}
              onChange={setUsuario}
              name="Habilitado"
              value={usuario.Habilitado}
              error={formErrors.Habilitado}
              placeHolder={'Seleccione'}
              helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
              isHandleChange
              required
            />
          </Grid>
        </Grid>
      </Grid>
      {(!_id || usuario.TipoUsuario === TiposUsuario.secundario) && <Grid className={classes.perfilGrid} item lg={4} md={5} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid className={classes.heading} item xs={12}>
            <Typography> Acceso / Permiso </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ColorBack}>
            <Grid item xs={12}>
              <Select
                className={classes.perfilItem}
                label="Perfil"
                labelProp="Nombre"
                valueProp="_id"
                options={perfiles}
                name="Perfil"
                onChange={setUsuario}
                error={formErrors.Perfil}
                value={usuario.Perfil || ''}
                isHandleChange
                helperText={requiredFIle(isSubmit, formErrors, 'Perfil')}
                required
              />
            </Grid>
            <Grid className={classes.perfilDescripcion} item xs={12} >
              {usuario.Perfil && (
                <Typography>
                  {perfilDescripcion ? perfilDescripcion : ''}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>}
    </Grid>
  </Default>;
};

export default React.memo(Usuario);
