import Estatus from '../../componentes/Estatus';
import Listados from "../../contenedores/Listados"
import { HORARIOS } from "../../configuraciones/endpoints"

const cabeceros = [
    { label: 'Nombre', key: 'Nombre' },
    { label: 'Tipo', key: 'TipoHorario', transform: ({ TipoHorario }) => TipoHorario?.Nombre },
    { label: 'Descripción', key: 'Descripcion' },
    { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
]

const Horarios = ({ history, location }) =>
    <Listados
        titulo="Horarios"
        placeHolder="Búsqueda por nombre"
        history={history}
        location={location}
        cabeceros={cabeceros}
        apiKey={HORARIOS}
        showActions
        filtrosBusqueda={{
            btnAgragar: true,
        }}
    />

export default Horarios;