/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, } from 'react';
import { toast } from 'react-toastify';
import {
  Grid,
} from '@material-ui/core';
import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import axios from '../../configuraciones/axios';
import endpoints, {
  DEPARTAMENTOS, EMPRESAS,
} from '../../configuraciones/endpoints';
import { findPropertysEmpty, requiredFIle, trim } from '../../utilidades/functions';
import estatus from '../../constantes/estatus';
import styles from './styles';
import { LETTERSSPECIALCHARACTER } from '../../utilidades/regex';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';


const Departamento = ({ history, match }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const classes = styles();
  const [formErrors, setFormErrors] = useState({});
  const [departamento, setDepartamento] = useState({});
  const [empresa, setEmpresas] = useState([]);
  const { params: { _id } } = match;

  // Funcion que permite regresar al listado de departamentos
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/departamentos',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  // Funcion que llama regresar
  const cancelar = useCallback(regresar, []);

  // Funcion encargada de guardar los campos del nuevo departamento
  const guardar = useCallback(() => {
    setIsSubmit(true);
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmDepartamento'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = _id ? 'put' : 'post';
    const nuevoDepartamento = trim(departamento);
    const nuevoObj = { ...nuevoDepartamento };
    axios[metodo](DEPARTAMENTOS, nuevoObj).then(regresar);

  }, [departamento]);


  // Funcion encargada extraer la informacion de un departamento y todas las empresas
  const consultaDatosIniciales = useCallback(async () => {
    axios.get(endpoints.base.url(EMPRESAS, '/obtener-Activos'))
      .then(async ({ empresa }) => {
        setEmpresas(empresa);

        if (_id) {
          const departamentoEdicion = await axios.get(endpoints.base.url(DEPARTAMENTOS, _id));
          if (!departamentoEdicion) {
            toast.warning(ELEMENTO_NO_ENCONTRADO);
            regresar();
            return;
          }
          const { Empresa } = departamentoEdicion;

          departamentoEdicion.Empresa = (Empresa.Habilitado && Empresa.Activo) ? Empresa._id : null;
          setDepartamento(departamentoEdicion);
        }
      });
  }, [_id]);

  useEffect(() => {
    consultaDatosIniciales();
  }, [consultaDatosIniciales])

  return <Default
    titulo={Boolean(_id) ? 'Editar departamento' : 'Nuevo departamento'}
    placeHolder={''}
    mostrarCabeceroFormulario={false}
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmDepartamento">
      <Grid className={classes.datosGrid} item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setDepartamento}
              name="Nombre"
              value={departamento.Nombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              helperText={requiredFIle(isSubmit, formErrors, 'Nombre')}
              error={formErrors.Nombre}
              required
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              label="Descripción"
              type="textarea"
              rows={6}
              onChange={setDepartamento}
              name="Descripcion"
              value={departamento.Descripcion}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 200, regexonchange: 'true' }}
              error={formErrors.Descripcion}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              options={estatus}
              onChange={setDepartamento}
              name="Habilitado"
              value={departamento.Habilitado}
              error={formErrors.Habilitado}
              helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
              isHandleChange
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.datosGrid} item lg={4} md={5} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Select
              label="Empresa"
              labelProp="Nombre"
              valueProp="_id"
              options={empresa}
              name="Empresa"
              placeHolder='Seleccione una'
              onChange={setDepartamento}
              value={departamento.Empresa || ''}
              error={formErrors.Empresa}
              helperText={requiredFIle(isSubmit, formErrors, 'Empresa')}
              isHandleChange
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Departamento);
