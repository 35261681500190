/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, memo } from 'react';
import propTypes from 'prop-types';

import styles from './styles';

import { Checkbox, OutlinedInput, Typography, Box, Chip, Select, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP, // Aumenta la altura máxima del menú
      width: 250,
    },
  },
};

function getStyles(value, filterValues, theme) {
  return {
    fontWeight:
      filterValues.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SelectChip = ({ options, label, nombreFiltro, onChange, filterValues, isFilterDelete, disabled }) => {
  const theme = useTheme();
  const classes = styles();
  // Variable para el control de casos donde se selecciona Todos
  const [isTodosSelected, setIsTodosSelected] = useState(false);
  // Almacenamiento de las opciones
  const [filtrosData, setFiltrosData] = useState({
    [nombreFiltro]: [{ value: '', label: 'Todos' }],
  });

  // Función de control de cambios al seleccionar una opción
  const handleChange = useCallback((event) => {
    // Obtener valor(es)
    const {
      target: { value },
    } = event;
    const selection = value.includes('') ? value.length - 1 : value.length;
    /**
     * Casos de uso SelectChip
     * cuando value incluya '' y el length sea igual a 1 ----------
     * cuando value.length sea igual a la cantidad de filtros -1 -- se setea la opción 'Todos'
     * cuando value.length sea igual a 0 --------------------------
     *
     * Cuando value.length sea mayor o igual a 2 y isTodos... sea falso, y si value incluye ''
     * se setea la opción de 'Todos'
     *
     * Caso default, aplicar valores y agregar el valor del filtro al componente padre
     */
    if (
      (value.includes('') && selection === 0) ||
      (selection >= filtrosData[nombreFiltro].length - 1) ||
      selection === 0) {
      setIsTodosSelected(true);
      onChange((current) => ({ ...current, [nombreFiltro]: [''] }));
    } else if ((selection >= 1 && !isTodosSelected) && value.includes('')) {
      setIsTodosSelected(true);
      onChange((current) => ({ ...current, [nombreFiltro]: [''] }));
    } else {
      setIsTodosSelected(false);
      onChange((current) => ({ ...current, [nombreFiltro]: value.filter((val) => val !== '') }));
    }
  }, [filtrosData, nombreFiltro, isTodosSelected]);

  // Optener valores seleccionados para mostrar en el input
  const getSelectedLabels = () => {
    if (isTodosSelected) {
      return ['Todos'];
    }
    return filtrosData[nombreFiltro]
      .filter((option) => filterValues.includes(option.value))
      .map((option) => option.label);
  };

  // Aplicar un valor default
  const setDefaultValue = useCallback (() => {
    const defaultValue = filtrosData[nombreFiltro].find(
      (option) => option.label === 'Todos'
    );
    if (defaultValue) {
      setIsTodosSelected(true);
      // Enviar el valor default al componente padre
      onChange((current) => ({ ...current, [nombreFiltro]: [defaultValue.value] }));
    }
  }, [filtrosData, isTodosSelected]);

  // Al iniciar, se carga los valores iniciales, si se eliminan los filtros, se llama a la funcion setDefaultValue
  useEffect(() => {
    if (options) {
      setFiltrosData({
        [nombreFiltro]: [{ value: '', label: 'Todos' }, ...options],
      });
      setDefaultValue();
    }
    if (isFilterDelete) {
      setDefaultValue();
    }
  }, [options, nombreFiltro, isFilterDelete]);

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>
        {label}
      </Typography>
      <Select
        className={classes.input}
        variant="standard"
        labelId="select-chip-label"
        id="select-chip"
        multiple
        value={filterValues}
        onChange={handleChange}
        disabled={disabled}
        inputprops={{
          disableUnderline: true,
        }}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            sx={{
              maxHeight: '45px',
              overflowY: 'auto',
            }}
          />
        }
        renderValue={() => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: '10px',
              paddingRight: '10px',
              maxWidth: '89%',
              overflow: 'hidden',
            }}
          >
            {getSelectedLabels().map((label) => (
              <Chip
                key={label}
                label={label}
                style={{ marginRight: '5px' }}
                className={classes.chips}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {filtrosData[nombreFiltro] &&
          filtrosData[nombreFiltro].map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={getStyles(option.value, filterValues, theme)}
            >
              <Checkbox
                checked={isTodosSelected || filterValues.includes(option.value)}
              />
              {option.label}
            </MenuItem>
          ))
        }
      </Select>
    </div>
  );
};

SelectChip.propTypes = {
  /** Variable para mostrar titulo del controlador */
  label: propTypes.string,
  /** Valores de filtrado */
  options: propTypes.oneOfType([
    propTypes.array,
    propTypes.object,
  ]).isRequired,
  /** Función para actualizar valores de selección al componente padre */
  onChange: propTypes.func,
  /** Nombre del filtro */
  nombreFiltro: propTypes.string.isRequired,
  /** Almacen de valores seleccionados */
  filterValues: propTypes.array.isRequired,
  /** Bandera que indica si el select se encuentra habilitado */
  disabled: propTypes.bool,
};

SelectChip.defaultProps = {
  label: '',
};

export default memo(SelectChip);
