import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';


const styles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  Geolocalizacion: {
    display: 'none',
  },
  box: {
    display: 'grid',
    gridTemplateColumns: '4fr 49px 49px',
    alignItems: 'center',
    width: '95%',
  },
  input: {
    minHeight: 45,
    minWidth: 145,
    marginTop: 10,
    backgroundColor: paleta.textField.backgroundColor,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    outline: 'none',
    width: '100%',
    border: `1px solid ${paleta.textField.border}`,
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
    },
  },
  error: {
    border: `1px solid ${paleta.textField.error}`,
  },
  actionContainer: {
       display: 'grid',
       marginLeft: '80px',
       gridTemplateColumns: '100px 100px',
       justifyItems: 'end',
       alignItems: 'end'

  },
  Display: {
    width: '100%',
    minHeight: '40px'
  },
  boton:{
    borderColor: paleta.bar.primary,
    backgroundColor: paleta.bar.primary,
    width: '49px',
    marginTop: 10,
    color: 'white',
    borderRadius: '0px 8px 8px 0px',
    '&:hover': {
      backgroundColor: paleta.bar.secondary,
      borderColor: paleta.bar.secondary,
    },
  },
  helperText: {
    marginTop: 5,
    marginLeft: 8,
    '&.error': {
      color: paleta.textField.error,
    },
  },
}));

export default styles;
