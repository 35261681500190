export const tipoEstatus = {
  justificante: 'Justificante',
  asistencia: 'Asistencia',
  RegistroCheck: 'RegistroCheck',
  ausencia: 'Ausencia',
}

export const estatusJustificante = Object.freeze({
  autorizado: 'Autorizado',
  solicitado: 'Solicitado',
  rechazado: 'Rechazado'
});

const estatus = [
  { label: 'Habilitado', id: true },
  { label: 'Inhabilitado', id: false },
];

export default estatus
