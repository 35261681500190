import React from 'react';
import propTypes from 'prop-types';
import Sidebar from '../../componentes/Sidebar';
import CabeceroListado from '../../componentes/CabeceroListado';
import styles from './styles';

const Default = ({
  children,
  mostrarCabeceroListado,
  mostrarCabeceroFormulario,
  customCabecero,
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  guardar,
  cancelar,
  style,
  mostrarLista,
  filtrosCabeceros,
  mostrar,
  customStyleSave,
  hiddenSideBar,
  params,
  toDo,
}) => {
  const classes = styles();
  const cabeceroChildren = () => {
    return (
      <>
        {!mostrarCabeceroFormulario && (
          <CabeceroListado
          titulo={titulo}
          subtitulo={subtitulo}
          placeHolder={placeHolder}
          buscar={buscar}
          agregar={agregar}
          customCabecero={customCabecero}
          esListado={mostrarCabeceroListado && !mostrarCabeceroFormulario}
          guardar={guardar}
          cancelar={cancelar}
          customStyles={style}
          filtrosCabeceros={filtrosCabeceros}
          mostrar={mostrar}
          params={params}
          toDo = {toDo}
        />
        )}
        {children}
      </>
    );
  }
  const childrenCabecero = () => {
    return (
      <>
        {children}
        <CabeceroListado
          titulo={titulo}
          subtitulo={subtitulo}
          placeHolder={placeHolder}
          buscar={buscar}
          agregar={agregar}
          customCabecero={customCabecero}
          esListado={mostrarCabeceroListado && !mostrarCabeceroFormulario}
          guardar={guardar}
          cancelar={cancelar}
          customStyles={style}
          filtrosCabeceros={filtrosCabeceros}
          customStyleSave={customStyleSave}
          params={params}
        />
      </>
    );
  }
  return (
    <div>
      <div className={classes.root} style={(hiddenSideBar && { marginLeft: -20, height: '84vh'})}>
        {mostrar.SideBar && (
          <Sidebar mostrarLista={mostrarLista} />
        )}
        <main className={classes.main}>
          {(customStyleSave) ? childrenCabecero() : cabeceroChildren()}
        </main>
      </div>
    </div>
  );
};

Default.propTypes = {
  /** Componente hijo a mostrar */
  children: propTypes.node.isRequired,
  /** Flag para mosrar el cabecero listado*/
  mostrarCabeceroListado: propTypes.bool,
  /** Flag para mostrar los cabeceros*/
  mostrarCabeceroFormulario: propTypes.bool,
  /** Titulo que llevara el listado */
  titulo: propTypes.oneOfType([propTypes.node, propTypes.string]).isRequired,
  /** Subtitulo a mostrar */
  subtitulo: propTypes.string,
  /** Texto default que llevara el buscador */
  placeHolder: propTypes.string,
  /** Funcion para realizar la busqueda */
  buscar: propTypes.func,
  /** Funcion para realizar el agregar*/
  agregar: propTypes.func,
  /** Accion de guardado*/
  guardar: propTypes.func,
  /** Accion para cancelar*/
  cancelar: propTypes.func,
  /** Mostrar mas filtros de busqueda*/
  filtrosCabeceros: propTypes.shape({
    /** Filtro para rango de fecha (inicio -> fin) */
    fechaInicioFinal: propTypes.bool,
    /** Filtro para los tipos de evaluacion (puntual, retardo, etc..) */
    evaluacion: propTypes.bool,
    /** Filtro para los tipos de estatus (incidencia, normal) */
    estatus: propTypes.bool,
    /** Filtro para busqueda por campo de texto */
    busqueda: propTypes.bool,
    /** Boton Agregar */
    btnAgregar: propTypes.bool,
  }),
  /** Estilo boton guardado */
  customStyleSave: propTypes.bool,
    /** Mostrar complementos de la tabla */
  mostrar: propTypes.oneOfType([propTypes.bool, propTypes.shape({
    /** Filtro para busqueda por campo de texto */
    txtBusqueda: propTypes.bool,
    /**  Subtitulo */
    Subtitulo: propTypes.bool,
    /** Boton Agregar */
    Agregar: propTypes.bool,
    /** Barra lateral (menu desplegable) */
    SideBar: propTypes.bool,
  })]),
    /** Parametros para realizar la busqueda */
  params: propTypes.oneOfType([propTypes.string, propTypes.shape({
    Estatus: propTypes.string,
    Evaluacion: propTypes.string,
  })]),
    /** Funcion para realizar un accion personalizada, viene desde el padre */
    toDo: propTypes.func,
};

Default.defaultProps = {
  mostrarCabeceroListado: false,
  mostrarCabeceroFormulario: false,
  titulo: "",
  subtitulo: "",
  placeHolder: "",
  buscar: null,
  agregar: null,
  guardar: null,
  cancelar: null,
  mostrarLista: false,
  mostrar: {
    /** Filtro para busqueda por campo de texto */
    txtBusqueda: true,
    /**  Subtitulo */
    Subtitulo: true,
    /** Boton Agregar */
    Agregar: true,
    /** Barra lateral (menu desplegable) */
    SideBar: true,
  },
  filtrosCabeceros: {
    /** Filtro para los tipos de movimiento (Entrada, salida) */
    movimiento: false,
    /** Filtro para rango de fecha (inicio -> fin) */
    fechaInicioFinal: false,
    /** Filtro para los tipos de evaluacion (puntual, retardo, etc..) */
    evaluacion: false,
    /** Filtro para los tipos de estatus (incidencia, normal) */
    estatus: false,
    /** Filtro para busqueda por campo de texto */
    busqueda: false,
    /** Boton Agregar */
    btnAgregar: false,
  },
  customStyleSave: false,
};

export default React.memo(Default);
