import { makeStyles } from '@material-ui/core';


const styles = makeStyles((theme) => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    borderStyle: 'dashed',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
   thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
   img:{
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  arrastreCentrado: {
    textAlign: 'center',
    padding: 1,
    paddingTop: '20px'
  },
  oCentrado: {
    textAlign: 'center',
    padding: 1,
    paddingBottom: '20px'
  },
  botonCentrado:{
    justifyContent: 'center'
  },
  DragDrop:{
    margin: '30px 10px 30px 10px', 
    border: 'dashed',
    borderColor: '#bdbdbd'

  }
}));

export default styles;