
const tipoEvaluaciones = {
  RegistroCheck: 'RegistroCheck',
  Asistencia: 'Asistencia',
}

export const constanteEvaluaciones = Object.freeze({
  puntual: 'Puntual',
  retardo: 'Retardo',
  anticipada: 'Anticipada',
  asistencia: 'Asistencia',
  descanso: 'Descanso',
  faltante: 'Faltante',
  falta: 'Falta',
});

export default tipoEvaluaciones
