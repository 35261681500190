import propTypes from 'prop-types';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  makeStyles, Paper, Grid,
  useMediaQuery, Box, CircularProgress
} from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import axios from '../../configuraciones/axios';

import Header from './Header';
import Mensaje from './Mensaje';
import Input from './Input';
import Burbuja from './Burbuja';

import endpoints from '../../configuraciones/endpoints';

import useSocket from '../../hooks/useSocket';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #E6EBF5',
    borderRadius: 24,
    minHeight: 400,
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  mensajesContainer: {
    padding: 15,
    overflowY: 'scroll',
  },
}));

const Chat = ({ idTamanoPadre, cliente, porcentajeAltura, tipoMensaje }) => {
  const styles = useStyles();
  const listRef = useRef(null);
  const match = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const [height, setHeight] = useState(0);
  const [cargando, setCargando] = useState(false);

  const { socket, connected } = useSocket(cliente._id);
  const ref = useRef(null);

  const mensajesPorPagina = useMemo(() => 30, []);

  const Row = ({ index, style }) => <>
    <div style={style}>
      {index === 0 && cargando &&
        <Box display='flex' justifyContent='center' style={style}>
          <CircularProgress />
        </Box>
      }
      <Mensaje mensaje={data.rows[index]} />
    </div>
  </>;

  const consultarPaginadoMensajes = useCallback(async (pagina, registrosPorPagina) => {
    if (!cliente._id) {
      setCargando(false);
      return { docs: [], hasNextPage: false }
    }

    const { docs: mensajes, ...response } = await axios.get(endpoints.chat(cliente._id), {
      params: {
        pagina,
        registrosPorPagina,
        noajax: true,
      },
    });

    const docs = mensajes.sort((a, b) => {
      if (a.FechaCreacion < b.FechaCreacion) return -1;
      if (a.FechaCreacion > b.FechaCreacion) return 1;
      return 0;
    });

    setCargando(false);
    return { docs, ...response };
  }, [cliente]);

  const {
    cambioPagina,
    data,
    setData,
  } = useInfiniteScroll(consultarPaginadoMensajes, { registrosPorPagina: mensajesPorPagina, reverso: true });

  const handleChatScroll = useCallback(({ scrollDirection, scrollOffset }) => {
    if (scrollDirection === 'forward' || scrollOffset >= 10) return;
    cambioPagina(() => setCargando(true));
  }, [cambioPagina]);

  useEffect(() => {
    if (data.nuevoMensaje) {
      listRef.current?.scrollToItem(data.count - 1);
      return;
    }
    listRef.current?.scrollToItem(mensajesPorPagina + 1);
  }, [data, mensajesPorPagina]);

  useEffect(() => {
    setTimeout(() => {
      const valor = (document.getElementById(idTamanoPadre)?.clientHeight * porcentajeAltura) || 0;
      setHeight(valor);
    }, 100);
  }, [idTamanoPadre, porcentajeAltura]);

  useEffect(() => {
    if (socket && cliente?._id) {
      socket.on(`cliente-${cliente._id}`, (accion, mensaje) => {
        switch (accion) {
          case 'push_mensaje':
            setData((prev) => ({
              count: prev.count + 1,
              rows: [...prev.rows, { ...mensaje, nuevoMensaje: true }],
              nuevoMensaje: true,
            }));
            break;
          default:
            break;
        }
      });

      return () => {
        socket.off(`cliente-${cliente._id}`);
      }
    }
  }, [socket, cliente, setData]);

  return (
    <Paper className={styles.root} style={{ height: match ? 0 : height }} ref={ref}>
      <Header
        avatar={cliente.logo}
        nombre={`${cliente.Nombres} ${cliente.Apellidos}`}
        correo={cliente.Email}
        online={connected}
      />
      <Box flex={1} className={styles.mensajesContainer}>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <Grid item xs={12}>
            <AutoSizer>
              {({ height, width }) => (
                <>
                  <List
                    className="List"
                    height={height}
                    width={width}
                    itemCount={data.count}
                    itemSize={80}
                    ref={listRef}
                    onScroll={handleChatScroll}
                    initialScrollOffset={mensajesPorPagina + 1}
                    direction='vertical'
                  >
                    {Row}
                  </List>
                </>
              )}
            </AutoSizer>
          </Grid>
        </Grid>
      </Box>
      <Input cliente={cliente} tipoMensaje={tipoMensaje} />
    </Paper>
  );
};

Chat.propTypes = {
  /** Identificador de un elemento a tomar como referencia para el tamaño en altura */
  idTamanoPadre: propTypes.string,
  /** Objeto que contiene la informacion del cliente */
  cliente: propTypes.object.isRequired,
  /** Indica el porcentaje de altura en funcion del tamaño del padre */
  porcentajeAltura: propTypes.number,
  /** Indica el tipo de mensaje (entrada / salida) */
  tipoMensaje: propTypes.oneOf(['E', 'S']),
};

Chat.defaultProps = {
  porcentajeAltura: 0.85,
  tipoMensaje: 'S',
}

export default Chat;

export {
  Burbuja,
};
