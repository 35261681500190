import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { IoMdAddCircleOutline } from 'react-icons/io';

import moment from 'moment-timezone';

import Accordion from '../Accordion';
import Boton from '../Button';
import Select from '../Select';
import TextField from '../TextField';
import Typography from '../Typography';

import styles from './styles';
import paleta from '../../configuraciones/paleta';
import axios from '../../configuraciones/axios';
import tiposEvaluacion from '../../constantes/tipoEvaluacion';
import endpoints, { EVALUACIONES, ESTATUS } from '../../configuraciones/endpoints';
import { LETTERSSPECIALCHARACTER } from '../../utilidades/regex';
import { setFiltrosBusqueda } from '../../ducks/listados';
import { ERROR_RANGO_FECHAS } from '../../configuraciones/mensajes';
import { toast } from 'react-toastify';

const CabeceroListado = ({
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  esListado,
  guardar,
  cancelar,
  customStyles,
  filtrosCabeceros,
  customStyleSave,
  mostrar,
  params,
  toDo,
}) => {
  const classes = styles();
  const matches = useMediaQuery('(max-width:960px)');
  const dispatch = useDispatch();
  const { filtrosBusqueda: { movimientos, evaluaciones, estatus, motivos, habilitado } } = useSelector(store => store.listados);
  const [txtBusqueda, setTxtBusqueda] = useState('');
  const [txtBusquedaAnterior, setTxtBusquedaAnterior] = useState('');
  const [filtros, setFiltros] = useState({
    motivoNombre: '',
    txtBusqueda: '',
    movimientoNombre: '',
    EstatusID: '',
    EvaluacionID: '',
    fechaInicio: '',
    fechaFinal: '',
    TipoEvaluacion: '',
  });

  const [filtrosData, setFiltrosData] = useState({
    movimientos: [{ value: '', label: 'Todos' },],
    habilitado: [{ value: '', label: 'Todos' },],
    evaluaciones: [{ value: '', label: 'Todos' },],
    estatus: [{ value: '', label: 'Todos' },],
    motivos: [{ value: '', label: 'Todos' },],
  });

  const manejadorKeyDown = (e) => {
    if (e.key === "Enter" && buscar)
      if (txtBusquedaAnterior !== txtBusqueda) {
        buscar(txtBusqueda);
        setTxtBusquedaAnterior(txtBusqueda);
      }
  };

  const ConsultarFiltros = useCallback(async () => {
    const evaluacionesProm = axios.get(endpoints.base.url(EVALUACIONES), { params: { tipoEvaluacion: filtrosCabeceros.tipoEvaluacion || '' } })

    const estatusProm = axios.get(endpoints.base.url(ESTATUS), { params: { tipoEstatus: filtrosCabeceros.tipoEstatus || '' } });
    const [evaluacionesRes, estatusRes] = await Promise.all([evaluacionesProm, estatusProm]);

    const evaluacionSelect = evaluacionesRes.map(({ Nombre, _id }) => ({ label: Nombre, value: _id }));
    const estatusSelect = estatusRes.map(({ Nombre, _id }) => ({ label: Nombre, value: _id }));
    const filtrosSelect = ({ evaluaciones: evaluacionSelect, estatus: estatusSelect })
    dispatch(setFiltrosBusqueda(filtrosSelect));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtrosCabeceros]);

  /** Consultar los datos necesarios para operar desde el back */
  useEffect(() => {
    ConsultarFiltros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFiltros = useCallback((e) => {
    const { name, value } = e.target;
    const nuevosFiltros = { ...filtros, [name]: value };
    setFiltros(nuevosFiltros);
    if (buscar) {
      buscar(txtBusqueda, nuevosFiltros);
    }
  }, [buscar, txtBusqueda, filtros]);

  //  Metodo para validar fechas
  const validarFechas = () => {
    const { fechaFinal, fechaInicio } = filtros;

    if (!fechaFinal || !fechaInicio) return;

    const inicio = moment(fechaInicio);
    const fin = moment(fechaFinal);

    if (inicio.isAfter(fin)) return toast.error(ERROR_RANGO_FECHAS);
  }

  /** Actualizar filtros opciones */
  useEffect(() => {
    if (filtrosCabeceros && (
      movimientos.length > 0 ||
      habilitado.length > 0 ||
      evaluaciones.length > 0 ||
      estatus.length > 0 ||
      motivos.length > 0
    )) {
      setFiltrosData({
        movimientos: [{ value: '', label: 'Todos' }, ...movimientos],
        habilitado: [{ value: '', label: 'Todos' }, ...habilitado],
        evaluaciones: [{ value: '', label: 'Todos' }, ...evaluaciones],
        estatus: [{ value: '', label: 'Todos' }, ...estatus],
        motivos: [{ value: '', label: 'Todos' }, ...motivos],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosCabeceros, movimientos, evaluaciones, estatus, motivos]);

  useEffect(() => {
    validarFechas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros])

  const componentFiltros = (
    <>
      {filtrosCabeceros.movimiento && (
        <Select
          label='Movimiento'
          name="movimientoNombre"
          placeHolder="Movimiento"
          placeHolderColor={paleta.textField.text}
          className={classes.select}
          displayTextStyles={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          onChange={onChangeFiltros}
          value={filtros.movimientoNombre}
          valueProp="value"
          options={filtrosData.movimientos}
        />
      )}
      {filtrosCabeceros.habilitado && (
        <Select
          label='Estatus'
          name="habilitadoValue"
          placeHolder="Estatus"
          placeHolderColor={paleta.textField.text}
          className={classes.select}
          displayTextStyles={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          onChange={onChangeFiltros}
          value={filtros.habilitadoValue}
          valueProp="value"
          options={filtrosData.habilitado}
        />
      )}
      {filtrosCabeceros.evaluacion && (
        <Select
          label='Evaluación'
          name="EvaluacionID"
          placeHolder="Evaluacion"
          placeHolderColor={paleta.textField.text}
          className={classes.select}
          displayTextStyles={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          onChange={onChangeFiltros}
          value={filtros.EvaluacionID}
          valueProp="value"
          options={filtrosData.evaluaciones}
        />
      )}
      {filtrosCabeceros.estatus && (
        <Select
          label='Estatus'
          name="EstatusID"
          placeHolder="Estatus"
          placeHolderColor={paleta.textField.text}
          className={classes.select}
          displayTextStyles={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          onChange={onChangeFiltros}
          value={filtros.EstatusID}
          valueProp="value"
          options={filtrosData.estatus}
        />
      )}
      {filtrosCabeceros.tiposEvaluacion && (
        <Select
          label='Tipo'
          name="TipoEvaluacion"
          placeHolder="Tipo"
          placeHolderColor={paleta.textField.text}
          className={classes.select}
          displayTextStyles={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          onChange={onChangeFiltros}
          value={filtros.TipoEvaluacion}
          valueProp="value"
          options={[{ label: 'Todos', value: '' }].concat(tiposEvaluacion)}
        />
      )}
      {filtrosCabeceros.motivo && (
        <Select
          label='Motivo'
          name="motivoNombre"
          placeHolder="Motivo"
          placeHolderColor={paleta.textField.text}
          className={classes.select}
          displayTextStyles={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          onChange={onChangeFiltros}
          value={filtros.motivoNombre}
          valueProp="value"
          options={filtrosData.motivos}
        />
      )}
    </>
  );

return (
  <div>
    <Box flex={1} className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography
          component="h2"
          className={classes.title}
          style={customStyles}
        >
          {titulo}
          {filtrosCabeceros.fechaTitulo && (
            <Grid
              className={classes.Fechas}
            >
              <TextField
                name="fechaInicio"
                value={filtros.fechaInicio}
                type="date"
                onChange={onChangeFiltros}
              />

              <Typography className={classes.textoEntreFechas}> al </Typography>
              <TextField
                name="fechaFinal"
                value={filtros.fechaFinal}
                onChange={onChangeFiltros}
                type="date"
              />
            </Grid>
          )}
        </Typography>
        {mostrar.Subtitulo && <Typography className={classes.subtitle}>{subtitulo}</Typography>}
      </div>
      {esListado && (
        <Box flex={1} className={classes.actionContainer}>
          {(filtrosCabeceros.fechaInicioFinal && !filtrosCabeceros.fechaTitulo) && (
            <Grid
              className={classes.Fechas}
            >
              <TextField
                name="fechaInicio"
                value={filtros.fechaInicio}
                type="date"
                onChange={onChangeFiltros}
              />

              <Typography className={classes.textoEntreFechas}> al </Typography>
              <TextField
                name="fechaFinal"
                value={filtros.fechaFinal}
                onChange={onChangeFiltros}
                type="date"
              />
            </Grid>
          )}
          {!matches && (
            <>
              {componentFiltros}
              {filtrosCabeceros.busqueda && (
                <TextField
                  name="txtBusqueda"
                  placeHolder={placeHolder}
                  className={classes.textField}
                  inputClassName={classes.textFieldInput}
                  inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
                  onChange={({ target: { value } }) => setTxtBusqueda(value)}
                  value={txtBusqueda}
                  onKeyDown={manejadorKeyDown}
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                />
              )}
            </>
          )}
          {buscar && mostrar.txtBusqueda && (
            <TextField
              name="txtBusqueda"
              placeHolder={placeHolder}
              className={classes.textField}
              inputClassName={classes.textFieldInput}
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              onChange={({ target: { value } }) => setTxtBusqueda(value)}
              value={txtBusqueda}
              onKeyDown={manejadorKeyDown}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
            />
          )}
          {agregar && mostrar.Agregar && (
            <Box className={classes.btnContainer}>
              <Boton
                icono={<IoMdAddCircleOutline size={18} />}
                label="Agregar"
                onClick={agregar}
                classesCustom={{
                  boton: classes.btnAdd,
                }}
              />
            </Box>
          )}
          {toDo && (
            <Box className={classes.btnContainer}>
              <Boton
                icono={<IoMdAddCircleOutline size={18} />}
                label="Agregar"
                onClick={toDo}
                classesCustom={{
                  boton: classes.btnAdd,
                }}
              />
            </Box>
          )}

          {matches && (
            <Accordion
              id="accordion-filtros"
              title="Más filtros"
            >
              {componentFiltros}
            </Accordion>
          )}
        </Box>
      )}
      {!esListado && (
        <Box flex={1} className={classes.actionContainer}>
          {buscar && (
            <TextField
              name="txtBusqueda"
              placeHolder={placeHolder}
              className={classes.textField}
              onChange={({ target: { value } }) => setTxtBusqueda(value)}
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              value={txtBusqueda}
              onKeyDown={manejadorKeyDown}
            />
          )}
          {guardar && (
            <Boton
              onClick={guardar}
              label="Guardar"
              classesCustom={customStyleSave ? { boton: classes.customStyleSave } : { boton: classes.btn }}
            />
          )}
          {cancelar && (
            <Boton
              onClick={cancelar}
              label="Cancelar"
              classesCustom={{ boton: classes.btn }}
              border
            />
          )}
        </Box>
      )}
    </Box>
  </div>
);
};

CabeceroListado.propTypes = {
  /** Titulo que llevara el listado */
  titulo: propTypes.oneOfType([propTypes.node, propTypes.string]).isRequired,
  /** Subtitulo a mostrar */
  subtitulo: propTypes.string,
  /** Texto default que llevara el buscador */
  placeHolder: propTypes.string,
  /** Funcion para realizar la busqueda */
  buscar: propTypes.func,
  /** Funcion para realizar el agregar*/
  agregar: propTypes.func,
  /** Manejar display de listado*/
  esListado: propTypes.bool,
  /** Accion de guardado*/
  guardar: propTypes.func,
  /** Accion para cancelar*/
  cancelar: propTypes.func,
  /** Dar estilo propio*/
  customStyles: propTypes.object,
  /** Mostrar mas filtros de busqueda*/
  filtrosCabeceros: propTypes.oneOfType([propTypes.bool, propTypes.shape({
    /** Filtro para los tipos de movimientos (Entrada, Salida) */
    movimiento: propTypes.bool,
    /** Filtro para habilitado (true, false) */
    habilitado: propTypes.bool,
    /** Filtro para rango de fecha (inicio -> fin) */
    fechaInicioFinal: propTypes.bool,
    /** Filtro para los tipos de evaluacion (puntual, retardo, etc..) */
    evaluacion: propTypes.bool,
    /** Filtro para los tipos de estatus (incidencia, normal) */
    estatus: propTypes.bool,
    /** Filtro para busqueda por campo de texto */
    busqueda: propTypes.bool,
    /** Booleano para mostrar fecha en el titulo */
    fechaTitulo: propTypes.bool,
    /** Boton Agregar */
    btnAgregar: propTypes.bool,
  })]),
  /** Mostrar complementos de la tabla */
  mostrar: propTypes.oneOfType([propTypes.bool, propTypes.shape({
    /** Filtro para busqueda por campo de texto */
    txtBusqueda: propTypes.bool,
    /**  Subtitulo */
    Subtitulo: propTypes.bool,
    /** Boton Agregar */
    Agregar: propTypes.bool,
    motivo: propTypes.bool,
  })]),
  /** Parametros para realizar la busqueda */
  params: propTypes.oneOfType([propTypes.string, propTypes.shape({
    Estatus: propTypes.string,
    Evaluacion: propTypes.string,
  })]),
  /** Funcion para realizar un accion personalizada, viene desde el padre */
  toDo: propTypes.func,
};

CabeceroListado.defaultProps = {
  subtitulo: '',
  placeHolder: '',
  buscar: null,
  agregar: null,
  esListado: true,
  guardar: null,
  cancelar: null,
  filtrosCabeceros: {
    /** Filtro para los tipos de movimiento (Entrada, salida) */
    movimiento: false,
    /** Filtro para rango de fecha (inicio -> fin) */
    fechaInicioFinal: false,
    /** Filtro para los tipos de evaluacion (puntual, retardo, etc..) */
    evaluacion: false,
    /** Filtro para los tipos de estatus (incidencia, normal) */
    estatus: false,
    /**  */
    fechaTitulo: false,
    motivo: false,
  },
  mostrar: {
    txtBusqueda: true,
    /**  Subtitulo */
    Subtitulo: true,
    Agregar: true,
  },
  params: {
    Estatus: 'Asistencia',
    Evaluacion: 'Asistencia',
  },
};

export default React.memo(CabeceroListado);
