import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { PUESTOS } from "../../configuraciones/endpoints";

const cabeceros = [
  { label: 'Nombre', key: 'Nombre' },
  { label: 'Empresa', transform: ({ Empresa }) => Empresa ? Empresa.Nombre : null},
  { label: 'Descripción', key: 'Descripcion'},
  { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
];

const Puestos = ({ history, location }) => {

  return <Listados
    titulo="Puestos"
    placeHolder="Búsqueda por nombre o empresa"
    history={history}
    location={location}
    showActions
    filtrosBusqueda={{
      btnAgragar: true,
    }}
    cabeceros={cabeceros || []}
    apiKey={PUESTOS}
  />;
};

export default Puestos;