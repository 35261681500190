import React, {
  useState,
  useEffect,
  forwardRef,
} from 'react';
import propTypes from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { AiFillDelete } from 'react-icons/ai';
import clsx from 'clsx';

import Button from '../Button';
import TextField from '../TextField';

import styles from './styles';
import { Grid } from '@material-ui/core';
import Geocode from '../../configuraciones/geocode';
import useMap from '../../hooks/useMap';

const Mapa = forwardRef(({
  containerStyle,
  defaultCenter, zoom, mapType,
  value,
  ocultarBusqueda, ocultarEliminar,
  onChange, inicio
}, ref) => {

  const [drawMark, setDrawMark] = useState(inicio);
  const [center, setCenter] = useState(defaultCenter);
  const [txtBusqueda, setTxtBusqueda] = useState('');
  const classes = styles();

  const { isLoaded, loadError } = useMap();

  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
    });
  } else {
  }

  /**
   * @param {busca que se presione la tecla enter}
   * Funciona mediante una api donde busca y toma las coordenadas para tomar un nuevo centro y ubicar el mapa
   */

  const manejadorKeyDown = (e) => {         //Busqueda en el buscador, no funciona por falta de api
    if (e.key === 'Enter') {
      Geocode.fromAddress(txtBusqueda).then(
        (response) => {
          if (response.results.length > 0) {
            const { lat, lng } = response.results[0].geometry.location;
            setCenter({ lat, lng });
          }
        },
      );
    }
  };

  /**
   * @param {coordenadas latitud y longitud} param0
   * Esta funcion toma las coordenadas lat y lng
   */

  const customOnClick = ({ latLng: { lat, lng } }) => {       //Hace el trazado del marcador onclick
    onChange(current => {
      return ({ ...current, latitud: lat(), longitud: lng() })
    });
  };

  useEffect(() => {
    onChange(current => ({ ...current, ...drawMark }))
  }, [drawMark, onChange]);


  /**
   *  Esta funcion borra las coordenadas (null) y el radio lo pone en 0 onclick
   */
  const customDelete = () => {
    setDrawMark({ radio: 0, latitud: null, longitud: null, })
  }

  if (loadError) {
    return <div>El mapa no puede ser cargado en este momento.</div>
  }

  return (
    <Grid>
      <div className={classes.contenedorMapa}>
        {
          (isLoaded)
            ?
            <>
              {!ocultarEliminar && <Button
                name='eliminar'
                icono={<AiFillDelete color='#666666' size={22} />}
                classesCustom={{ boton: classes.btnDelete }}
                onClick={customDelete}
              />}
              {!ocultarBusqueda && <TextField
                color={true}
                placeHolder='Buscar por dirección'
                name="txtDireccion"
                value={txtBusqueda}
                className={classes.txtDireccion}
                onChange={({ target: { value } }) => setTxtBusqueda(value)}
                onKeyDown={manejadorKeyDown}
              />}
              {/* Mapa */}
              <GoogleMap
                mapContainerClassName={clsx(classes.mapa, containerStyle)}
                center={center}
                mapTypeId={mapType}
                onClick={customOnClick}
                zoom={zoom}
              >
                {/* Marcador */}
                {value.latitud && value.longitud ? (
                  <Marker position={{ lat: value.latitud, lng: value.longitud }} />
                ) : null}
              </GoogleMap>
            </>
            :
            <div className={classes.loadingMap}></div>
        }
      </div>
    </Grid>
  )
});

Mapa.propTypes = {
  /** Estilos de contenedor del mapa */
  containerStyle: propTypes.string,
  /** Propiedad usada para manejar el centrado del mapa */
  defaultCenter: propTypes.shape({
    lat: propTypes.number,
    lng: propTypes.number,
  }),
  /** Propiedad para manejar el zoom inicial en el mapa */
  zoom: propTypes.number,
  /** Define el tipo de mapa a usar */
  mapType: propTypes.string,
  /** Propiedad que maneja los valores de la coordenada y el radio  */
  value: propTypes.shape({
    latitud: propTypes.number,
    longitud: propTypes.number,
    radio: propTypes.number,
  }),
  /** Oculta el menu de busqueda */
  ocultarBusqueda: propTypes.bool,
  /** Ocultar el boton eliminar dentro del mapa */
  ocultarEliminar: propTypes.bool,
}

Mapa.defaultProps = {
  mapTypeControl: false,
  containerStyle: '',
  defaultCenter: {
    lat: 24.8089200,
    lng: -107.3938400,
  },
  zoom: 13,
  mapType: 'satellite',
  ocultarBusqueda: false,
  ocultarEliminar: false,
}

export default Mapa;
