import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
    contenedorConfiguracion: {
        'background-color': '#EAF0F6',
        'padding': '1rem',
        'borderRadius': '3px',
        'max-height': '70vh',
        'overflowY': 'scroll',
        'minHeight': '100%',
        'marginTop': '0.25rem'
    }
}));

export default styles;