import React from 'react';

import propTypes from 'prop-types';
import clsx from 'clsx';
import { GiInfo } from 'react-icons/gi';
import { Tooltip, IconButton } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';

import Typography from '../Typography';
import styles from './styles';
import { regexValid } from '../../utilidades/regex';

const TextField = ({
  label, value, name, type, onChange, isHandleChange,
  inputProps, required, error, tooltip, rows, helperText,
  placeHolder, className, onKeyDown, disabled,
  inputClassName, inputStyles, simbolo, onClick, onFocus, ampm, color, onTextChange,
}) => {
  const classes = styles();
  const customOnChange = (e) => {
    if (onTextChange) {
      onTextChange(e.target.value);
      return;
    }

    if (type === 'time') {
      if (isHandleChange)
        onChange((current) => ({ ...current, [name]: e }));
      else
        onChange(e);
      return;
    }

    if (regexValid(e) && onChange)
      if (isHandleChange)
        onChange((current) => ({ ...current, [name]: type === 'numero' ? Number(e.target.value) : e.target.value }));
      else
        onChange(e);
  };


  const inputsTypes = {
    'textarea': <textarea
      className={clsx(classes.input, inputClassName, { [classes.error]: error, disabled })}
      value={value || ''}
      name={name}
      type={type}
      rows={rows}
      onChange={customOnChange}
      required={required}
      placeholder={placeHolder}
      onKeyDown={onKeyDown}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled}
      style={{
        ...inputStyles,
        resize: 'none',
        padding: 5,
      }}
      {...inputProps}
    />,
    'time':
      <KeyboardTimePicker
        id="time-picker"
        format={ampm ? 'hh:mm a' : 'HH:mm'}
        value={value || null}
        placeholder='-- : --'
        onChange={customOnChange}
        lang="es"
        disabled={disabled}
        className={clsx(classes.inputTime, { [classes.errorTime]: error, disabled })}
        ampm={ampm}
        required={required}
        onKeyDown={(e) => e.preventDefault()}
        KeyboardButtonProps={{
          'aria-label': 'Cambiar tiempo',
        }}
      />
  }

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <Typography className={classes.label}>
          {label}
          {required && ' * '}
          {tooltip && tooltip !== '' && (
            <Tooltip title={<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>}>
              <IconButton size="small">
                <GiInfo />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      {inputsTypes[type] ||
        <div>
          <input
            className={clsx(classes.input, inputClassName, { [classes.error]: error, disabled, [classes.color]: color })}
            style={inputStyles}
            value={value || ''}
            name={name}
            type={type}
            onChange={customOnChange}
            required={required}
            placeholder={placeHolder}
            onKeyDown={onKeyDown}
            onClick={onClick}
            onFocus={onFocus}
            disabled={disabled}
            {...inputProps}
          />
          {simbolo}
        </div>
      }
      {(helperText && helperText !== '') && <Typography
        className={clsx(classes.helperText, { error })}
        component="p">
        {helperText}
      </Typography>}
    </div>
  );
};

TextField.propTypes = {
  /* Etiqueta del textfield **/
  label: propTypes.string,
  /* Valor inicial **/
  value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.object]),
  /* Nombre de la propiedad que setea **/
  name: propTypes.string.isRequired,
  /* Tipo de input **/
  type: propTypes.string,
  /* Callback para reaccionar al cambio **/
  onChange: propTypes.func,
  /* Flag que indica si se sobreescribe una copia del estado anterior **/
  isHandleChange: propTypes.bool,
  /* Props del input **/
  inputProps: propTypes.object,
  /* Flag que indica si es requerido **/
  required: propTypes.bool,
  /* Propiedad para asignar error **/
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  /* Cadena para el tooltip **/
  tooltip: propTypes.string,
  /* Número de filas **/
  rows: propTypes.number,
  /* Texto de ayuda para el text field **/
  helperText: propTypes.string,
  /* PLaceholder para el textfield **/
  placeHolder: propTypes.string,
  /* Classes para el tiext field **/
  className: propTypes.string,
  /* Callback para manejar el evento key down **/
  onKeyDown: propTypes.func,
  /* Flag para desactivar el text field **/
  disabled: propTypes.bool,
  /* Classes para el input **/
  inputClassName: propTypes.string,
  /* Estilos para el input **/
  inputStyles: propTypes.object,
  /* Callback para manejar el cambio de texto en el input **/
  onTextChange: propTypes.func,
};

TextField.defaultProps = {
  label: '',
  value: '',
  type: 'text',
  isHandleChange: false,
  inputProps: {},
  required: false,
  error: false,
  tooltip: '',
  rows: 1,
  helperText: '',
  placeHolder: '',
  className: '',
  onKeyDown: null,
  disabled: false,
  style: {}
};

export default React.memo(TextField, (prev, next) => {
  return prev.value === next.value &&
    prev.error === next.error &&
    prev.helperText === next.helperText &&
    prev.disabled === next.disabled &&
    prev.tooltip === next.tooltip &&
    prev.onChange === next.onChange;
});
