import queryString from 'query-string';

export const USUARIOS = '/usuario';
export const SESIONES = '/sesion';
export const PERFILES = '/perfil';
export const SECCION = '/seccion';
export const SUCURSALES = '/sucursal';
export const EMPRESAS = '/empresa';
export const DEPARTAMENTOS = '/departamento';
export const AVATARESPREDEFINIDOS = '/avatarPredefinido';
export const REGISTROS = '/registro';
export const EVALUACIONES = '/evaluacion';
export const ESTATUS = '/estatus';
export const AUSENCIA ='/ausencia';
export const AUSENCIADETALLES = '/ausenciaDetalle';
export const HORARIOS = '/horario';
export const PUESTOS = '/puesto';
export const EMPLEADOS = '/empleado';
export const JUSTIFICANTES  = '/justificante';
export const DISPOSITIVOS = '/dispositivo';
export const ASISTENCIA  = '/asistencias';
export const HORARIOEMPLEADO  = '/horario-empleado'
export const REPORTE_ASISTENCIA_INDIVIDUAL = '/asistencia-individual';
export const REPORTE_ASISTENCIAS_GRUPAL = '/asistencias-grupal';
export const REPORTE_CHECK_INDIVIDUAL = '/check-individual';
export const REPORTE_CHECKS_GRUPAL = '/checks-grupal';
export const LANDING = '/landing';
export const PLANES = '/planes';
export const CHAT = '/chats';
export const CLIENTE_URI = '/cliente'

const endpoints = {
  base: {
    url: (uri, id = '') => `${uri}/${id}`,
    busqueda: (uri, params) => `${uri}/busqueda${queryString.stringify(params)}`,
  },
  iniciarSesion: () => `${SESIONES}/iniciar`,
  chat: (clienteId) => `${CHAT}${CLIENTE_URI}/${clienteId}`
};

export default endpoints;
