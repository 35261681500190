import { Checkbox, } from '@material-ui/core';
import propTypes, { string } from 'prop-types';
import clsx from 'clsx';
import React, { useState } from 'react'
import Typography from '../Typography';
import styles from './styles';

export const TablaDinamica = ({
  headers, secciones, onChange,
}) => {
  const classes = styles();
  const [seccionSeleccionada, setSeccionSeleccionada] = useState(null);

  /**
   * @param {index utilizado para localizar que modulo es seleccionado} index 
   * @param {Valor en casilla para agregar true o false} checked 
   * Esta funcion sirve para seleccionar las casillas en modulo una por una
   * guarda el valor de la casilla en checked, utiliza el index para ubicar la casilla del modulo
   * seccionSeleccionada sirve para ubicar los modulos atraves del index de este
   * nuevas seccioenes es una copia de secciones
   */
  const onModuloChecked = (index, checked) => {
    const nuevasSecciones = [...secciones];
    seccionSeleccionada.Modulos[index].checked = checked;
    nuevasSecciones[seccionSeleccionada.index] = seccionSeleccionada;
    onChange(nuevasSecciones);
  }

  /**
    * @param {Utlizado para localizar que seccion es seleccionada} index
    * @param {valor en las casillas } checked
    * Esta funcion sirve para seleccionar todas las casillas de modulos con un click en secciones
    * Muestras los modulos de cada seccion 
    * 
    */

  const onSeccionChecked = (index, checked) => {
    const nuevasSecciones = [...secciones];
    nuevasSecciones[index].Modulos = nuevasSecciones[index].Modulos
      .map((modulo) => ({
        ...modulo,
        checked,
      }));
    nuevasSecciones[index].index = index;
    setSeccionSeleccionada(nuevasSecciones[index]);
    onChange(nuevasSecciones);
  };

  return (
    <div >
      <table className={clsx(classes.table)} style={{ width: '90%' }}>
        <thead>
          <tr >
            {headers.map((head, index) => (
              <th key={index} className={classes.titulosTabla} >

                <Typography className={classes.label}>
                  {head.label}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.margenSeccion} >
              {secciones.map((seccion, index) => {

                return (
                  <ul
                    onClick={() => setSeccionSeleccionada({ ...seccion, index })}
                    key={seccion.id}
                    className={clsx(classes.seccion, {
                      [classes.active]: seccionSeleccionada?.id === seccion.id
                    })}
                  >
                    {/* COLUMNA IZQUIERDA */}
                    <Checkbox className={classes.checkbox}
                      onClick={(e) => onSeccionChecked(index, e.target.checked)}
                      checked={!Boolean(seccion.Modulos.some(({ checked }) => !checked))}
                    />
                    <span
                    /* Render */
                    >
                      <Typography className={classes.label}>
                        {seccion.Nombre}
                      </Typography>
                    </span>
                  </ul>);
              })
              }
            </td>

            <td className={classes.margenModulo}>
              {seccionSeleccionada && seccionSeleccionada.Modulos.map((modulo, index) => {

                return (
                  <ul key={index} className={clsx(classes.modulo, {
                  })}
                  >
                    {/* COLUMNA DERECHA */}
                    <Checkbox className={classes.checkbox}
                      onClick={(e) => onModuloChecked(index, e.target.checked)}
                      checked={modulo.checked || false}
                    />
                    <Typography className={classes.label}>
                      {modulo.Nombre}
                    </Typography>
                  </ul>
                )
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
TablaDinamica.propTypes = {
  /** Array de cabeceros para mostrar en la tabla */
  headers: propTypes.arrayOf(propTypes.shape({ label: string, })).isRequired,
  /** Array de valores para desplegar en la tabla */
  secciones: propTypes.arrayOf(propTypes.shape({
    Nombre: string,
    id: string,
    Modulos: propTypes.arrayOf(propTypes.shape({ Enum: string, Nombre: string })),
  })).isRequired,
  /** Funcion para cambiar el estado en el padre */
  onChange: propTypes.func.isRequired,
};

TablaDinamica.defaultProps = {

};

export default React.memo(TablaDinamica);
