import { makeStyles } from '@material-ui/core';


const styles = makeStyles((theme) => ({

  botonDentro:{
    borderWidth: '2px',
    borderColor: '#bdbdbd',
    borderRadius: '20px ',
    border: 'solid',
    padding: '5px !important',
  },
  textoCentrado: {
    textAlign: 'center',
    padding: 1,
    fontSize: '12px',
  },

  botonCentrado:{
    justifyContent: 'center'
  },
  spinner: {
    color: '#408080',
    size: 100
  },
  spinnerBox:{
    display: 'grid !important',
    placeItems: 'center !important'
  }
}));

export default styles;