import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: paleta.bar.user,
    borderRadius: 10,
    padding: theme.spacing(0.5, 1),
    color: paleta.sidebar.backgroundColor,
    userSelect: 'none',
    '&.disabled': {
      backgroundColor: paleta.activo.primary,
    },
  },
  Normal: {
    backgroundColor: paleta.bar.user,
    borderRadius: 10,
    padding: theme.spacing(0.5, 1.8),
    color: paleta.sidebar.backgroundColor,
    userSelect: 'none',
    '&.Incidencia': {
      backgroundColor: '#fb94a6',
      padding: theme.spacing(0.5, 1),
    },
  },
}));

export default styles;
