import { memo, useCallback, useState, useEffect } from 'react';
import { HiDownload } from 'react-icons/hi';

import propTypes from 'prop-types';

import moment from 'moment-timezone';
import { Box, Typography, Grid } from '@material-ui/core';

import Button from '../Button';
import RightDrawer from '../RightDrawer';
import EmpleadoInfo from '../EmpleadoInfo';
import Table from '../Table';

import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import { DescargarDocumento } from '../../utilidades/functions';

const ReporteChecklDetalle = ({
  empleado, fechaInicio, fechaFin, isOpen, onClose, cabeceros, apiKey, tituloReporte
}) => {
  const registrosPorPagina = 10;
  const [pagina, setPagina] = useState(1);
  const [checks, setChecks] = useState([]);

  const handlePaginationChange = useCallback((pagina)  => {
    setPagina(pagina);
  }, []);

  const consultarChecks = useCallback(async () => {
    if (!empleado || !isOpen) return;

    const { _id: empleadoId } = empleado;
    const checksResponse = await axios.get(
      endpoints.base.url(apiKey, `empleado/${empleadoId}`),
     {
      params: {
        empleadoId,
        pagina,
        registrosPorPagina,
        fechaInicio,
        fechaFin,
      }
    });
    setChecks(checksResponse)
  }, [empleado, isOpen, apiKey, pagina, fechaInicio, fechaFin]);

  const descargarPdf = useCallback(async () => {
    const { _id } = empleado
    const response = await axios.get(
      endpoints.base.url(apiKey, `empleado/${_id}/pdf`),
      {
        responseType: 'arraybuffer',
        params: {
          fechaInicio,
          fechaFin,
        }
      }
    );

    DescargarDocumento({ response, tipoArchivo: 'pdf' });
  }, [apiKey, empleado, fechaFin, fechaInicio]);

  useEffect(() => {
    consultarChecks();
  }, [consultarChecks]);

  useEffect(() => {
    if (!isOpen) setPagina(1);
  }, [isOpen]);

  return (
    <>
      <RightDrawer
        isOpen={isOpen}
        header={
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: 'bold' }} variant="h6">{tituloReporte}</Typography>
            <Button
              label='Cerrar'
              onClick={onClose}
              border
            />
          </Box>}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EmpleadoInfo empleado={empleado} />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column" gap={1}>
                {(fechaInicio || fechaFin) && <Typography style={{ fontWeight: 'bold' }}>
                  Período
                </Typography>}
                {fechaInicio && fechaFin && <Typography style={{ textTransform: 'capitalize'}}>
                  {moment(fechaInicio).format('DD MMM YYYY')} - {moment(fechaFin).format('DD MMM YYYY')}
                </Typography>}
                {fechaInicio && !fechaFin && <Typography style={{ textTransform: 'capitalize'}}>
                  A partir de {moment(fechaInicio).format('DD MMM YYYY')}
                </Typography>}
                {!fechaInicio && fechaFin && <Typography style={{ textTransform: 'capitalize'}}>
                  Antes de {moment(fechaFin).format('DD MMM YYYY')}
                </Typography>}
              </Box>
              <Button
                label="PDF"
                onClick={descargarPdf}
                icono={<HiDownload />}
                border
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Table
              headers={cabeceros}
              rows={checks?.docs || []}
              count={checks?.totalDocs || 0}
              onPageChange={handlePaginationChange}
              pagina={pagina}
              showActions={false}
            />
          </Grid>
        </Grid>
      </RightDrawer>
    </>
  )
};

ReporteChecklDetalle.propTypes = {
  /** Objeto empleado */
  empleado: propTypes.object,
  /** Fecha inicio del périodo de checks */
  fechaInicio: propTypes.string,
  /** Fecha fin del périodo de checks */
  fechaFin: propTypes.string,
  /** Bandera que inidica si el drawer está abierto */
  isOpen: propTypes.bool,
  /** Callback que se va a ejecutar cuando se dé click en el botón cerrar */
  onClose: propTypes.func,
}

export default memo(ReporteChecklDetalle);
