
import 'react-image-crop/dist/ReactCrop.css'
import React, { useCallback, useEffect, useState } from 'react'
import { MdOutlineCameraAlt } from 'react-icons/md';
import { GrPersonalComputer, GrImage } from 'react-icons/gr';

import clsx from 'clsx';
import propTypes from 'prop-types';
import { Grid, Box, IconButton, Avatar, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import styles from './styles';
import Modal from '../Modal';
import Boton from '../Button';
import Drag from '../Drag';
import PhotoCrop from '../PhotoCrop';
import ImageCrop from '../ImageCrop';
import axios from '../../configuraciones/axios';
import endpoints, { AVATARESPREDEFINIDOS } from '../../configuraciones/endpoints';
import { urltoFile } from '../../utilidades/functions';

export const BotonAvatar = (
  { url, onChange }
) => {
  const [preview, setPreview] = useState();                           //Visualizacion de la imagen en avatar
  const [avatarImg, setAvatarImg] = useState()                        //Imagen mostrado en avatar principal
  const [avatarSeleccion, setAvatarSeleccion] = useState(null)        //Datos de avatar seleccionado
  const [show, setShow] = useState(false);                            //Abrir menu
  const [value, setValue] = useState('1');                            //Ventana de menu abierto
  const [avatar, setAvatar] = useState()                              //Arreglo de avatar traido desde base de datos

  const classes = styles();

  /** Hacer consulta de avatares predefinidos dados de alta en la base de datos */
  const consultarDatosIniciales = useCallback(async () => {
    const promesas = [
      axios.get(endpoints.base.url(AVATARESPREDEFINIDOS)),
    ];
    const [opcionesAvatar] = await Promise.all(promesas);
    const opcionesDefinidas = opcionesAvatar.map(({ URL, _id }) => ({ URL, Enum: _id }));
    opcionesDefinidas.pop();
    setAvatar(opcionesDefinidas);
  }, [])

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  //Funcion para mostrar el menu onclick
  const handleButtonShow = () => {
    setShow(!show)
  };

  //Funcion para cerrar el menu onclick
  const cancelar = useCallback(() => {
    setShow(false);
  }, []);

  /** Maneja el cambio de tabs */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /** Guardar Avatar mostrados */
  const saveDefaultAvatar = () => {
    setAvatarImg(avatarSeleccion.URL);
    setPreview(avatarSeleccion.URL);
    setShow(false);
  }

  /** Recibir foto de disco local */
  const handleSeleccion = (Avatar) => {
    if (Avatar) {
      setPreview(Avatar)
      setAvatarSeleccion(null);
      urltoFile(Avatar, 'PerfilAvatar', 'text/plain').then(function (file) { setAvatarImg(file); });
    }
  }
  /** Se envia la imagen recortada al padre */
  useEffect(() => {
    if (show) {
      onChange(avatarImg);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarImg, show]);

  useEffect(() => {
    if (avatarSeleccion && avatarSeleccion.URL) {
      setAvatarImg(avatarSeleccion.URL);
    }
  }, [avatarSeleccion]);

  return (
    <Grid container item spacing={0} className={classes.box}>
      <Avatar
        src={preview || url}
        className={classes.avatar}
      />
      <Box>
        <IconButton
          className={classes.boton}
          onClick={handleButtonShow}
        >
          <MdOutlineCameraAlt />
        </IconButton>
      </Box>
      <Modal
        titulo={'Cambia la foto de perfil'}
        width={'xs'}
        open={show}
        handleClose={cancelar}
        children={
          <Grid >
            <TabContext value={value} >
              <Box style={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <TabList onChange={handleChange} centered>
                  <Tab
                    icon={<GrImage className={classes.iconos} />}
                    label="Ilustraciones"
                    value="1"
                  />
                  <Tab
                    icon={<GrPersonalComputer className={classes.iconos} />}
                    label="Desde la Computadora" value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container className={classes.tableroAvatares}>
                  {avatar ? (
                    avatar.map(({ URL, Enum }, index) =>
                      <React.Fragment key={Enum}>
                        <Avatar
                          src={URL}
                          onClick={() => setAvatarSeleccion({ URL, Enum })}
                          className={clsx(classes.avatares, {
                            [classes.avatarSeleccion]: avatarSeleccion?.URL === URL
                          })}
                        />
                      </React.Fragment>
                    )
                  ) : (
                    <h1> Cargando </h1>
                  )}
                </Grid>
                {avatarSeleccion ?
                  <Grid className={classes.guardar}
                  >
                    <Boton
                      label="Guardar como foto de perfil"
                      onClick={saveDefaultAvatar}
                    />
                  </Grid>
                  : ''}
              </TabPanel>
              <TabPanel value="2">
                <Grid style={{ paddingTop: '40px' }}>
                  <Avatar
                    className={classes.imagenComputadora}
                    src={preview || url}
                  />
                  {/* Arrastrar y soltar */}
                  <Drag
                    onChange={handleSeleccion}
                  />
                  <Grid flex="true" container justifyContent='space-evenly'>
                    {/*Elegir desde disco local*/}
                    <ImageCrop
                      onChange={handleSeleccion}
                    />
                    {/* Tomar fotografia */}
                    <PhotoCrop
                      onChange={handleSeleccion}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        }
      />
    </Grid>
  );
}

BotonAvatar.propTypes = {
  /* Mandará la imagen al padre */
  onChange: propTypes.func,
  /* Recibe el url de la imagen desde el padre */
  url: propTypes.string
}

export default React.memo(BotonAvatar);
