import React from 'react';

import moment from 'moment-timezone';

import Estatus from '../../componentes/Estatus';
import ReportesGrupal from '../../contenedores/Reporte';
import endpoints, { REPORTE_ASISTENCIAS_GRUPAL } from '../../configuraciones/endpoints';
import { capitalizar } from '../../utilidades/functions';

const cabeceros = [
  { label: 'Empleado', transform: ({ Empleado }) => Empleado ? Empleado.NombreCompleto : null },
  { label: 'Empresa', transform: ({ Empleado }) => Empleado ? Empleado.Empresa.Nombre : null },
  { label: 'Sucursal', transform: ({ Empleado }) => Empleado ? Empleado.Sucursal.Nombre : null },
  { label: 'Departamento', transform: ({ Empleado }) => Empleado ? Empleado.Departamento.Nombre : null },
  { label: 'Puesto', transform: ({ Empleado }) => Empleado ? Empleado.Puesto.Nombre : null },
  { label: 'Fecha', transform: ({ FechaAsistencia }) => FechaAsistencia && capitalizar(moment(FechaAsistencia).format('DD MMMM YYYY')) },
  { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion ? Evaluacion.Nombre : null },
  { label: 'Estatus', transform: ({ Estatus: estatusRegistro }) => estatusRegistro ? <Estatus Cadena={estatusRegistro.Nombre} Habilitado={false} /> : null },
];

const AsistenciaGrupal = ({ history, location }) => {

  return (
    <>
      <ReportesGrupal
        titulo="Reporte de asistencia grupal"
        placeHolder=""
        history={history}
        location={location}
        showActions={false}
        filtrosBusqueda={{
          empresa: true,
        }}
        mostrar={{
          txtBusqueda: false,
          Agregar: false,
          SideBar: true,
        }}
        cabeceros={cabeceros || []}
        apiKey={REPORTE_ASISTENCIAS_GRUPAL}
        apiKeyExcel={endpoints.base.url(REPORTE_ASISTENCIAS_GRUPAL, 'excel')}
        cabeceroReporte
        excel
      />
    </>
  );
};

export default AsistenciaGrupal;
