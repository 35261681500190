import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    textBlue: {
        color: '#3b6175',
        marginRight: '.3rem'
    },
    textGray: {
        color: '#a4a8aa',
    },
    boton: {
        height: '20px important!',
        padding: '0 important!'
    },
    botonRechazar: {
        backgroundColor: '#FFF',
        color: '#003366 !important'
    },
    botones: {
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
    },
    evidencia: {
        objectFit: 'cover',
        maxWidth: '400px',
    }
}))

export default styles;