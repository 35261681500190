/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense, useEffect, useState } from 'react';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppBar from '../componentes/AppBar';

import NotFound from '../paginas/404';
import Forbidden from '../paginas/401';
import Login from '../paginas/Login';
import Dashboard from '../paginas/Dashboard';
import Usuarios from '../paginas/Usuarios';
import Usuario from '../paginas/Usuario';
import Perfil from '../paginas/Perfil';
import Perfiles from '../paginas/Perfiles';
import Sucursales from '../paginas/Sucursales'
import Sucursal from '../paginas/Sucursal'
import Empresa from '../paginas/Empresa';
import Empresas from '../paginas/Empresas';
import Horario from '../paginas/Horario';
import Horarios from '../paginas/Horarios';
import Departamentos from '../paginas/Departamentos';
import Departamento from '../paginas/Departamento';
import Puestos from '../paginas/Puestos';
import Puesto from '../paginas/Puesto';
import Empleados from '../paginas/Empleados';
import Empleado from '../paginas/Empleado'
import Justificantes from '../paginas/Justificantes';
import Registros from '../paginas/Registros';
import Asistencias from '../paginas/Asistencias';
import AsistenciaGrupal from '../paginas/AsistenciaGrupal';
import AsistenciaIndividual from '../paginas/AsistenciaIndividual';
import CheckGrupal from '../paginas/CheckGrupal';
import CheckIndividual from '../paginas/CheckIndividual';
import MiCuenta from '../paginas/MiCuenta';
import Planes from '../paginas/Planes';
import ChatSoporte from '../paginas/ChatSoporte';

export default () => {
  const user = useSelector(store => store.user);
  const PrivateRoute = ({ component, ...rest }) => {
    const [tienePermiso, setTienePermiso] = useState(true);
    const { isAuth, permisos, permisosConsultados } = useSelector(({
      user: { isAuth }, appbar: { permisos, permisosConsultados }
    }) => ({ isAuth, permisos, permisosConsultados }));
    rest.location.state = { ...rest.state, ...rest.location.state };

    useEffect(() => {
      if (permisosConsultados && rest.location.state.moduloID) {
        setTienePermiso(Boolean(permisos.find(({ Permisos }) => Permisos === rest.location.state.moduloID)));       //Manejo de permisos
      }
    }, [rest.location, permisos, permisosConsultados, tienePermiso]);

    return (
      <Route
        {...rest}
        render={(props) => (isAuth && tienePermiso ? (
          React.createElement(component, props)
        ) : (
          <Redirect to={{ pathname: !isAuth ? '/login' : '/forbidden', state: { from: props.location, }, }} />
        ))
        }
      />
    );
  };
  const PublicRoute = ({ component, ...rest }) => {
    rest.location.state = { ...rest.state, ...rest.location.state };
    const { isAuth } = useSelector(({ user: { isAuth } }) => ({ isAuth }));
    return (
      <Route
        {...rest}
        render={(props) => (isAuth ? (
          <Redirect to={{ pathname: '/dashboard', }} />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  };

  return (
    <HashRouter>
      {(user.isAuth) && <AppBar />}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PublicRoute path={['/', '/login']} component={Login} exact />
          <PrivateRoute path="/dashboard" component={Dashboard} exact />
          <PrivateRoute path="/catalogos/usuarios" component={Usuarios} state={{ moduloID: 'Usuarios' }} exact />
          <PrivateRoute path="/catalogos/usuarios/formulario/:_id?" state={{ moduloID: 'Usuarios' }} component={Usuario} exact />
          <PrivateRoute path="/catalogos/perfiles/formulario/:_id?" state={{ moduloID: 'Perfiles' }} component={Perfil} exact />
          <PrivateRoute path="/catalogos/perfiles" component={Perfiles} state={{ moduloID: 'Perfiles' }} exact />
          <PrivateRoute path="/catalogos/empresas" component={Empresas} state={{ moduloID: 'Empresas' }} exact />
          <PrivateRoute path="/catalogos/empresas/formulario/:_id?" state={{ moduloID: 'Empresas' }} component={Empresa} exact />
          <PrivateRoute path="/catalogos/sucursales" component={Sucursales} state={{ moduloID: 'Sucursales' }} exact />
          <PrivateRoute path="/catalogos/sucursales/formulario/:_id?" state={{ moduloID: 'Sucursales' }} component={Sucursal} exact />
          <PrivateRoute path="/catalogos/horarios" component={Horarios} state={{ moduloID: 'Horarios' }} exact />
          <PrivateRoute path="/catalogos/horarios/formulario" component={Horario} state={{ moduloID: 'Horarios' }} exact />
          <PrivateRoute path="/catalogos/horarios/formulario/:_id?" component={Horario} state={{ moduloID: 'Horarios' }} exact />
          <PrivateRoute path="/catalogos/puestos" component={Puestos} state={{ moduloID: 'Puestos' }} exact />
          <PrivateRoute path="/catalogos/puestos/formulario/:_id?" state={{ moduloID: 'Puestos' }} component={Puesto} exact />
          <PrivateRoute path="/catalogos/departamentos" component={Departamentos} state={{ moduloID: 'Departamentos' }} exact />
          <PrivateRoute path="/catalogos/departamentos/formulario/:_id?" state={{ moduloID: 'Departamentos' }} component={Departamento} exact />
          <PrivateRoute path="/check/empleados" component={Empleados} state={{ moduloID: 'Empleados' }} exact />
          <PrivateRoute path="/check/empleados/formulario/:_id?" state={{ moduloID: 'Empleados' }} component={Empleado} exact />
          <PrivateRoute path="/check/justificantes" state={{ moduloID: 'Justificantes' }} component={Justificantes} exact />
          <PrivateRoute path="/check/registrosCheck" component={Registros} state={{ moduloID: 'Registros check' }} exact />
          <PrivateRoute path="/check/asistencias" component={Asistencias} state={{ moduloID: 'Asistencias' }} exact />
          <PrivateRoute path="/reportes/asistenciaGrupal" component={AsistenciaGrupal} state={{ moduloID: 'AsistenciaGrupal' }} exact />
          <PrivateRoute path="/reportes/asistenciaPersonal" component={AsistenciaIndividual} state={{ moduloID: 'AsistenciaPersonal' }} exact />
          <PrivateRoute path="/reportes/checkGrupal" component={CheckGrupal} state={{ moduloID: 'CheckGrupal' }} exact />
          <PrivateRoute path="/reportes/checkPersonal" component={CheckIndividual} state={{ moduloID: 'CheckPersonal' }} exact />
          <PrivateRoute path="/configuracion/cuenta" component={MiCuenta} state={{ moduloID: 'MiCuenta' }} exact />
          <PrivateRoute path="/configuracion/planes" component={Planes} state={{ moduloID: 'PlanesSuscripcion' }} exact />
          <PrivateRoute path="/configuracion/chat" component={ChatSoporte} state={{ moduloID: 'ChatSoporte' }} exact />
          <Route path="/forbidden" component={Forbidden} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </HashRouter>
  );
};
