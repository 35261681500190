import { memo } from 'react';

import propTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import Typography from '../Typography';

import styles from './styles';

const Plan = ({
  nombre, descripcion, precio,
  accion, icono, esPlanActual,
}) => {
  const classes = styles();

  return (
    <Grid
      lg={4}
      xs={12}
      item
    >
      <Grid
        container
        rowSpacing={4}
        className={classes.root}
        style={{
          color: esPlanActual ? 'white' : 'black',
          backgroundColor: esPlanActual ? '#1F4078' : '#ECF5F9',
          borderRadius: 8,
          minHeight: 320,
        }}
        px={4}
      >
        <Grid item xs={12} display='flex' flexDirection='column' gap={1}>
          <Box display='flex' gap={1} alignItems='center'>
            {icono}
            <Typography component='h1' style={{ color: 'inherit' }}>{nombre}</Typography>
          </Box>
          <Typography style={{ color: '#969696', fontSize: 14 }} >{descripcion}</Typography>
        </Grid>
        <Grid item xs={12}>
          {precio}
        </Grid>
        <Grid item xs={12} mb={4}>
          {accion}
        </Grid>
      </Grid>
    </Grid>
  );
}

Plan.propTypes = {
  /** Texto que se va a desplegar como nombre del plan */
  nombre: propTypes.string.isRequired,
  /** Icono del plan */
  icono: propTypes.node,
  /** Texto que se va a desplegar como descripción del plan */
  descripcion: propTypes.string.isRequired,
  /** Texto o componente que se va a desplegar como precio del plan */
  precio: propTypes.oneOfType([propTypes.string, propTypes.node]),
  /** Botón de acción para el plan */
  accion: propTypes.node.isRequired,
  /** Bandera que indica si es plan actual */
  esPlanActual: propTypes.bool,
}

Plan.defaultProps = {
  oscuro: false,
  icono: '',
}


export default memo(Plan);
