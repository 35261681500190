import PropTypes from 'prop-types'
import { Box } from '@mui/material';

import Typography from '../Typography';

function PriceTag({
  precio, unidad
}) {
  return (
    <Box display="flex" gap={1} alignItems="flex-end">
      <Typography variant='h3' style={{ color: '#CBD4D8'}}>$</Typography>
      <Typography variant='h3' style={{ margin: 0, size: 22, color: 'inherit' }}>{precio}</Typography>
      <Typography variant='subtitle1' style={{ color: '#CBD4D8', paddingBottom: '6px' }}>{unidad}</Typography>
    </Box>
  )
}

PriceTag.propTypes = {
  /** Número que se va a desplegar como precio */
  precio: PropTypes.number.isRequired,
  /** Unidad de la etiqueta */
  unidad: PropTypes.string.isRequired,
}

export default PriceTag;
