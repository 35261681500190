import React, { useCallback, useEffect, useState } from "react"

import { Box, Grid } from "@material-ui/core"
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep'

import styles from './styles';

import Default from "../../contenedores/Default"

import Select from '../../componentes/Select';
import TextField from "../../componentes/TextField"
import ConfiguracionHorario from "../../componentes/ConfiguracionHorario";

import axios from '../../configuraciones/axios';
import endpoints, { HORARIOS } from "../../configuraciones/endpoints";

import estatus from "../../constantes/estatus";
import Typography from "../../componentes/Typography";
import { findPropertysEmpty, requiredFIle } from '../../utilidades/functions';
import { CONFIGURACION_HORARIO_INVALIDA, ELEMENTO_NO_ENCONTRADO, HORARIO_SIN_PERIODOS, SUCCESS } from "../../configuraciones/mensajes";
import { LETTERSSPECIALCHARACTER } from "../../utilidades/regex";


const Horario = ({ history, match }) => {
  const [dias, setDias] = useState([]);
  const [horario, setHorario] = useState({});
  const [detalle, setDetalle] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [tiposHorario, setTiposHorario] = useState([]);
  const [detalleErrors, setDetalleErrors] = useState({});
  const [tipoHorarioSeleccionado, setTipoHorarioSeleccionado] = useState(null);

  const { _id } = match.params;

  const classes = styles();

  //método para regresar
  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/horarios',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  }, [history]);

  //Realiza fetch a los datos inicales de la pantalla
  const consultarDatosIniciales = useCallback(async () => {
    const consultas = [
      axios.get(endpoints.base.url(HORARIOS, 'dias')),
      axios.get(endpoints.base.url(HORARIOS, 'tipos')),
    ]

    if (_id) consultas.push(axios.get(endpoints.base.url(HORARIOS, _id)));

    const [diasResponse, tiposHorarioResponse, horarioResponse] = await Promise.all(consultas);

    if (_id && !horarioResponse) {
      toast.warning(ELEMENTO_NO_ENCONTRADO);
      regresar();
      return;
    }

    if (horarioResponse) {
      setHorario(horarioResponse);
      setDetalle(horarioResponse.HorarioDetalle.map((detalle) => {

        detalle.Periodos = detalle.Periodos.map(({ Entrada, Salida, ...periodo }) =>
        ({
          ...periodo,
          Entrada: moment(`${moment().format('YYYY-MM-DD')} ${Entrada}`),
          Salida: moment(`${moment().format('YYYY-MM-DD')} ${Salida}`)
        })
        );

        return detalle;
      }));
    }
    else {
      setDetalle(diasResponse.map(({ Nombre: Dia, Numero: DiaNumero }) => ({ Dia, DiaNumero, Periodos: [], Habilitado: true })));
    }
    setDias(diasResponse);
    setTiposHorario(tiposHorarioResponse);
  }, [_id, regresar]);

  //método para guardar el horario o editar
  const guardar = useCallback(async () => {
    setIsSubmit(true);
    const {
      errors,
      regex,
      totalErrors,
      totalRegex
    } = findPropertysEmpty(document.getElementById('frmHorario'), true);

    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    if (Object.values(detalleErrors).some(value => !!value)) {
      toast.error(CONFIGURACION_HORARIO_INVALIDA);
      return;
    }

    let periodosConfigurados = 0;

    const detalleGuardar = cloneDeep(detalle).flatMap((detalle) => {
      if (!detalle.Habilitado) {
        detalle.Periodos = [];
        return detalle;
      }

      detalle.Periodos = detalle.Periodos.map(({ Entrada, Salida, Periodo }) =>
      ({
        Periodo,
        Entrada: moment(Entrada).format('HH:mm'),
        Salida: moment(Salida).format('HH:mm'),
      })
      );
      periodosConfigurados++;
      return detalle;
    })

    if (!periodosConfigurados) {
      toast.error(HORARIO_SIN_PERIODOS);
      return;
    }

    const horarioGuardar = {
      ...horario,
      HorarioDetalle: detalleGuardar,
    }

    if (_id) {
      const originalHorarioResponse = await axios.get(endpoints.base.url(HORARIOS, _id));

      if (JSON.stringify(horarioGuardar) === JSON.stringify(originalHorarioResponse)) {
        horarioGuardar.changes = true;
      }

      await axios.put(endpoints.base.url(HORARIOS, _id), horarioGuardar)
    } else {
      await axios.post(HORARIOS, horarioGuardar);
    }

    toast.success(SUCCESS);
    regresar();
  }, [_id, horario, detalle, detalleErrors, regresar]);

  //Actualiza los datos recibidos por los compenentes de configuracion de horario
  const handleConfiguracionChange = useCallback((configuracion) => {
    setDetalle(current => {
      const nuevoDetalle = current.filter(({ Dia }) => Dia !== configuracion.Dia);
      nuevoDetalle.push(configuracion);
      return nuevoDetalle;
    });
  }, []);

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  useEffect(() => {
    const tipoSeleccionado = tiposHorario.find(({ _id }) => _id === horario.TipoHorario);
    setTipoHorarioSeleccionado(tipoSeleccionado);
  }, [horario, tiposHorario]);

  return (
    <Default
      titulo={Boolean(_id) ? 'Editar Horario' : 'Nuevo Horario'}
      mostrarCabeceroFormulario={false}
      guardar={guardar}
      cancelar={regresar}
    >
      <Grid container spacing={2} id="frmHorario">
        <Grid item lg={4} xs={12}>
          <TextField
            label="Nombre del horario"
            name="Nombre"
            onChange={setHorario}
            value={horario.Nombre}
            error={formErrors.Nombre}
            isHandleChange
            inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
            helperText={requiredFIle(isSubmit, formErrors, 'Nombre')}
            required
          />
          <TextField
            label="Descripción"
            name="Descripcion"
            type="textarea"
            rows={6}
            value={horario.Descripcion ?? ''}
            onChange={setHorario}
            error={formErrors.Descripcion}
            isHandleChange
            inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 200, regexonchange: 'true' }}
          />
          <Select
            label="Tipo Horario"
            labelProp="Nombre"
            valueProp="_id"
            name="TipoHorario"
            options={tiposHorario}
            onChange={setHorario}
            value={tiposHorario.find(e => e._id === horario.TipoHorario) ? horario.TipoHorario : ''}
            placeHolder={'Seleccione'}
            error={formErrors.TipoHorario}
            isHandleChange
            helperText={requiredFIle(isSubmit, formErrors, 'TipoHorario')}
            required
          />
          <Select
            label="Estatus"
            name="Habilitado"
            options={estatus}
            onChange={setHorario}
            value={horario.Habilitado}
            placeHolder="Seleccione"
            error={formErrors.Habilitado}
            isHandleChange
            helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
            required
          />
        </Grid>
        <Grid item lg={8} xs={12}>
          <Typography style={{ fontSize: 12, fontWeight: 'bold', }}>
            Configuración de horario
          </Typography>
          <Box className={classes.contenedorConfiguracion}>
            {tipoHorarioSeleccionado && dias.map(
              dia =>
                <ConfiguracionHorario
                  key={dia._id}
                  horarioId={_id}
                  dia={dia.Nombre}
                  onChange={handleConfiguracionChange}
                  value={detalle.find(({ Dia }) => Dia === dia.Nombre)}
                  tipoHorario={tipoHorarioSeleccionado}
                  errors={detalleErrors}
                  onError={setDetalleErrors}
                />)
            }
          </Box>
        </Grid>
      </Grid>
    </Default>
  )
}

export default React.memo(Horario)
