import React, { useCallback, useEffect, useState } from 'react';
import {
  AppBar as MaterialBar, Toolbar,
  Box, Tabs, Tab, Button,
  Menu, MenuItem, Hidden,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RiArrowDownSFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import Typography from '../../componentes/Typography';
import { MENU_OPCIONES, quitarPermisos, setOpcionActiva, setPermisos } from '../../ducks/appbar';
import { STORAGE, removeToken } from '../../ducks/user';

import axios from '../../configuraciones/axios';
import { USUARIO_NO_PERMISO } from '../../configuraciones/mensajes';

import styles from './styles';
import endpoints, { PERFILES } from '../../configuraciones/endpoints';

const AppBar = () => {
  const classes = styles();
  const { indexActivo, permisos, ocultarOpciones } = useSelector(({
    appbar: { indexActivo, permisos, ocultarOpciones },
    user: { user }
  }) => ({ indexActivo, permisos, user, ocultarOpciones }));
  const dispatch = useDispatch();
  const history = useHistory();
  const [userAnchor, setUserAnchor] = useState(null);

  //Manejo de opcion Activa en la barra
  const handleClick = (opcion) => {
    if (permisos.map(permiso => permiso.moduloID).includes(opcion.id) || opcion.index === 0) {
      sessionStorage.setItem('tabIndex', opcion.index);
      dispatch(setOpcionActiva(opcion.index));

      history.replace(opcion.path)
    }
    else {
      toast.error(USUARIO_NO_PERMISO);
    }
  }

  const cerrarSesion = () => {
    dispatch(removeToken());
    dispatch(quitarPermisos());
    sessionStorage.clear();
  };

  const usuario = JSON.parse(localStorage.getItem(STORAGE) || sessionStorage.getItem(STORAGE));

  const consultarDatosPermisos = useCallback(async () => {
    const perfiles = await axios.get(endpoints.base.url(PERFILES, `usuario/${usuario.id}`))
    const infoPermisos = perfiles.PerfilModulos.map(({ Enum }) => ({ Permisos: Enum })) //Permisos en modulos
    dispatch(setPermisos(infoPermisos));
  }, [dispatch, usuario.id])

  useEffect(() => {
    if (permisos.length === 0) {
      consultarDatosPermisos();
    };
  }, [consultarDatosPermisos, permisos]);

  return (
    <div>
      <MaterialBar position="static" className={classes.root}>
        <Toolbar>
          <Box flex={1} style={{ height: '64px', display: 'flex' }} flexDirection="row">
            <Box className={classes.logoContainer}>
              <img
                alt="logo"
                src="/assets/images/Logo_check_assistance_Appbar.svg"
                className={classes.logo} />
            </Box>
            {!ocultarOpciones && <Hidden smDown>
              <Box flex={4}>
                <Tabs
                  value={indexActivo}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  {MENU_OPCIONES
                    .map((opcion, index) => (
                      <Tab
                        key={index}
                        label={opcion.label}
                        className={classes.tab}
                        value={opcion.index}
                        onClick={() => handleClick(opcion)}
                      />
                    ))}
                </Tabs>
              </Box>
            </Hidden>}
            <Box flex={1} className={classes.userContainer}>
              <Menu
                keepMounted={false}
                open={Boolean(userAnchor)}
                anchorEl={userAnchor}
                onClose={() => setUserAnchor(null)}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <MenuItem onClick={cerrarSesion}>Cerrar sesión</MenuItem>
              </Menu>
              <Button
                variant="text"
                className={classes.user}
                onClick={({ currentTarget }) => setUserAnchor(currentTarget)}>
                {usuario ?
                  <Typography>{usuario.nombre}</Typography> :
                  <Typography>{''}</Typography>
                }
                <RiArrowDownSFill />
              </Button>
            </Box>
          </Box>
        </Toolbar>
        {!ocultarOpciones && <Hidden mdUp>
          <Toolbar style={{ overflow: 'auto' }}>
            <Box flex={4}>
              <Tabs
                value={indexActivo}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {MENU_OPCIONES.map((opcion, index) => (
                  <Tab
                    key={index}
                    label={opcion.label}
                    className={classes.tab}
                    onClick={() => handleClick(opcion)}
                  />
                ))}
              </Tabs>
            </Box>
          </Toolbar>
        </Hidden>}
      </MaterialBar>
    </div>
  );
};

export default AppBar;
