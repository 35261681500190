/* eslint-disable import/no-anonymous-default-export */
export const MENU_OPCIONES = [
  {
    label: 'Inicio',
    path: '/dashboard',
    id: 'Inicio',
    index: 0,
  },
  {
    label: 'Check',
    submenu: [
      { label: 'Empleados', sublabel: 'Check', link: '/check/empleados', id: 'Empleados'},
      { label: 'Registros check', sublabel: 'Check', link: '/check/registrosCheck', id: 'Registros check'},
      { label: 'Asistencias', sublabel: 'Check', link: '/check/asistencias', id: 'Asistencias' },
      { label: 'Justificantes', sublabel: 'Check', link: '/check/justificantes', id: 'Justificantes'},
    ],
    path: '/dashboard',
    index: 1,
  },
  {
    label: 'Catálogos',
    submenu: [
      { label: 'Usuarios', sublabel: 'Catálogos', link: '/catalogos/usuarios', id: 'Usuarios' },
      { label: 'Perfiles', sublabel: 'Catálogos', link: '/catalogos/perfiles', id: 'Perfiles' },
      { label: 'Empresas', sublabel: 'Catálogos', link: '/catalogos/empresas', id: 'Empresas' },
      { label: 'Sucursales', sublabel: 'Catálogos', link: '/catalogos/sucursales', id: 'Sucursales' },
      { label: 'Departamentos', sublabel: 'Catálogos', link: '/catalogos/departamentos', id: 'Departamentos' },
      { label: 'Puestos', sublabel: 'Catálogos', link: '/catalogos/puestos', id: 'Puestos' },
      { label: 'Horarios', sublabel: 'Catálogos', link: '/catalogos/horarios', id: 'Horarios' },
    ],
    path: '/dashboard',
    index: 2,
  },
  {
    label: 'Reportes',
    submenu: [
      { label: 'Asistencia Grupal', sublabel: 'Reportes', link: '/reportes/asistenciaGrupal', id: 'AsistenciaGrupal' },
      { label: 'Check Grupal', sublabel: 'Reportes', link: '/reportes/checkGrupal', id: 'CheckGrupal' },
      { label: 'Asistencia Personal', sublabel: 'Reportes', link: '/reportes/asistenciaPersonal', id: 'AsistenciaPersonal' },
      { label: 'Check Personal', sublabel: 'Reportes', link: '/reportes/checkPersonal', id: 'CheckPersonal' },
    ],
    path: '/dashboard',
    index: 3,
  },
  {
    label: 'Configuración',
    submenu: [
      { label: 'Mi cuenta', sublabel: 'Configuracion', link: '/configuracion/cuenta', id: 'MiCuenta', },
      { label: 'Planes y suscripción', sublabel: 'Configuracion', link: '/configuracion/planes', id: 'PlanesSuscripcion', soloSuscriptor: true, },
      { label: 'Chat de soporte', sublabel: 'Configuracion', link: '/configuracion/chat', id: 'ChatSoporte', soloSuscriptor: true, },
    ],
    path: '/dashboard',
    index: 4,
  },
];

const SET_INDEX_ACTIVO = 'SET_INDEX_ACTIVO';
const SET_SIDEBAR_ISOPEN = 'SET_SIDEBAR_ISOPEN';
const SET_PERMISOS = 'SET_PERMISOS';
const QUITAR_PERMISOS = 'QUITAR_PERMISOS';
const OCULTAR_OPCIONES = 'OCULTAR_OPCIONES';

const tabInitIndex = Number(sessionStorage.getItem('tabIndex')) || 1;
const initialState = {
  indexActivo: tabInitIndex,
  sidebarIsOpen: false,
  permisos: [],
  permisosConsultados: false,
  ocultarOpciones: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INDEX_ACTIVO:
      return { ...state, indexActivo: payload };
    case SET_SIDEBAR_ISOPEN:
      return { ...state, sidebarIsOpen: !state.sidebarIsOpen };
    case SET_PERMISOS:
      return { ...state, permisos: payload, permisosConsultados: true };
    case QUITAR_PERMISOS:
      return { ...state, permisos: [], permisosConsultados: false };
    case OCULTAR_OPCIONES:
      return { ...state, ocultarOpciones: payload };
    default:
      return state;
  }
};

export const setOpcionActiva = (payload) => ({
  type: SET_INDEX_ACTIVO,
  payload,
});

export const setSidebarIsOpen = () => ({ type: SET_SIDEBAR_ISOPEN });

export const setPermisos = (payload) => ({ type: SET_PERMISOS, payload });

export const quitarPermisos = () => ({ type: QUITAR_PERMISOS });

export const ocultarOpciones = (payload) => ({ type: OCULTAR_OPCIONES, payload });
