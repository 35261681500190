import React from 'react';
import propTypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  Divider,
} from '@material-ui/core';
import { IoMdClose } from 'react-icons/io';
import styles from './styles';
import Typography from '../Typography';

const Modal = ({
  handleClose,
  open,
  titulo,
  children,
  showActions,
  actions,
  width
}) => {
  const classes = styles();
  return (
    <div>
      <Dialog 
      maxWidth={width}
      fullWidth
       onClose={handleClose} open={open}>
        <DialogTitle disableTypography className={classes.root}>
          <Typography variant='h6'>{titulo}</Typography>
          {handleClose ? (
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <IoMdClose />
            </IconButton>
          ) : null}
        </DialogTitle>
        <Divider orientation='horizontal' />
        <DialogContent className={classes.contenido}>{children}</DialogContent>
        {showActions && (
          <DialogActions className={classes.acciones}>{actions}</DialogActions>
        )}
      </Dialog>
    </div>
  );
};

Modal.propTypes = {
  /** Componente hijo a renderizar */
  children: propTypes.node.isRequired,
  /** Titulo/Label del modal */
  titulo: propTypes.string,
  /** Anchura del modal */
  width: propTypes.string
};

Modal.defaultProps = {
  titulo: '',
  showActions: false,
  actions: null,
  width: 'lg'
};

export default Modal;
