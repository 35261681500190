import React from 'react';
import propTypes from 'prop-types';
import { Button as Boton, Box } from '@material-ui/core';
import Typography from '../Typography';
import styles from './styles';
import clsx from 'clsx';

const Button = ({
  label, name, icono, classesCustom, onClick, border, isSubmit, variant, disabled
}) => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Boton
        variant={variant}
        className={clsx(classes.boton, classesCustom.boton, { [classes.border]: border })}
        name={name}
        onClick={onClick}
        type={isSubmit ? 'submit' : 'button'}
        disabled={disabled}
      >
        <Box flex={1} className={classes.box}>
          {icono}
          {label !== '' && <Typography className={clsx({ icono: Boolean(icono) })}>{label}</Typography>}
        </Box>
      </Boton>
    </div>
  );
};

Button.propTypes = {
  /* Etiqueta del botón **/
  label: propTypes.string,
  /* Nombre del botón **/
  name: propTypes.string,
  /* Icono a renderizar **/
  icono: propTypes.element,
  /* Objeto para las classes custom **/
  classesCustom: propTypes.shape({
    /* Propiedad que aplica las clases custom al botón **/
    boton: propTypes.string,
    /* Propiedad que aplica las clases custom al icono **/
    icono: propTypes.string,
    /* Propiedad que aplica las clases custom al texto **/
    texto: propTypes.string,
  }),
  /* Callback para el evento click del botón **/
  onClick: propTypes.func,
  /* Flag que indica si el botón será de tipo border **/
  border: propTypes.bool,
  /* Flag que indica si el botón es un submit **/
  isSubmit: propTypes.bool,
  /* Variante del botón **/
  variant: propTypes.string,
  /* Flag que deshabilita el botón **/
  disabled: propTypes.bool,
}

Button.defaultProps = {
  label: '',
  variant: 'contained',
  icono: null,
  classesCustom: {},
  onClick: null,
  border: false,
  isSubmit: false,
  disabled: false,
}

export default Button;
