import React from 'react';
import propTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import Typography from '../Typography';

import { IoIosArrowDown as ExpandMoreIcon } from "react-icons/io";

import styles from './styles';

export const CustomAccordion = ({ children, id, title }) => {
  const classes = styles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={id}
      >
        <Typography className={classes.heading}>{ title }</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.contentContainer}>
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
};

CustomAccordion.propTypes = {
  children: propTypes.node,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
  title: propTypes.string,
};

CustomAccordion.defaultProps = {
  children: null,
  id: null,
  title: '',
};

export default CustomAccordion;
