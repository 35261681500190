import React from 'react';

import moment from 'moment-timezone';

import Estatus from '../../../componentes/Estatus';
import Listados from '../../../contenedores/Listados';
import tipoEvaluaciones from '../../../constantes/Evaluaciones';
import { tipoEstatus } from '../../../constantes/estatus';
import { capitalizar } from '../../../utilidades/functions';
import { REGISTROS } from '../../../configuraciones/endpoints';

const cabeceros = [
  {

    label: 'Día', transform: ({ FechaCheck }) => FechaCheck && capitalizar(moment(FechaCheck).format('dddd'))
  },
  {
    label: 'Fecha', transform: ({ FechaCheck }) => FechaCheck && capitalizar(moment(FechaCheck).format('DD MMMM YYYY'))
  },
  { label: 'Check', key: 'HoraCheck' },
  { label: 'Horario', key: 'HoraHorario' },
  { label: 'Movimiento', key: 'Movimiento' },
  { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion && Evaluacion.Nombre },
  { label: 'Estatus', transform: ({ Estatus: estatusRegistro }) => estatusRegistro && <Estatus Cadena={estatusRegistro.Nombre} Habilitado={false} /> },
];

const EmpleadoCheck = ({ history, location, match }) => {
  const { params: { _id } } = match;

  return (

    <Listados
      ID={_id}
      history={history}
      location={location}
      mostrar={{
        fechaInicioFinal: true,
        txtBusqueda: false,
        Subtitulo: false,
        Agregar: false,
        SideBar: false,
      }}
      filtrosBusqueda={{
        movimiento: true,
        evaluacion: true,
        estatus: true,
        fechaTitulo: true,
        tipoEstatus: tipoEstatus.RegistroCheck,
        tipoEvaluacion: tipoEvaluaciones.RegistroCheck
      }}
      cabeceros={cabeceros || []}
      apiKey={REGISTROS}
      params={{ Estatus: 'RegistroCheck', Evaluacion: tipoEvaluaciones.RegistroCheck }}
    />
  );
};

export default EmpleadoCheck;
