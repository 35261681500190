import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';



const styles = makeStyles(() => ({
  permisosContainer: {
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: -5,
  },
  datosGrid: {
    margin: 20,
  },
  perfilGrid: {
    margin: 20,
    marginTop: '60px',
  },
  heading: {
    fontWeight: 'bold',
    marginLeft:'15px',
    marginTop:'-40px',
  },
  perfilItem: {
    margin: '20px 15px 20px 25px',
  },
  perfilDescripcion: {
    marginLeft: '30px',
    wordWrap: 'break-word',
    maxWidth: '350px',
  },
  ColorBack: {
    backgroundColor: paleta.sidebar.active,
    maxHeight: '300px',
    minHeight: '250px',
  }
  
}));

export default styles;
