import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  container: {
    borderRadius: '10px',
    marginBottom: '20px',
    padding: '20px',
    background: paleta.sidebar.active,
    display: 'flex',
    justifyContent: 'space-around'
  },
  fecha: {
    width: '90%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttons: {
    width: '100px'
  },
}));

export default styles;
