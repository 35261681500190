/* eslint no-restricted-syntax: 0 */
/* eslint guard-for-in: 0 */
/* eslint no-param-reassign: 0 */
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import Regex, { regexMessages } from './regex';

import 'moment/locale/es';
import axios from '../configuraciones/axios';

moment.locale('es');

export const capitalizar = (s) => s.split(' ').map(
  palabra => palabra[0].toUpperCase() + palabra.slice(1)
).join(' ');

export const removeByVal = (obj, val) => {
  for (const key in obj) {
    if ((obj[key] && (obj[key] === val || obj[key].toString() === val.toString()))) delete obj[key];
  }
  return obj;
};

export const findPropertysEmpty = (obj, showMsg = false) => {
  const errors = {};
  const regex = {};
  const elements = obj?.querySelectorAll('input, textarea, select') || [];
  let totalErrors = 0;
  let totalRegex = 0;
  Array.prototype.slice.call(elements).forEach((element) => {
    const regexElement = element.getAttribute('regex');
    const regexOnSubmit = element.getAttribute('regexonsubmit');
    const index = element.getAttribute('index');
    if (regex && regexOnSubmit && element.value.trim() !== '') {
      const validation = Regex[regexElement];
      const regexValid = validation(element.value);
      if (!regexValid) {
        if (index) {
          regex[`${element.name}${index}`] = regexMessages[regexElement] || true;
        } else {
          regex[element.name] = regexMessages[regexElement] || true;
        }
        totalRegex++;
      }
    }
    if (!element.required) return;
    const type = typeof (element.value);
    const errorName = element.getAttribute('required');
    if (type === 'string') {
      if (element.value.trim() === '' || element.value.trim() === '-1') {
        totalErrors++;
        errors[errorName || element.name] = true;
      }
    } else if (type === 'number') {
      if (element.value === -1) {
        totalErrors++;
        errors[errorName || element.name] = true;
      }
    }
  });

  for (const key in errors) {
    if (key.includes('.')) {
      const arr = key.split('.');
      if (!errors[arr[0]]) errors[arr[0]] = {};
      errors[arr[0]][arr[1]] = errors[key];
      delete errors[key];
    }
  }

  if (showMsg && (totalErrors > 0 || totalRegex > 0)) {
    toast.error('Formulario no completado correctamente');
  }

  return {
    errors, totalErrors, regex, totalRegex,
  };
};

  //Función para el mensaje helperText de campos requeridos/obligatorios
  export const requiredFIle = (isSubmit, formErrors, field) => {
    if (isSubmit && formErrors[field]) {
      return 'Campo requerido';
    } else {
      return '';
    }
  }

export const objToFormData = (obj) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    let valor = '';
    if (Array.isArray(obj[key]) || typeof obj[key] === 'object') valor = JSON.stringify(obj[key]);
    else valor = obj[key];
    formData.append(key, valor);
  });
  return formData;
};

export const getDifferences = (original, update) => {
  const differences = {};
  Object.keys(update)
    .forEach((key) => {
      if (original[key] !== update[key]) {
        differences[key] = original[key];
      }
    });
  return differences;
}

export const fileToBase64 = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(file);
})

export const getMimeType = (name) => {
  const split = name?.split('.');
  return split[split.length - 1]
};

export const trim = (obj) => {
  const objTrim = {};

  // Eliminamos espacios en blanco de la propiedades
  for (const [key, value] of Object.entries(obj)) {
    objTrim[key] = typeof value === 'string' && key !== 'password' ? value.trim() : value
  };

  return {
    ...objTrim
  };
}

export const getISOWeekDates = (isoWeekNum = 1, format = '') => {
  let d = moment()
    .isoWeek(1)
    .startOf('isoWeek')
    .add(isoWeekNum - 1, 'weeks');
  for (var dates = [], i = 0; i < 7; i++) {
    const p = d.clone().format(format);
    dates.push(p);
    d.add(1, 'day');
  }
  return dates;
};
export const getTheDaysOfTheWeek = (isoWeekNum = 1, format = '') => {
  let d = moment()
    .isoWeek(1)
    .startOf('isoWeek')
    .add(isoWeekNum - 1, 'weeks');
  for (var dates = [], i = 0; i < 7; i++) {
    const nombre = d.clone().format(format);
    const dia = d.clone().format('YYYY-MM-DD')
    dates.push({ id: i, nombre, dia: dia });
    d.add(1, 'day');
  }
  return dates;
};

export const getWeeks = () => {
  const semanas = [];
  for (let i = 1; i <= moment().weeksInYear(); i++) {
    semanas.push({
      id: i,
      semanaID: moment().subtract(i, 'weeks').isoWeek(),
      nombre: `${i} - ${moment().isoWeek(i).startOf("isoweek").format('DD-MMM-YYYY')}
       /  ${moment().isoWeek(i).endOf("isoweek").format('DD-MMM-YYYY')}`,
    });
  }

  return semanas;
};

export const getWeeksFormatted = () => {
  const semanas = [];
  for (let i = 0; i < moment().weeksInYear(); i++) {
    semanas.push({
      id: i,
      semanaID: moment().subtract(i, 'weeks').isoWeek(),
      nombre: `${moment().subtract(i, 'weeks').isoWeek()} (${moment()
        .subtract(i, 'weeks')
        .startOf('isoWeek')
        .format('DD-MMM-YYYY')} / ${moment()
          .subtract(i, 'weeks')
          .endOf('isoWeek')
          .format('DD-MMM-YYYY')})`,
    });
  }

  return semanas;
};

export const sumarArr = (arr, key) => {
  const valores = arr.map(e => e[key]);
  return valores.reduce((a, b) => a + b, 0);
}

export const parseFloatProperties = (obj) => {
  const arr = Object.entries(obj);
  const parseValues = arr.map(e => {
    const value = parseFloat(e[1]);
    if (!Number.isNaN(value)) {
      return [e[0], parseFloat(e[1])];
    }
    return e;
  })
  return Object.fromEntries(parseValues);
}

export const ValidarRangos = ({
  rangoEstadoRojoMin,
  rangoEstadoRojoMax,
  rangoEstadoAmarilloMin,
  rangoEstadoAmarilloMax,
  rangoEstadoVerdeMin,
  rangoEstadoVerdeMax,
}, id) => {
  if (rangoEstadoRojoMin && rangoEstadoRojoMax) {
    if (rangoEstadoRojoMin < rangoEstadoRojoMax) {
      if (rangoEstadoRojoMax < rangoEstadoAmarilloMin) {
        if (rangoEstadoAmarilloMin < rangoEstadoAmarilloMax) {
          if (rangoEstadoAmarilloMax < rangoEstadoVerdeMin) {
            if (rangoEstadoVerdeMin < rangoEstadoVerdeMax && rangoEstadoVerdeMax <= 100) {
              return;
            }
          }
        }
      }
    }
  }
  return id;
}

export const capitalizarPalabras = (string) => {
  return string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

/**
 * Función para descargar un documento
 * @param {{
 *    response: ArrayBuffer,
 *    tipoArchivo: 'excel' | 'pdf'
 *  }} documento
 */
export const DescargarDocumento = ({ response, tipoArchivo }) => {
  let outputFilename;
  if (tipoArchivo === 'excel') {
    outputFilename = `excel_${moment(moment.now()).format(
      'DD-MM-YYYY:HH:mm'
    )}.xlsx`;
  }

  if (tipoArchivo === 'pdf') {
    outputFilename = `reporte_${moment(moment.now()).format(
      'DD-MM-YYYY:HH:mm'
    )}.pdf`;
  }
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', outputFilename);
  document.body.appendChild(link);
  link.click();
};


export const exportarDocumento = async ({ params, endpoint, tipoArchivo, method }) => {
  try {
    let response;
    if (method === 'POST') {
      response = await axios({
        url: endpoint,
        method,
        responseType: 'arraybuffer',
        data: {
          ...params,
          exportar: true,
          tipoExportacion: tipoArchivo
        }
      });
    } else {
      response = await axios.get(endpoint, {
        responseType: 'arraybuffer',
        params: {
          ...params,
          exportar: true,
          tipoExportacion: tipoArchivo,
        },
        headers: { 'Content-Type': 'blob' },
      });
    }
    if (response.byteLength > 0) {
      DescargarDocumento({ response, tipoArchivo });
    } else {
      toast.error('Sin registros para exportar');
    }
  } catch (error) {
    return error;
  }
};

export const customCabecero = (selects) => {
  const cabecero = {
    sitio: { width: 4, show: false },
    invernadero: { width: 4, show: false },
    nave: { width: 4, show: false },
    fenologia: { width: 4, show: false },
    tabla: { width: 4, show: false },
    planta: { width: 4, show: false },
    parametro: { width: 4, show: false },
    invernaderoNave: { width: 4, show: false },
    enfermedad: { width: 4, show: false },
    trampa: { width: 4, show: false },
    plaga: { width: 4, show: false },
    surco: { width: 4, show: false },
    saneo: { width: 4, show: false },
    seccion: { width: 4, show: false },
    nivel: { width: 4, show: false },
    nivelInfeccion: { width: 4, show: false },
    semana: { width: 4, show: false },
    dia: { width: 4, show: false },
  };

  return {
    ...cabecero,
    ...selects,
  };
};

export const obtenerCentroide = (pts) => {
  if (!pts[0]?.x || !pts[0]?.y) return null;
  var first = pts[0], last = pts[pts.length - 1];
  if (first.x !== last.x || first.y !== last.y) pts.push(first);
  var twicearea = 0,
    x = 0, y = 0,
    nPts = pts.length,
    p1, p2, f;
  for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i]; p2 = pts[j];
    f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
    twicearea += f;
    x += (p1.x + p2.x - 2 * first.x) * f;
    y += (p1.y + p2.y - 2 * first.y) * f;
  }
  f = twicearea * 3;
  return { x: x / f + first.x, y: y / f + first.y };
};

export const obtenerRangoFechas = (startDate, endDate, type, format) => {
  try {
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    const diff = toDate.diff(fromDate, type);
    const range = [];
    for (let i = 0; i < diff; i++) {
      range.push(moment(startDate).add(i, type).format(format));
    }
    return range;
  } catch {
    return [];
  }
};

export const max = (data) => {
  if (data && data.length > 0) {
    return Math.max(...data);
  }
  return 0;
};

export const min = (data) => {
  if (data && data.length > 0) {
    return Math.min(...data);
  }
  return 0;
};

export const urltoFile = (url, filename, mimeType) => {
  if (url.startsWith('data:')) {
    var arr = url.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  return fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], filename, { type: mimeType }));
}
