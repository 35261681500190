import { useMemo, memo } from 'react';

import propTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';

import styles from './styles';

const PlanesComparacion = ({ planes, caracteristicas }) => {
  const classes = styles();

  const headers = useMemo(() => {
    const headers = ['Funcionalidades', ...planes.map(({ Nombre }) => Nombre)];
    return headers.map(func => ({
      key: func,
      label: func,
    }));
  }, [planes]);

  const rows = useMemo(() =>
    caracteristicas.map(({ _id: caracteristicaId, Nombre: caracteristica }) => {
      const row = { Funcionalidades: caracteristica };
      planes.forEach(({ Nombre: plan, Caracteristicas }) => {
        const planCaracteristica = Caracteristicas?.find(({ Caracteristica }) => Caracteristica === caracteristicaId);
        if (planCaracteristica && !planCaracteristica.NoEsVisible) {
          row[plan] = planCaracteristica.Descripcion || <FaCheck color='#38A5F4' />;
        }
      })
      return row;
  }), [planes, caracteristicas]);

  return (
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow className={classes.row}>
          {headers?.map((header, index) => <TableCell key={index} style={{ backgroundColor: 'white', fontWeight: 'bold', border: 0 }}>
            {header.label}
          </TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row, index) => <TableRow className={classes.row} key={index}>
          {headers.map((header, index) => <TableCell style={{ border: 0 }} key={index}>
            {row[header.key]}
          </TableCell>)}
        </TableRow>)}
      </TableBody>
    </Table>
  );
}

PlanesComparacion.propTypes = {
  /** Lista de planes */
  planes: propTypes.array,
  /** Lista de características */
  caracteristicas: propTypes.array,
}

PlanesComparacion.defaultProps = {
  plans: [],
  caracteristicas: [],
}

const PlanComparisonMemo = memo(PlanesComparacion);

export default PlanComparisonMemo;
