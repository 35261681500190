/* eslint-disable import/no-anonymous-default-export */

const SET_PAGINA = 'SET_PAGINA';
const SET_REGISTROS = 'SET_REGISTROS';
const CLEAR_REGISTROS = 'CLEAR_REGISTROS';
const SET_FILTROS_BUSQUEDA = 'SET_FILTROS_BUSQUEDA';


const initialState = {
  pagina: 1,
  registros: { rows: [], count: 0 },
  sitios: [],
  invernaderos: [],
  naves: [],
  filtrosBusqueda: {
    movimientos: [{ label: 'Entrada', value: 'Entrada' }, { label: 'Salida', value: 'Salida' }],
    habilitado: [{ value: true, label: 'Habilitado' }, { value: false, label: 'Inhabilitado' }],
    evaluaciones: [],
    estatus: [],
    motivos: [{ label: 'Feriado', value: 'Feriado'}, { label: 'Permiso', value: 'Permiso'}, { label: 'Vacaciones' , value: 'Vacaciones'}],
  },
};

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_PAGINA:
      return { ...state, pagina: payload };
    case SET_REGISTROS:
      return { ...state, registros: payload };
    case CLEAR_REGISTROS:
      return { ...state, registros: initialState.registros, pagina: initialState.pagina };
    case SET_FILTROS_BUSQUEDA:
      return { ...state, filtrosBusqueda: { ...state.filtrosBusqueda, ...payload } };

    default:
      return state;
  }
};

export const setPagina = (payload) => ({ type: SET_PAGINA, payload });
export const setRegistros = (payload) => ({ type: SET_REGISTROS, payload });
export const setFiltrosBusqueda = (payload) => ({ type: SET_FILTROS_BUSQUEDA, payload });
export const setLimpiar = () => ({ type: CLEAR_REGISTROS });

