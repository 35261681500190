import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  permisosContainer: {
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: -5
  },
  tablaPermisosSitios: {
    marginTop: 10,
  },
  heading: {
    fontWeight: 'bold',
  },
  formEmpresa: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingBottom: 30,
  },
  formData: {
    marginTop: 8,
    marginLeft: 0,
    backgroundColor: paleta.sidebar.active,
    paddingLeft: 15,
    paddingBottom: 30,
  },
  container: {
    paddingRight: 40
  },
  input: {
    display: 'none',
  },
  image: {
    maxWidth: '50%',
  },
}));

export default styles;
