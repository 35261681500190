import { makeStyles } from '@material-ui/core';


const styles = makeStyles((theme) => ({
  avatar: {
    width: '90px !important',
    height: '90px !important',
    left: '20px !important',
  },
  avatares: {
    width: '70px !important',
    height: '70px !important',
    margin: '4px',

  },
  tableroAvatares:{
    justifyContent: 'center',
    padding: '10px !important',
  },
  avatarSeleccion:{
    width: '70px !important',
    height: '70px !important',
    margin: '1px',
    border: 'solid',
    padding: 1,
    borderColor: '#bdbdbd',
  },
  boton: {
    top: 65,
    left: -10,
    fontSize: 20,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    border: 'inset',
    padding: '4px !important',
    '&:hover': {
      backgroundColor: '#bdbdbd',
    },
  },
  botonDentro:{
    borderWidth: '2px',
    borderColor: '#bdbdbd',
    borderRadius: '20px ',
    border: 'solid',
  },
  imagenComputadora: {
    width: '115px !important',
    height: '115px !important',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  textoCentrado: {
    textAlign: 'center',
    padding: 1,
  },
  arrastreCentrado: {
    textAlign: 'center',
    padding: 1,
    paddingTop: '40px'
  },
  oCentrado: {
    textAlign: 'center',
    padding: 1,
    paddingBottom: '40px'
  },
  iconos:{
    height: '25px',
    width: '25px'
  },
  guardar:{
    padding: '25px 0px 0px 75px',
  },
  botonCentrado:{
    justifyContent: 'center'
  },
  spinner: {
    color: '#408080',
    size: 100
  },
  spinnerBox:{
    display: 'grid !important',
    placeItems: 'center !important'
  }
}));

export default styles;