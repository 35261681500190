import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid,
} from '@material-ui/core';

import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';

import axios from '../../configuraciones/axios';
import endpoints, { PUESTOS, EMPRESAS } from '../../configuraciones/endpoints';

import { findPropertysEmpty, trim, requiredFIle } from '../../utilidades/functions';

import estatus from '../../constantes/estatus';

import styles from './styles';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';
import { toast } from 'react-toastify';
import { LETTERSSPECIALCHARACTER } from '../../utilidades/regex';

const Puesto = ({ history, match }) => {
  const classes = styles();

  const [formErrors, setFormErrors] = useState({});
  const [puesto, setPuesto] = useState({});
  const [empresa, setEmpresa] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const { params: { _id } } = match;

  //Método para regresar a la pantalla principal de puestos (tabla)
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/puestos',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  //Método para cancelar la operacion de agregar/editar un regristro
  const cancelar = useCallback(regresar, [regresar]);

  //Método para cargar los datos de un registro
  const consultaDatosIniciales = () => new Promise((resolve, reject) => {
    //Se cargan los datos de empresas para el formulario (select)
    axios.get(endpoints.base.url(EMPRESAS, '/obtener-Activos'))
    .then(({ empresa }) => {
      setEmpresa(empresa);
    });

    //Si el _id no existe, quiere decir que es un registro nuevo, retornamos
    if (!_id) return

    //Petición para cargar los datos del registro puestos
    axios.get(endpoints.base.url(PUESTOS, _id))
      .then(({ puesto }) => {

        //Si puesto no existe, mandamos un warning del elemento no encontrado
        if (!puesto) {
          toast.warning(ELEMENTO_NO_ENCONTRADO);
          regresar();
          return;
        }
        //cargar los datos al formulario
        const { Empresa } = puesto;
        puesto.Empresa = (Empresa.Habilitado && Empresa.Activo) ? Empresa._id : null;
        setPuesto(puesto);
        resolve();
      })
        .catch(reject);
  });

  //Método para enviar datos al back (nuevo registro y editar)
  const guardar =  async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    //Control de errores del formulario
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmPuesto'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    //forma en la que va a actuar la petición HTTP de axios
    const metodo = _id ? 'put' : 'post';
    const obj = trim(puesto);
    try {
      //Se envían los datos al back con los datos del formulario
      await axios[metodo](PUESTOS, obj).then(regresar);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    consultaDatosIniciales();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  return <Default
    titulo={Boolean(_id) ? 'Editar puesto' : 'Nuevo puesto'}
    placeHolder={''}
    mostrarCabeceroFormulario={false}
    guardar={guardar}
    cancelar={cancelar}>
    <Grid className={classes.container} container spacing={4} id="frmPuesto">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid className={classes.formEmpresa} container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setPuesto}
              name="Nombre"
              value={puesto.Nombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              helperText={requiredFIle(isSubmit, formErrors, 'Nombre')}
              error={formErrors.Nombre}
              required
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              label="Descripción"
              type="textarea"
              rows={6}
              onChange={setPuesto}
              name="Descripcion"
              value={puesto.Descripcion ?? ''}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 200, regexonchange: 'true' }}
              error={formErrors.Descripcion}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              placeHolder="Seleccione"
              name="Habilitado"
              onChange={setPuesto}
              value={puesto.Habilitado}
              options={estatus}
              isHandleChange
              error={formErrors.Habilitado}
              helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid className={classes.formEmpresa} container spacing={4}>
          <Grid item xs={12}>
            <Select
              label="Empresa"
              labelProp="Nombre"
              valueProp="_id"
              options={empresa}
              name="Empresa"
              onChange={(setPuesto)}
              value={empresa.find(e => e._id === puesto.Empresa) ? puesto.Empresa : ''}
              isHandleChange
              placeHolder="Seleccione"
              error={formErrors.Empresa}
              helperText={requiredFIle(isSubmit, formErrors, 'Empresa')}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Puesto);
