import React from 'react';

import moment from 'moment-timezone';

import Estatus from '../../componentes/Estatus';
import ReportePersonal from '../../contenedores/ReportePersonal';
import { capitalizar } from '../../utilidades/functions';
import { REPORTE_ASISTENCIA_INDIVIDUAL } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Empleado', key: 'NombreCompleto' },
  { label: 'Empresa', transform: ({ Empresa }) => Empresa?.Nombre },
  { label: 'Sucursal', transform: ({ Sucursal }) => Sucursal?.Nombre },
  { label: 'Departamento', transform: ({ Departamento }) => Departamento?.Nombre },
  { label: 'Puesto', transform: ({ Puesto }) => Puesto?.Nombre },
];

const cabecerosDrawer = [
  { label: 'Fecha', transform: ({ FechaAsistencia }) => FechaAsistencia && capitalizar(moment(FechaAsistencia).format('DD MMMM YYYY')) },
  { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion ? Evaluacion.Nombre : null },
  { label: 'Estatus', transform: ({ Estatus: estatusRegistro }) => estatusRegistro ? <Estatus Cadena={estatusRegistro.Nombre} Habilitado={false} /> : null },
]

const AsistenciaIndividual = ({ history, location }) => {

  return (
    <ReportePersonal
      history={history}
      location={location}
      cabeceros={cabeceros}
      titulo="Reporte de asistencia individual"
      apiKey={REPORTE_ASISTENCIA_INDIVIDUAL}
      cabecerosDrawer={cabecerosDrawer}
      apiKeyDrawer={REPORTE_ASISTENCIA_INDIVIDUAL}
      tituloReporte="Reporte de asistencia individual"
    />
  );
};

export default AsistenciaIndividual;
