import React, { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';

import { Grid, Link } from '@material-ui/core';

import styles from './styles';

import axios from '../../../configuraciones/axios';
import estatus from '../../../constantes/estatus';
import Default from '../../../contenedores/Default';
import Select from '../../../componentes/Select';
import TextField from '../../../componentes/TextField';
import Typography from '../../../componentes/Typography';
import BotonAvatar from '../../../componentes/BotonAvatar';
import Confirmacion from '../../../componentes/Confirmacion';
import endpoints, { EMPLEADOS, EMPRESAS, SUCURSALES, DEPARTAMENTOS, PUESTOS, HORARIOS, ASISTENCIA } from '../../../configuraciones/endpoints';
import { findPropertysEmpty, objToFormData, requiredFIle } from '../../../utilidades/functions';
import { EMAIL_REGEX, LETTERSSPECIALCHARACTER, NUMBER_REGEX, ONLY_NUMBERS } from '../../../utilidades/regex';
import { ERROR_CAMBIO_HORARIO, HORARIO_ELIMINADO_EMPLEADO } from '../../../configuraciones/mensajes';

const Empleado = ({ history, match }) => {
  const classes = styles();

  const [horarioEliminado, setHorarioEliminado] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [image, setImage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [empleado, setEmpleado] = useState({});
  const [opciones, setOpciones] = useState({
    empresas: [],
    sucursales: [],
    departamentos: [],
    puestos: [],
    horarios: [],
  });
  const [opcionesFiltro, setOpcionesFiltro] = useState({
    sucursales: [],
    departamentos: [],
    puestos: [],
  })
  const { params: { _id } } = match;

  const horarioAnterior = useRef(null);

  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);

  const cancelDelete = () => {
    setAnchorConfirmacion(null);
  };

  const acceptDelete = () => {
    eliminar();
    cancelDelete();
  };

  const customDelete = (event) => {
    setAnchorConfirmacion(event.currentTarget);
  };

  const eliminar = async () => {
    try {
      await axios.delete(endpoints.base.url(EMPLEADOS, _id)).then(regresar);
    } catch (error) {
      console.error(error);
    }
  };

  // esta funcion cumple como regreso a la pagina anterior [Pathname]
  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = (_id) ? history.history : history;
    if (_id) {
      history.history.push({
        pathname: '/check/empleados',
        search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
      });
    } else {
      history.push({
        pathname: '/check/empleados',
        search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
      });
    }
  }, [_id, history]);

  // funcion que sirve para guardar y hacer la creacion o edicion de los empleados en la base de datos
  const guardar = useCallback(async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    //Control de errores del formulario
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmEmpleado'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    // Obtener asistencia del empleado del día actual
    if (_id) {
      const { asistencia: ultimaAsist } = await axios.get(endpoints.base.url(ASISTENCIA, `empleado/${_id}/hoy`));

      if (!ultimaAsist && horarioAnterior.current !== empleado.HorarioEmpleado && !horarioEliminado) {
        return toast.info(ERROR_CAMBIO_HORARIO);
      }
    }

    const formData = objToFormData(empleado);
    formData.append('Imagen', image);
    const metodo = _id ? 'put' : 'post';
    await axios[metodo](endpoints.base.url(EMPLEADOS), formData)
      .then(regresar);
  }, [_id, empleado, horarioEliminado, image, regresar, horarioAnterior]);

  //Carga los valores iniciales desde base de datos para las opciones
  const consultarDatosIniciales = useCallback(async () => {
    const promesas = [
      axios.get(endpoints.base.url(EMPRESAS, 'obtener-Activos')),
      axios.get(endpoints.base.url(SUCURSALES, 'obtener-Activos')),
      axios.get(endpoints.base.url(DEPARTAMENTOS, 'obtener-Activos')),
      axios.get(endpoints.base.url(PUESTOS, 'obtener-Activos')),
      axios.get(endpoints.base.url(HORARIOS, 'obtener-Activos')),
      _id && axios.get(endpoints.base.url(EMPLEADOS, _id)),
      _id && axios.get(endpoints.base.url(EMPLEADOS, `${_id}/horario`))
    ];
    const [empresas, sucursales, departamentos, puestos, horarios, respEmpleado, horarioActivo] = await Promise.all(promesas);
    let registroEmpleado;
    if (respEmpleado) {
      registroEmpleado = respEmpleado.empleado;
    }
    //Opciones select
    const opcionesData = {
      empresas: empresas.empresa.map(({ Nombre, _id }) => ({ label: Nombre, EmpresaID: _id, })),
      sucursales: sucursales.sucursal.map(({ Nombre, _id, Empresa }) => ({ label: Nombre, SucursalID: _id, Empresa })),
      departamentos: departamentos.departamentos.map(({ Nombre, _id, Empresa }) => ({ label: Nombre, DepartamentoID: _id, Empresa })),
      puestos: puestos.puesto.map(({ Nombre, _id, Empresa }) => ({ label: Nombre, PuestoID: _id, Empresa })),
      horarios: horarios.horarios.map(({ Nombre, _id }) => ({ label: Nombre, HorarioID: _id })),
    };
    setOpciones(opcionesData)
    if (_id) {
      registroEmpleado.Sucursal = registroEmpleado.Sucursal.Habilitado ? registroEmpleado.Sucursal.id : null;

      const Horarios = opcionesData.horarios;
      registroEmpleado.FechaNacimiento = new Date(registroEmpleado.FechaNacimiento).toISOString().split('T')[0];
      delete registroEmpleado.NombreCompleto;
      registroEmpleado.HorarioEmpleado = registroEmpleado.HorarioEmpleado.Horario;

      // Encontrar si el horario que tiene el empleado no ha sido eliminado
      const horarioEliminado = !Horarios.some(({ HorarioID }) => HorarioID === registroEmpleado.HorarioEmpleado)
      if (horarioEliminado) {
        setHorarioEliminado(true);
        toast.info(HORARIO_ELIMINADO_EMPLEADO);
      }
      horarioAnterior.current = registroEmpleado.HorarioEmpleado;
      if (!horarioActivo.Activo || !horarioActivo.Habilitado) {
        setHorarioEliminado({ change: true });
      }
      setEmpleado(registroEmpleado);
    }
  }, [_id])

  /** Consultar los datos necesarios para operar desde el back */
  useEffect(() => {
    consultarDatosIniciales();
  }, [_id, consultarDatosIniciales]);

  /** Manejar el cambio de imagen del componente BotonAvatar */
  const handleUpload = (avatar) => {
    if (avatar) {
      if (typeof avatar === 'string' && avatar.startsWith('https')) {
        setEmpleado({ ...empleado, Foto: { secure_url: avatar } });
        setImage()
      } else if (avatar instanceof Object) {
        setImage(avatar)
        setEmpleado({ ...empleado });
      }
    }
  }

  /** Actualizar las opciones mostradas dependiendo de la empresa seleccionada */
  useEffect(() => {
    if (empleado.Empresa) {
      const filtroDepartamento = opciones.departamentos.filter(({ Empresa }) => Empresa === empleado.Empresa);
      const filtroPuesto = opciones.puestos.filter(({ Empresa }) => Empresa === empleado.Empresa);
      const filtroSucursal = opciones.sucursales.filter(({ Empresa }) => Empresa === empleado.Empresa)
      setOpcionesFiltro({ departamentos: filtroDepartamento, puestos: filtroPuesto, sucursales: filtroSucursal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empleado.Empresa])

  return <Default
    placeHolder={''}
    guardar={guardar}
    titulo={Boolean(_id) ? "" : 'Nuevo empleado'} //INTENTO
    mostrarCabeceroFormulario={false}
    customStyleSave={_id ? true : false}
    cancelar={_id ? null : regresar}
    mostrar={{ SideBar: false }}
    hiddenSideBar={true}>
    <Grid container spacing={6} id="frmEmpleado"  >
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container className={classes.principales} spacing={2}>
          <Grid item xs={12}>
            <BotonAvatar
              url={empleado?.Foto?.secure_url || ''}
              onChange={handleUpload}
            />
          </Grid>
          <Grid item xs={12} className={classes.empleado}>
            <Confirmacion
              anchor={anchorConfirmacion}
              onClose={cancelDelete}
              onAccept={acceptDelete}
              position={'translateX(120px) translateY(-45px)'}
            />
          </Grid>
          <Grid item xs={12} className={classes.empleado}>
            <TextField
              label="Nombre"
              onChange={setEmpleado}
              name="Nombres"
              value={empleado.Nombres}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 25, regexonchange: 'true' }}
              error={formErrors.Nombres}
              required
              helperText={requiredFIle(isSubmit, formErrors, 'Nombres')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Apellidos"
              onChange={setEmpleado}
              name="Apellidos"
              value={empleado.Apellidos}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 25, regexonchange: 'true' }}
              error={formErrors.Apellidos}
              required
              helperText={requiredFIle(isSubmit, formErrors, 'Apellidos')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Fecha Nacimiento"
              onChange={setEmpleado}
              name="FechaNacimiento"
              value={empleado.FechaNacimiento}
              isHandleChange
              inputProps={{ maxLength: 50, regexonchange: 'true' }}
              error={formErrors.FechaNacimiento}
              required
              type="date"
              helperText={requiredFIle(isSubmit, formErrors, 'FechaNacimiento')}

            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              options={estatus}
              onChange={setEmpleado}
              name="Habilitado"
              value={empleado.Habilitado}
              error={formErrors.Habilitado}
              helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
              placeHolder={'Seleccione'}
              isHandleChange
              required
            />
          </Grid>
          {_id && (
            <Grid item xs={12}>
              <Link className={classes.eliminar} component="button" variant="body2" onClick={customDelete}>
                Eliminar Empleado
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid item>
          <Typography variant='subtitle1' className={classes.smallText}> Datos de contacto </Typography>
        </Grid>
        <Grid container spacing={1} className={classes.contenedorContacto}>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo Electrónico"
              onChange={setEmpleado}
              name="Correo"
              value={empleado.Correo}
              isHandleChange
              inputProps={{ maxLength: 50, regexonsubmit: 'true', regex: EMAIL_REGEX }}
              error={formErrors.Correo}
              required
              helperText={empleado.Correo ?
                formErrors.Correo :
                requiredFIle(isSubmit, formErrors, 'Correo')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Teléfono"
              onChange={setEmpleado}
              name="Telefono"
              value={empleado.Telefono}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true' }}
              error={formErrors.Telefono}
              helperText={formErrors.Telefono}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Whatsapp"
              required
              onChange={setEmpleado}
              name="Whatsapp"
              value={empleado.Whatsapp}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true' }}
              error={formErrors.Whatsapp}
              helperText={Boolean(empleado.Whatsapp) ?
                formErrors.Whatsapp :
                requiredFIle(isSubmit, formErrors, 'Whatsapp')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid item>
          <Typography variant='subtitle1' className={classes.smallText}> Datos de la organización </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.contenedorOrganizacion}>
          <Grid item xs={12}>
            <Select
              label="Empresa"
              required
              options={opciones.empresas}
              onChange={setEmpleado}
              name="Empresa"
              value={empleado.Empresa || ''}
              valueProp={'EmpresaID'}
              error={formErrors.Empresa}
              helperText={requiredFIle(isSubmit, formErrors, 'Empresa')}
              placeHolder={'Seleccione'}
              isHandleChange
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Sucursal"
              required
              options={opcionesFiltro.sucursales}
              onChange={setEmpleado}
              name="Sucursal"
              value={empleado.Sucursal || ''}
              valueProp={'SucursalID'}
              error={formErrors.Sucursal}
              helperText={requiredFIle(isSubmit, formErrors, 'Sucursal')}
              placeHolder={'Seleccione'}
              isHandleChange
              disabled={!Boolean(empleado.Empresa)}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Departamento"
              required
              options={opcionesFiltro.departamentos}
              onChange={setEmpleado}
              name="Departamento"
              value={empleado.Departamento || ''}
              valueProp={'DepartamentoID'}
              error={formErrors.Departamento}
              helperText={requiredFIle(isSubmit, formErrors, 'Departamento')}
              placeHolder={'Seleccione'}
              isHandleChange
              disabled={!Boolean(empleado.Empresa)}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Puesto"
              required
              options={opcionesFiltro.puestos}
              onChange={setEmpleado}
              name="Puesto"
              value={empleado.Puesto || ''}
              valueProp={'PuestoID'}
              error={formErrors.Puesto}
              helperText={requiredFIle(isSubmit, formErrors, 'Puesto')}
              placeHolder={'Seleccione'}
              isHandleChange
              disabled={!Boolean(empleado.Empresa)}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Horario"
              required
              options={opciones.horarios}
              onChange={setEmpleado}
              name="HorarioEmpleado"
              value={empleado.HorarioEmpleado}
              valueProp={'HorarioID'}
              error={formErrors.HorarioEmpleado}
              helperText={requiredFIle(isSubmit, formErrors, 'HorarioEmpleado')}
              placeHolder={'Seleccione'}
              isHandleChange
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Número empleado"
              onChange={setEmpleado}
              name="NumeroEmpleado"
              value={empleado.NumeroEmpleado}
              isHandleChange
              inputProps={{ regex: ONLY_NUMBERS, maxLength: 10, regexonsubmit: 'true' }}
              error={formErrors.NumeroEmpleado}
              helperText={Boolean(empleado.NumeroEmpleado) && formErrors.NumeroEmpleado}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Empleado);
