import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { USUARIOS } from '../../configuraciones/endpoints';


const cabeceros = [
  { label: 'CORREO / USUARIO', key: 'Usuario' },
  { label: 'NOMBRE', key: 'Nombre' },
  { label: 'PERFIL',transform: ({ Perfil }) => Perfil ? Perfil.Nombre : null},  
  { label: 'ESTATUS', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
];

const Usuarios = ({ history, location }) => {

  return <Listados
    titulo="Usuarios"
    placeHolder="Búsqueda por nombre y usuario"
    history={history}
    location={location}
    filtrosBusqueda={{
      btnAgragar: true,
    }}
    showActions
    cabeceros={cabeceros}
    apiKey={USUARIOS}
  />;
};

export default Usuarios;