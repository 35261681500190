import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
  contenedorContrasena: {
    backgroundColor: '#ecf5f9',
    borderRadius: '8px',
  },
  btnAplicar: {
    backgroundColor: 'white',
  },
  txtDesactivado: {
    backgroundColor: '#b4d7d2 !important',
  }
}));

export default styles;
