import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { DEPARTAMENTOS } from '../../configuraciones/endpoints';


const cabeceros = [
  { label: 'NOMBRE', key: 'Nombre' },
  { label: 'EMPRESA', transform: ({ Empresa }) => Empresa ? Empresa.Nombre : null},  
  { label: 'DESCRIPCIÓN', key: 'Descripcion' },
  { label: 'ESTATUS', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
];

const Departamentos = ({ history, location }) => {

  return <Listados
    titulo="Departamentos"
    placeHolder="Búsqueda por nombre y empresa"
    history={history}
    location={location}
    showActions
    filtrosBusqueda={{
      btnAgragar: true,
    }}
    cabeceros={cabeceros}
    apiKey={DEPARTAMENTOS}
  />;
};

export default Departamentos;
