import { useState, useEffect } from 'react';

import io from 'socket.io-client';

import general from '../configuraciones/general';

let socket;

const useSocket = (clienteId) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (!socket && clienteId) {
      socket = io(general.URL_SOCKET, {
        query: {
          clienteId,
        },
      });
      socket.on('disconnect', () => {
        setConnected(false);
      });
      socket.on('connect', () => {
        setConnected(true);
      })
    }

    if (socket) {
      socket.connect();
      setConnected(true);
    }
  }, [clienteId]);

  useEffect(() => {
    if (connected) {
      socket.emit('joinRoom', `cliente-${clienteId}`);
    }
  }, [connected, clienteId]);

  useEffect(() => {
    return () => {
      socket.disconnect();
    }
  }, [])

  return { socket, connected };
};

export default useSocket;
