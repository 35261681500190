import { useState, useCallback, useEffect, useMemo } from 'react';

import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import { Box, Chip, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { quitarPermisos } from '../../ducks/appbar';


import Button from '../../componentes/Button';
import Default from '../../contenedores/Default';
import EtiquetaPrecio from '../../componentes/EtiquetaPrecio';
import Plan from '../../componentes/Plan';
import PlanesComparacion from '../../componentes/PlanesComparacion';
import Typography from '../../componentes/Typography';

import styles from './styles';
import axios from '../../configuraciones/axios';
import endpoints, { LANDING, PLANES } from '../../configuraciones/endpoints';

const Planes = ({ history, path }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [planes, setPlanes] = useState([]);
  const [planActual, setPlanActual] = useState({});
  const [caracteristicas, setCaracteristicas] = useState([]);
  const [fechaExpiro, setFechaExpiro] = useState(null);
  const [esPrueba, setEsPrueba] = useState(false);
  const [esPrecioAnual, setEsPrecioAnual] = useState(false);
  const [esPrecioAnualSeleccion, setEsPrecioEsAnualSeleccion] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [planCancelado, setPlanCancelado] = useState(false);

  const diasRestantes = useMemo(
    () => fechaExpiro ? fechaExpiro.diff(moment(), 'day') : '', [fechaExpiro]
  );

  const planesComponentes = useMemo(() => planes.map(({
    Nombre, PrecioAnual, PrecioMensual, Descripcion, Icono
  }) => {
    const unidad = esPrecioAnualSeleccion ? 'año' : 'mes';
    return {
      nombre: Nombre,
      descripcion: Descripcion,
      icono: <img src={`/${Icono}`} alt='' />,
      precio: <EtiquetaPrecio
        precio={esPrecioAnualSeleccion ? PrecioAnual : PrecioMensual}
        unidad={`al ${unidad}`}
      />,
      esPlanActual: Nombre === planActual?.Nombre && esPrecioAnual === esPrecioAnualSeleccion,
    }
  }), [planes, esPrecioAnual, planActual, esPrecioAnualSeleccion]);

  const mensajeChip = useMemo(() => {
    const evento = planCancelado ? 'cancelación' : 'renovación';

    if (esPrueba && diasRestantes !== '' && diasRestantes !== 0) {
      return { texto: `En prueba por ${diasRestantes} días más`, clase: classes.chip }
    }
    if (esPrueba && diasRestantes === 0) {
      return { texto: 'Último día de prueba', clase: classes.chip }
    }
    if (!planActual) {
      return { texto: 'Sin suscripción', clase: classes.chipSinSub }
    }
    if (diasRestantes === 0) {
      return { texto: `La ${evento} es hoy`, clase: classes.chip }
    }

    if (diasRestantes > 30) {
      const mesesRestantes = fechaExpiro.diff(moment(), 'month');
      const dias = moment().add(mesesRestantes, 'month');
      return { texto: `${mesesRestantes} meses y ${fechaExpiro.diff(dias, 'd')} días restantes para la ${evento}`, clase: classes.chip }
    }

    return { texto: `${diasRestantes} días restantes para la ${evento}`, clase: classes.chip }
  }, [fechaExpiro, diasRestantes, esPrueba, planActual, classes, planCancelado]);


  const handlePrecioAnualChange = useCallback(({ target: { checked } }) => setEsPrecioEsAnualSeleccion(checked), []);

  const obtenerPlanes = useCallback(async () => {
    const {
      planes: planesResponse,
      caracteristicas: caracteristicasResponse,
    } = await axios.get(endpoints.base.url(LANDING, 'planes'));
    setPlanes(planesResponse);
    setCaracteristicas(caracteristicasResponse);
    setCargando(false);
  }, []);

  const obtenerPlanActual = useCallback(async () => {
    const { Plan: planActualResponse, FechaExpiro, EsPrueba, EsPrecioAnual, PlanCancelado } = await axios.get(endpoints.base.url(PLANES, 'cliente'));
    setEsPrueba(EsPrueba);
    setFechaExpiro(moment.unix(FechaExpiro));
    setPlanActual(planActualResponse);
    setEsPrecioAnual(EsPrecioAnual);
    setEsPrecioEsAnualSeleccion(EsPrecioAnual);
    setPlanCancelado(PlanCancelado);
  }, []);

  const suscribirse = useCallback((Plan) => {
    return async () => {
      dispatch(quitarPermisos());
      const checkoutUrl = await axios.post(PLANES, {
        EsPrecioAnual: esPrecioAnualSeleccion,
        Plan,
      });
      window.location.href = checkoutUrl;
    }
  }, [esPrecioAnualSeleccion, dispatch]);

  useEffect(() => {
    obtenerPlanes();
    obtenerPlanActual();
  }, [obtenerPlanes, obtenerPlanActual, dispatch]);

  if (cargando) {
    return <Default />
  }

  return (
    <Default
      titulo={
        <Box display='flex' gap={2}>
          <Typography component='h3'>Planes y suscripción</Typography>
          {<Chip label={mensajeChip.texto} className={mensajeChip.clase} />}
        </Box>
      }
    >
      <Grid container rowSpacing={6}>
        <Grid item xs={12} display='flex' justifyContent='center' alignItems='center'>
          <Typography bold={!esPrecioAnualSeleccion}>Mensual</Typography>
          <Switch checked={esPrecioAnualSeleccion} onChange={handlePrecioAnualChange} />
          <Typography bold={esPrecioAnualSeleccion}>Anual</Typography>
        </Grid>

        <Grid item xs={12} >
          <Grid container spacing={4} rowSpacing={8}>
            {planesComponentes.map((plan, index) => <Plan
              {...plan}
              key={index}
              accion={
                plan.nombre === planActual?.Nombre && esPrecioAnual === esPrecioAnualSeleccion ?
                  <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
                    <Typography style={{ color: 'white' }} bold>PLAN ACTUAL</Typography>
                    <Button
                      label={planCancelado ? 'Reactivar' : 'Cancelar'}
                      classesCustom={{
                        boton: classes.planActualBoton,
                      }}
                      onClick={suscribirse(plan.nombre)}
                    />
                  </Box>
                  : <Button
                    label={`Cambiar a ${plan.nombre} ${esPrecioAnualSeleccion ? 'anual' : 'mensual'}`}
                    classesCustom={{
                      boton: classes.planBoton,
                    }}
                    onClick={suscribirse(plan.nombre)}
                  />
              }
            />)}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PlanesComparacion planes={planes} caracteristicas={caracteristicas} />
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='center'>
          <Typography bold>Los precios expresados son en moneda nacional M.N. Ya cuentan con IVA incluidos*</Typography>
        </Grid>
      </Grid>
    </Default>
  )
}

export default Planes;
