import { useState, useCallback, memo, useEffect, useMemo } from "react";

import propTypes from 'prop-types';
import clsx from "clsx";
import { Grid } from "@material-ui/core";

import TextField from "../TextField";
import Typography from "../Typography";

import styles from './styles';
import { NOCTURNO } from "../../constantes/tiposHorario";
import { CAMPOS_REQUERIDOS, ERROR_HORA_ENTRADA_MAYOR, ERROR_HORA_SALIDA_MENOR } from "../../configuraciones/mensajes";

const ConfiguracionPeriodo = ({
    dia, numeroPeriodo, habilitado,
    errors, onError, onChange, value,
    tipoHorario, border
}) => {
    const [mensajeError, setMensajeError] = useState('');
    const classes = styles();

    const periodoId = useMemo(() => `${dia}-${numeroPeriodo}`, [dia, numeroPeriodo]);

    //Valida que el périodo configurado no contenga errores
    const validarPeriodo = useCallback(() => {
        if (!habilitado) return;

        const { Entrada, Salida } = value;

        if (!Entrada && !Salida) return;

        if (!Entrada || !Salida) {
            errors[periodoId] = true;
            onError(current => ({ ...current, [periodoId]: true }));
            return setMensajeError(CAMPOS_REQUERIDOS)
        };

        errors[periodoId] = false;

        if (tipoHorario === NOCTURNO && (Salida.format('HH:mm') >= Entrada.format('HH:mm'))) {
            errors[periodoId] = true;
            onError(current => ({ ...current, [periodoId]: true }));
            return setMensajeError(ERROR_HORA_SALIDA_MENOR);
        }

        if (tipoHorario !== NOCTURNO && (Salida.format('HH:mm') <= Entrada.format('HH:mm'))) {
            errors[periodoId] = true;
            onError(current => ({ ...current, [periodoId]: true }));
            return setMensajeError(ERROR_HORA_ENTRADA_MAYOR);
        }

        errors[periodoId] = false;
        onError(current => ({ ...current, [periodoId]: false }));
        setMensajeError('');
    }, [tipoHorario, errors, periodoId, value, habilitado, onError]);


    //Maneja el cambio del time picker
    const handlePeriodoChange = (movimiento) => {
        return (e) => {
            const nuevoPeriodo = ({ ...value, [movimiento]: e });
            onChange(nuevoPeriodo);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => validarPeriodo(), [tipoHorario, value, habilitado]);

    return (
        <>
            <Grid container alignItems="flex-end" className={clsx(classes.periodoItem, { [classes.border]: border })}>
                <Grid item lg={6} >
                    <Typography>Periodo {numeroPeriodo}</Typography>
                    <TextField
                        label="Entrada"
                        name="Entrada"
                        disabled={!habilitado}
                        onChange={handlePeriodoChange('Entrada')}
                        value={value.Entrada}
                        required={habilitado}
                        error={errors[periodoId] && habilitado}
                        type="time"
                        ampm>
                    </TextField>
                </Grid>
                <Grid item lg={6} >
                    <TextField
                        label="Salida"
                        name="Salida"
                        disabled={!habilitado}
                        onChange={handlePeriodoChange('Salida')}
                        value={value.Salida}
                        required={habilitado}
                        error={errors[periodoId] && habilitado}
                        type="time"
                        ampm>
                    </TextField>
                </Grid>
                {habilitado && mensajeError && <Typography className={classes.error}>
                    {mensajeError}
                </Typography>}
            </Grid>
        </>
    )
};

ConfiguracionPeriodo.propTypes = {
    /** Día al que pertenece el périodo */
    dia: propTypes.string.isRequired,
    /** Número del périodo */
    numeroPeriodo: propTypes.number.isRequired,
    /** Flag que activa el périodo para ser configurado */
    habilitado: propTypes.bool.isRequired,
    /** Objeto que guarda los errores del périodo */
    errors: propTypes.object.isRequired,
    /** Función que actualiza los errores de la configuración */
    onError: propTypes.func.isRequired,
    /** Función que reacciona a los cambios del périodo */
    onChange: propTypes.func.isRequired,
    /** Valor inicial del périodo */
    value: propTypes.object.isRequired,
    /** Tipo de horario del périodo */
    tipoHorario: propTypes.string.isRequired,
}

export default memo(ConfiguracionPeriodo)