/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';
import { Box, Grid } from '@material-ui/core';

import styles from './styles';

import Boton from '../../../componentes/Button';
import TextField from '../../../componentes/TextField';
import Select from '../../../componentes/Select';
import RightDrawer from '../../../componentes/RightDrawer';
import Typography from '../../../componentes/Typography';
import Estatus from '../../../componentes/Estatus';
import Listados from '../../../contenedores/Listados';

import { capitalizar, findPropertysEmpty, requiredFIle, trim } from '../../../utilidades/functions';
import { tipoEstatus } from '../../../constantes/estatus';
import axios from '../../../configuraciones/axios';
import endpoints, { AUSENCIADETALLES, EMPLEADOS } from '../../../configuraciones/endpoints';
import { DIA_INICIO_MAYOR_A_FINAL, DIA_MENOR_A_ACTUAL, EMPLEADO_DESHABILITADO } from '../../../configuraciones/mensajes';

const cabeceros = [
  {
    label: 'Día', transform: ({ FechaAusencia }) => FechaAusencia && capitalizar(moment(FechaAusencia).format('dddd'))
  },
  {
    label: 'Fecha', transform: ({ FechaAusencia }) => FechaAusencia && capitalizar(moment(FechaAusencia).format('DD MMMM YYYY'))
  },
  { label: 'Motivo', transform: ({ Ausencia }) => Ausencia ? Ausencia.MotivoAusencia : null },
  { label: 'Estatus', transform: ({ Estatus: estatusRegistro }) => estatusRegistro && <Estatus Cadena={estatusRegistro.Nombre} Color={`#${estatusRegistro.ColorCodigo}`} Habilitado={false} /> },
  { label: 'Comentario', transform: ({ Ausencia }) => Ausencia ? Ausencia.Comentario : null }
];

const EmpleadoAusencia = ({ history, location, match }) => {

  const listadoRef = useRef(null);
  const { filtrosBusqueda: { motivos } } = useSelector(store => store.listados);
  const [isSubmit, setIsSubmit] = useState(false);
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [nuevaAusencia, setNuevaAusencia] = useState({});
  const [fechas, setFechas] = useState({
    fechaInicio: moment().add(1, 'day').format('YYYY-MM-DD'),
    fechaFinal: moment().add(1, 'day').format('YYYY-MM-DD'),

  })
  const [formErrors, setFormErrors] = useState({});

  const { params: { _id } } = match;

  const ActionDrawer = () => {
    setOpen(true);
  }

  const cancelar = () => {
    listadoRef.current.refrescarListado();
    setFormErrors({});
    Reinicio();
    setOpen(false);
  }

  const guardar = useCallback(async () => {
    setIsSubmit(true);
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmMotivoAusencia'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0)
      return;

    const { empleado } = await axios.get(endpoints.base.url(EMPLEADOS, _id));
    if (!empleado.Habilitado) {
      return toast.error(EMPLEADO_DESHABILITADO);
    }

    if (fechas.fechaInicio <= moment().format('YYYY-MM-DD' ||
      fechas.fechaFinal <= moment().format('YYYY-MM-DD'))
    ) {
      return (toast.error(DIA_MENOR_A_ACTUAL));
    } else {
      if (fechas.fechaFinal < fechas.fechaInicio) {
        return (toast.error(DIA_INICIO_MAYOR_A_FINAL))
      };
    };

    const obj = trim(nuevaAusencia)
    await axios['post'](AUSENCIADETALLES + '/' + _id, obj)

    setOpen(false);
    if (listadoRef.current) listadoRef.current.refrescarListado();
    Reinicio();
  }, [_id, nuevaAusencia])

  const Reinicio =()=>{
    setNuevaAusencia({});
    setFechas({
    fechaInicio: moment().add(1, 'day').format('YYYY-MM-DD'),
    fechaFinal: moment().add(1, 'day').format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    setNuevaAusencia({ ...nuevaAusencia, ...fechas })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechas])

  return (
    <Grid >
      <Listados
        ref={listadoRef}
        ID={_id}
        history={history}
        location={location}
        mostrar={{
          txtBusqueda: false,
          Subtitulo: false,
          Agregar: false,
          SideBar: false,
        }}
        filtrosBusqueda={{
          fechaInicioFinal: true,
          estatus: true,
          motivo: true,
          tipoEstatus: tipoEstatus.ausencia,
        }}
        cabeceros={cabeceros || []}
        apiKey={AUSENCIADETALLES}
        params={{ Estatus: 'Ausencia' }}
        toDo={ActionDrawer}
      />
      <RightDrawer
        isOpen={open}
        header={
          <Typography
            component="h2"
            className={classes.title}
          >
            Generar nueva ausencia aprobada
          </Typography>

        }
      >
        <Grid id="frmMotivoAusencia">
          <Box className={classes.actionContainer} >
            <Boton
              onClick={guardar}
              label="Guardar"
              classesCustom={{ boton: classes.btn }}
            />
            <Boton
              onClick={cancelar}
              label="Cancelar"
              classesCustom={{ boton: classes.btn }}
              border
            />
          </Box>
          <Grid>
            <Typography
              component="h3"
              className={classes.Seleccione}
            >
              Seleccione el periodo que se aprobará como ausencia para el colaborador
            </Typography>
            <Box className={classes.boxFechas} >
              <TextField
                name="fechaInicio"
                onChange={setFechas}
                isHandleChange
                value={nuevaAusencia.fechaInicio}
                type="date"
                required
                error={formErrors.fechaInicio}
                helperText={requiredFIle(isSubmit, formErrors, 'fechaInicio')}
              />
              <Typography
                compontent="h3"
                style={{ marginTop: '15px' }}
              >
                al
              </Typography>
              <TextField
                name="fechaFinal"
                onChange={setFechas}
                isHandleChange
                value={nuevaAusencia.fechaFinal}
                type="date"
                required
                error={formErrors.fechaFinal}
                helperText={requiredFIle(isSubmit, formErrors, 'fechaFinal')}

              />
            </Box>
            <Grid item className={classes.motivo}>
              <Select
                label='Motivo'
                placeHolder={'Seleccione el motivo'}
                name="Motivo"
                onChange={setNuevaAusencia}
                isHandleChange
                value={nuevaAusencia.Motivo}
                options={motivos}
                valueProp="value"
                required
                error={formErrors.Motivo}
                helperText={requiredFIle(isSubmit, formErrors, 'Motivo')}

              />
            </Grid>

            <Grid item className={classes.comentario}>
              <TextField
                label="Comentario (opcional)"
                onChange={setNuevaAusencia}
                name="Comentario"
                value={nuevaAusencia.Comentario ?? ''}
                isHandleChange
                type="textarea"
                rows={6}
                error={formErrors.Comentario}
              />
            </Grid>
          </Grid>
        </Grid>

      </RightDrawer>
    </Grid>

  );
};

export default EmpleadoAusencia;
