import { makeStyles } from '@material-ui/core';
import paleta from '../../../configuraciones/paleta';

const styles = makeStyles(() => ({
  contenedorContacto: {
    marginTop: 8,
    marginLeft: 0,
    backgroundColor: paleta.sidebar.active,
    paddingLeft: 15,
    paddingBottom: 222,

  },
  contenedorOrganizacion: {
    marginTop: 8,
    marginLeft: 0,
    backgroundColor: paleta.sidebar.active,
    paddingLeft: 15,
    paddingBottom: 20,
  },
  container: {
    paddingRight: 40
  },
  principales: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingBottom: 30,
  },
  avatar:{ 
    width: 90,
    height: 90, 
    top: 20,
  },
  boton:{
    top: -5,
    left: 60,
    fontSize: 20,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    '&:hover': {
      backgroundColor: paleta.sidebar.active,
      borderColor: paleta.sidebar.active,
    },
    },
    empleado:{
      top: -25,
    },
    eliminar: {
      marginLeft: 8, 
      color: 'red',
    }
}));

export default styles;
