import { makeStyles } from "@material-ui/core";

import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: 'common.white',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: paleta.neutral,
    },
    'td, th': {
      border: '0 !important',
    },
  }
}));

export default styles;
