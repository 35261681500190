import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  permisosContainer: {
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: -5
  },
  form: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingBottom: 30,
  },
  container: {
    paddingRight: 40
  },
  input: {
    display: 'none',
  },
}));

export default styles;
