import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@material-ui/core';

import styles from './styles';

export const TabsHeader = React.memo((props) => {
  const { value, handleChange, tabs } = props;
  const classes = styles();

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      value={value}
      onChange={handleChange}
      classes={{ root: classes.tabsRoot }}
      TabIndicatorProps={{ className: classes.indicator }}
    >
      {
        tabs.map((element) => (
          <Tab
            disableRipple
            key={element.id}
            label={element?.label}
            classes={{ root: classes.tabsRoot }}
          />
        ))
      }
    </Tabs>
  );
});

TabsHeader.propTypes = {
  /** Valor Seleccionado */
  value: PropTypes.number,
  /** Función para cambiar de valor */
  handleChange: PropTypes.func,
  /** Arreglo con los tabs a mostrar */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    /** Identificador y posición del tab */
    id: PropTypes.number,
    /** Nombre del tab */
    label: PropTypes.string,
  })),
};

TabsHeader.defaultProps = {
  tabs: [],
  value: 0,
  handleChange: () => { },
};

export const TabContainer = ((props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      style={{ width: '100%', height: '100%' }}
      {...other}
    >
      <Box display={value === index ? 'block' : 'none'} height="100%">{children}</Box>
    </div>
  );
});

TabContainer.propTypes = {
  /** Valor Seleccionado */
  value: PropTypes.number,
  /** Index */
  index: PropTypes.number,
  /** Función para cambiar de valor */
  children: PropTypes.node,
};

TabContainer.defaultProps = {
  value: 0,
  index: 0,
  children: null,
};