import { useCallback, useRef, useState } from 'react';

import moment from 'moment-timezone';

import Estatus from '../../componentes/Estatus';
import Listados from "../../contenedores/Listados"
import SolicitudJustificante from '../../componentes/SolicitudJustificante';

import { tipoEstatus } from '../../constantes/estatus';
import { JUSTIFICANTES } from "../../configuraciones/endpoints"

const cabeceros = [
    { label: 'Nombre', transform: ({ Empleado }) => Empleado?.NombreCompleto },
    { label: 'Sucursal', transform: ({ Empleado }) => Empleado?.Sucursal?.Nombre },
    { label: 'Fecha', transform: ({ Fecha }) => Fecha && moment(Fecha).format('DD MMMM YYYY') },
    { label: 'Tipo', transform: ({ Tipo }) => Tipo?.Nombre },
    { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion?.Nombre },
    { label: 'Estatus', transform: ({ Estatus: estatus }) => estatus ? <Estatus Color={`#${estatus.ColorCodigo}`} Cadena={estatus.Nombre} Habilitado={false} /> : null },
]

const Justificantes = ({ history, location }) => {
    const [isSolicitudOpen, setIsSolicitudOpen] = useState(false);
    const [justificante, setJustificante] = useState('');

    const listadoRef = useRef(null);

    const handleInfoClick = useCallback(({ _id }) => {
        setJustificante(_id);
        setIsSolicitudOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setIsSolicitudOpen(false);
        if (listadoRef.current) listadoRef.current.refrescarListado();
    }, []);

    return (
        <>
            <Listados
                ref={listadoRef}
                titulo="Justificantes"
                placeHolder="Búsqueda por empleado o sucursal"
                mostrar={{
                    txtBusqueda: true,
                    Subtitulo: true,
                    Agregar: false,
                    SideBar: true,
                }}
                filtrosBusqueda={{
                    fechaInicioFinal: true,
                    estatus: true,
                    tiposEvaluacion: true,
                    tipoEstatus: tipoEstatus.justificante
                }}
                history={history}
                location={location}
                cabeceros={cabeceros}
                apiKey={JUSTIFICANTES}
                customDetailAction={handleInfoClick}
                showActions
                actionDetail
                params= { {Estatus:'Justificante'} }

            />
            <SolicitudJustificante
                isOpen={isSolicitudOpen}
                justificanteId={justificante}
                onClose={handleDrawerClose}
            />
        </>

    );
}

export default Justificantes;
