import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '91vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '82vh',
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '85vh',
    }
  },
  main: {
    padding: theme.spacing(3),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: paleta.sidebar.backgroundColor,
    overflow: 'auto',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    }
  },
}));

export default styles;
