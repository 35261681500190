import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999999999999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default styles;
