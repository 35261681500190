import React from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '../Typography';
import styles from './styles';

const Estatus = ({ activo, Habilitado, Cadena, Color }) => {
  const classes = styles();

  return (

    <div>
      {Habilitado && <Typography className={clsx(classes.root, { disabled: !activo })}>
        {activo ? 'Habilitado' : 'Inhabilitado'}
      </Typography>}

      {Cadena && <Typography className={clsx(classes.Normal, { [`${Cadena}`]: Cadena === 'Incidencia' })} style={{ backgroundColor: Color }}>
        {Cadena}
      </Typography>}

    </div>
  )
};

Estatus.propTypes = {
  activo: propTypes.bool,
  /* Mensaje a mostrar */
  Habilitado: propTypes.bool,
  /* Flag para activar el Habilitado, siempre en true */
  Cadena: propTypes.string,
  /* Cadena personalizada */
  Color: propTypes.string
  /* Color personalizado */
};

Estatus.defaultProps = {
  Habilitado: true
};
export default React.memo(Estatus);