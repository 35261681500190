import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderBottom: `2px solid ${paleta.bar.border}`,
    overflow: 'auto',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 50,
    width: 200,
  },
  tab: {
    height: '64px',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconoContainer: {
    paddingRight: 10,
    marginRight: 10,
  },
  user: {
    color: `${paleta.bar.user} !important`,
    borderLeft: `2px solid ${paleta.bar.iconoBorder}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'inline-flex', 
    whiteSpace: 'nowrap', 
    '& span': {
      color: `${paleta.bar.user} !important`,
      textTransform: 'capitalize',
    },
  },
}));

export default styles;
