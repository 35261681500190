import { useMemo } from "react"
import { useJsApiLoader } from "@react-google-maps/api";

import general from '../configuraciones/general';

const useMap = () => {
  const libraries = useMemo(() => ['drawing'], []);

  const { isLoaded, loadError } = useJsApiLoader({      // Carga la apikey
    googleMapsApiKey: general.MAPS_API_KEY,
    libraries: libraries,
  });

  return { isLoaded, loadError }
}

export default useMap