import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    transition: 'transform .2s;',
    '&:hover': {
      transform: 'scale(1.05)',
    }
  }
}));

export default styles;
