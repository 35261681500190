import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { PERFILES } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Nombre', key: 'Nombre' },
  { label: 'Descripción', key: 'Descripcion' },
  { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
];

const Perfiles = ({ history, location }) => {

  return <Listados
    titulo="Perfiles"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    filtrosBusqueda={{
      btnAgragar: true,
    }}
    cabeceros={cabeceros}
    apiKey={PERFILES}
  />;
};

export default Perfiles;