import { useCallback, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import moment from 'moment-timezone';

import RightDrawer from '../RightDrawer';
import Button from '../Button';
import Typography from '../Typography';
import EmpleadoInfo from '../EmpleadoInfo';
import Modal from '../Modal';
import Estatus from '../Estatus';

import styles from './styles';
import axios from '../../configuraciones/axios';
import Table from '../Table';
import tipoJustificantes from '../../constantes/tipoJustificantes';
import endpoints, { JUSTIFICANTES } from '../../configuraciones/endpoints';
import { estatusJustificante } from '../../constantes/estatus';
import { capitalizar } from '../../utilidades/functions'


const SolicitudJustificante = ({
    isOpen,
    justificanteId,
    onClose,
}) => {
    const [justificante, setJustificante] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const classes = styles();

    let cabeceros;
    if (justificante?.Tipo?.Nombre === tipoJustificantes.registroCheck) {
        cabeceros = [
            { label: 'Check', transform: ({ Registro }) => Registro?.HoraCheck },
            { label: 'Horario', transform: ({ Registro }) => Registro?.HoraHorario },
            { label: 'Movimiento', transform: ({ Registro }) => Registro?.Movimiento },
            { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion?.Nombre },
            {
                label: 'Estatus',
                transform: ({ Estatus: estatus }) =>
                    <Estatus Cadena={estatus?.Nombre} Color={`#${estatus?.ColorCodigo}`} Habilitado={false} />
            },
        ]
    }
    if (justificante?.Tipo?.Nombre === tipoJustificantes.asistencia) {
        cabeceros = [
            { label: 'Día', transform: ({ Fecha }) => Fecha && capitalizar(moment(Fecha).format('dddd')) },
            { label: 'Fecha', transform: ({ Fecha }) => Fecha && capitalizar(moment(Fecha).format('DD MMMM YYYY')) },
            { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion?.Nombre },
            {
                label: 'Estatus',
                transform: ({ Estatus: estatus }) =>
                    <Estatus Cadena={estatus?.Nombre} Color={`#${estatus?.ColorCodigo}`} Habilitado={false} />
            },
        ]
    }

    const consultarDatos = useCallback(async () => {
        const justificanteResponse = await axios.get(endpoints.base.url(JUSTIFICANTES, justificanteId));
        setJustificante(justificanteResponse);
    }, [justificanteId]);

    const autorizarJustificante = useCallback(async (autorizado) => {
        await axios.post(endpoints.base.url(JUSTIFICANTES, justificanteId), { autorizado });
        await consultarDatos();
    }, [justificanteId, consultarDatos]);

    const handleVerClick = useCallback(() => setIsModalOpen(true), []);
    const handleModalClose = useCallback(() => setIsModalOpen(false), [])

    useEffect(() => {
        if (isOpen) consultarDatos();
    }, [isOpen, consultarDatos]);

    return (
        <>
            <Modal
                titulo="Evidencia"
                open={isModalOpen}
                handleClose={handleModalClose}
                showActions
            >
                <Box display="flex" justifyContent="center">
                    <img className={classes.evidencia} src={justificante?.Evidencia?.secure_url} alt=""/>
                </Box>
            </Modal>
            <RightDrawer isOpen={isOpen}>
                <Box flex={1} className={classes.header}>
                    <Typography component='h2'>Solicitud de justificante</Typography>
                    <Button label='Cerrar' onClick={onClose} border />
                </Box>
                <EmpleadoInfo empleado={justificante?.Empleado} />
                <Box display="flex" mt={2}>
                {justificante?.Tipo?.Nombre === tipoJustificantes.registroCheck &&
                <>
                    <Box style={{textTransform: 'capitalize'}} display="flex" flexDirection="column" p={2}>
                        <Typography bold>Día</Typography>
                        <Typography>{justificante.Fecha && moment(justificante.Fecha).format('dddd')}</Typography>
                    </Box>
                    <Box style={{textTransform: 'capitalize'}} display="flex" flexDirection="column" p={2}>
                        <Typography bold>Fecha</Typography>
                        <Typography>{justificante.Fecha && moment(justificante.Fecha).format('DD MMMM YYYY')}</Typography>
                    </Box>
                </>}
                </Box>
                <Table
                    headers={cabeceros}
                    rows={[justificante]}
                    showActions={false}
                    customStyles={{ minHeight: 'revert' }}
                    hidePaginator
                />
                <Box display="flex" flexDirection="column" mt={2}>
                    <Typography className={classes.textBlue} bold>
                        Detalle
                    </Typography>
                    <Typography className={classes.textGray}>
                        {justificante?.Comentario}
                    </Typography>
                </Box>
                {justificante?.Evidencia && <Box display="flex" alignItems="center">
                    <Typography className={classes.textBlue} bold>
                        Foto adjunto:
                    </Typography>
                    <Button
                        label='Ver'
                        classesCustom={{
                            boton: [classes.boton]
                        }}
                        onClick={handleVerClick}
                    />
                </Box>}
                {justificante?.Estatus?.Nombre === estatusJustificante.solicitado && <Box className={classes.botones} mt={2}>
                    <Button
                        label='Autorizar'
                        classesCustom={{
                            boton: [classes.boton]
                        }}
                        onClick={() => autorizarJustificante(true)}

                    />
                    <Button
                        label='Rechazar'
                        onClick={() => autorizarJustificante(false)}
                        border
                    />
                </Box>}
            </RightDrawer>
        </>
    )
}

export default SolicitudJustificante;
