import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  main: {
    backgroundImage: 'url(/assets/images/Background_dack.svg)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    minHeight: 700,
  },
  body: {
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  contenedorTitulo: {
    fontFamily: 'Designer',
    '& h1': {
      fontSize: 44,
      fontWeight: '500',
    },
    '& h2': {
      fontSize: 38,
      fontWeight: '500',
    },
  },
  card: {
    [theme.breakpoints.up("md")]: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'lightgray',
      borderRadius: 10,
      padding: 30,
      backgroundColor: 'rgba(255,255,255,0.90)',
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    '& p': {
      fontSize: '12px'
    },
  },
  botonInicio: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      marginTop: '60px',
      padding: '10px',
      fontSize: '18px',
      backgroundColor: '#31346C' //Color Opcional
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: '40px',
      padding: '5px',
      fontSize: '16px',
    },
  },
  logoCheck: {
    height: 80,
    width: 'fit-content',
    [theme.breakpoints.down("md")]: {
      width: 60,
      height: 60,
    },
  },
  ContenedorTexto: {
    spacing: 1,
    direction: "column",
    marginTop: '40%',
    marginLeft: '45%',
  }
  ,
  textocentrado: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '150%',
    marginLeft: '10%',
    marginTop: '0%',
    width: '60%',
    color: '#c1c1c1',
  },
  smallText: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '40%',
    width: '50%',
    color: '#c1c1c1',
    fontSize: '',
  },
  Logo: {
    height: '50%',
    width: '50%',
  },

}));

export default styles;
