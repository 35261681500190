import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  permisosContainer: {
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: -5,
  },
  form: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingBottom: 30,
  },
  input: {
    display: 'none',
  },
  avatar: {
    width: '50px', 
    height: '50px', 
    marginRight: '10px',
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  empleado: {
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: '10px',
    marginBottom: 'auto',
    marginTop: 'auto',
    maxWidth: '250px',
  },
  cabecero: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px',
    
  },
  tab: {
    marginLeft: '70px',
  },
  iconButton: {
    fontSize: '25px',
    width: '45px',
    height: '45px',
    marginLeft: '20px',
  },
  listadoSelect: {
    display: 'flex' ,
    flexDirection: 'column !important',
    flexWrap: 'wrap',
    boxSizing: 'border-box', 
  },
}));

export default styles;
