import { Box, Avatar } from '@material-ui/core'

import Typography from '../Typography';
import EmpleadoDetalle from './EmpleadoDetalle';

import styles from './styles';

const EmpleadoInfo = ({
    empleado
}) => {
    const classes = styles();

    return (
        <Box flex={1} className={classes.empleadoBox} p={2} mt={4}>
            <Box className={classes.empleadoHeader}>
                <Avatar src={empleado?.Foto?.secure_url} />
                <Typography component='h2'>{empleado?.Nombres} {empleado?.Apellidos}</Typography>
            </Box>
            <Box className={classes.infoEmpleado}>
                <EmpleadoDetalle atributo="Empresa" valor={empleado?.Empresa?.Nombre} />
                <EmpleadoDetalle atributo="Sucursal" valor={empleado?.Sucursal?.Nombre} />
                <EmpleadoDetalle atributo="Departamento" valor={empleado?.Departamento?.Nombre} />
                <EmpleadoDetalle atributo="Puesto" valor={empleado?.Puesto?.Nombre} />
            </Box>
        </Box>
    );
}

export default EmpleadoInfo;