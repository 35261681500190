
import React, { useCallback, useState } from 'react'
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLocationPlus } from 'react-icons/bi';
import { toast } from 'react-toastify';      // notificaciones

import propTypes from 'prop-types';

import clsx from 'clsx';
import { Grid, Box, IconButton, } from '@material-ui/core';

import TextField from '../TextField';
import Typography from '../Typography';
import Boton from '../Button';
import RightDrawer from '../RightDrawer';
import SucursalGeolocalizacion from '../SucursalGeolocalizacion';

import { SUCCESS } from '../../configuraciones/mensajes';
import { DECIMAL_REGEX, regexValid } from '../../utilidades/regex';
import { findPropertysEmpty, requiredFIle } from '../../utilidades/functions';

import styles from './styles';

export const TextfieldWithActions = ({ texto, value, onChange, name, required,
  placeHolder, onKeyDown, disabled,
  isHandleChange, inputClassName, error, inputProps, titulo, inicio, helperText
}) => {

  const classes = styles();
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [show, setShow] = useState(false);
  const [datos, setDatos] = useState(inicio)

  const customOnChange = (e) => {
    if (regexValid(e) && onChange)
      if (isHandleChange)
        onChange(() => { })
      else
        onChange(e);
  };

  //Guarda los valores de la geolocalizacion en el padre onclick
  const guardar = useCallback(() => {
    setIsSubmit(true);
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmGeolocalizacion'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0)
      return;

    setShow(false);
    onChange((current) => ({
      ...current, [name]: {
        ...current.Geolocalizacion, latitud: datos.latitud, longitud: datos.longitud
      }
    }));
    onChange((current) => ({ ...current, [name]: datos }));
    toast.success(SUCCESS)
  }, [datos, name, onChange]);

  //Establece un valor nulo a las coordenadas y el valor de 0 al radio on click
  const handleButtonDelete = useCallback(() => {
    setDatos({ ...datos, latitud: null, longitud: null, })
    onChange((current) => ({ ...current, [name]: { latitud: null, longitud: null, radio: 0 } }));
  }, [name, onChange, datos]
  )

  //Funcion para mostrar el menu onclick
  const handleButtonShow = () => setShow(!show);

  //Funcion para cerrar el menu onclick
  const cancelar = useCallback(() => {
    setShow(false);
    setIsSubmit(false);
  }, []);

  //Agrega el formato a mostrar de las coordenadas con un limite despues del punto decimal
  const formato = useCallback((value) => {

    if (value && value.latitud && value.longitud)
      return (`${value.latitud.toFixed(8)}, ${value.longitud.toFixed(8)}`)
    else
      return
  }, [])

  return (
    <Grid item lg={12} md={4} sm={12} xs={12}>
      <Typography className={classes.label}>
        {texto}
        {required && ' * '}
      </Typography>
      <div>
        <Grid container spacing={0} className={classes.box}>
          <input
            className={clsx(classes.input, inputClassName, { [classes.error]: error, disabled })}
            value={formato(value) || ''}
            name={name}
            onChange={customOnChange}
            required={required}
            placeholder={placeHolder}
            onKeyDown={onKeyDown}
            {...inputProps}
            disabled
          />
          <IconButton
            style={{ borderRadius: '0' }}
            className={classes.boton}
            onClick={handleButtonShow}
          >
            <BiLocationPlus />
          </IconButton>
          <IconButton
            className={classes.boton}
            onClick={handleButtonDelete}
          >
            <RiDeleteBinLine />
          </IconButton>

          <RightDrawer
            isOpen={show}
            header={
              <>
                <Typography
                  component="h2"
                  className={classes.title}
                >
                  {titulo}
                </Typography>
                <Grid container id="frmGeolocalizacion">
                  <Grid item lg={6} md={12} sm={12} xs={12}  >
                    <TextField
                      className={classes.Display}
                      onChange={setDatos}
                      name="radio"
                      type="numero"
                      value={datos.radio}
                      label="Radio (Mts)"
                      placeHolder={'Cobertura'}
                      inputProps={{ maxLength: 10, regexonchange: 'true', regex: DECIMAL_REGEX }}
                      isHandleChange
                      required
                      error={isSubmit ? formErrors.radio : false}
                      helperText={requiredFIle(isSubmit, formErrors, 'radio')}
                    />

                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}  >
                    <Box flex={1} className={classes.actionContainer} style={{ marginTop: '22px' }}>
                      {guardar && (
                        <Boton
                          onClick={guardar}
                          label="Guardar"
                          classesCustom={{ boton: classes.btn }}
                        />
                      )}
                      {cancelar && (
                        <Boton
                          onClick={cancelar}
                          label="Cancelar"
                          classesCustom={{ boton: classes.btn }}
                          border
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
            children={
              <Grid >
                <Grid>

                  <SucursalGeolocalizacion
                    mapType='roadmap'
                    onChange={setDatos}
                    value={datos}
                    isHandleChange
                    inicio={inicio}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </div>
      {(helperText && helperText !== '') && <Typography
        className={clsx(classes.helperText, { error })}
        component="p">
        {helperText}
      </Typography>}
    </Grid>
  );
}

TextfieldWithActions.propTypes = {
    /** Etiqueta a mostrar encima del cuadro */
  texto: propTypes.string,
    /** Valor de coordenadas y radio enviadas al padre*/
  value: propTypes.shape({
    latitud: propTypes.number,
    longitud: propTypes.number,
    radio: propTypes.number,
  }),
  /** Funcion para cambiar el estado en el padre */
  onChange: propTypes.func,
  /** Propiedad que tomara lugar en el padre */
  name: propTypes.string.isRequired,
  /** Propiedad para marcar como requerido */
  required: propTypes.bool,
  /** Texto a mostrar dentro de la casilla */
  placeHolder: propTypes.string,
  /** Propiedad para buscar una ciudad y mostrar en el mapa */
  onKeyDown: propTypes.string,
  /** Deshabilitado */
  disabled: propTypes.bool,
  /** Manejar el estado sin perder informacion*/
  isHandleChange: propTypes.bool,
  /** Manejar el estilo de la clase */
  inputClassName: propTypes.string,
  /** Mostrado de errores */
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  /** Propiedad para seguir reglas en el input */
  inputProps: propTypes.object,
  /** Titulo que llevara el rightdrawer */
  titulo: propTypes.string,
  /** Mensaje de ayuda para el usuario */
  helperText: propTypes.string,
}
TextField.defaultProps = {
  texto: '',
  placeHolder: '',
  disabled: false,
  isHandleChange: false,
  error: false,
  inputProps: {},
  titulo: '',
  helperText: '',
};
export default React.memo(TextfieldWithActions);
