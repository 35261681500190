import React from 'react';

import moment from 'moment-timezone';

import Estatus from '../../componentes/Estatus/index';
import Listados from '../../contenedores/Listados';

import tipoEvaluaciones from '../../constantes/Evaluaciones'
import { REGISTROS } from '../../configuraciones/endpoints';
import { tipoEstatus } from '../../constantes/estatus';
import { capitalizar } from '../../utilidades/functions';

const cabeceros = [
  { label: 'Nombre', transform: ({ Empleado }) => Empleado ? Empleado.NombreCompleto : null },
  { label: 'Fecha', transform: ({ FechaCheck }) => FechaCheck ? capitalizar(moment(FechaCheck).format('DD MMMM YYYY')): null },
  { label: 'Check', transform: ({ FechaCheck }) => moment(FechaCheck).format('HH:mm') },
  { label: 'Horario', transform: ({ HoraHorario }) => HoraHorario },
  { label: 'Movimiento', key: "Movimiento" },
  { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion ? Evaluacion.Nombre : null },
  { label: 'Estatus', transform: ({ Estatus: estatusRegistro }) => estatusRegistro ? <Estatus Cadena={estatusRegistro.Nombre} Habilitado={false} /> : null },
];

const Registros = ({ history, location }) => {
  return <Listados
    titulo="Registros Check"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    mostrar={{
      Subtitulo: true,
      SideBar: true,
    }}
    filtrosBusqueda={{
      fechaInicioFinal: true,
      evaluacion: true,
      estatus: true,
      busqueda: true,
      tipoEstatus: tipoEstatus.RegistroCheck,
      tipoEvaluacion: tipoEvaluaciones.RegistroCheck
    }}
    cabeceros={cabeceros || []}
    apiKey={REGISTROS}
    params={ {Estatus: 'RegistroCheck', Evaluacion: tipoEvaluaciones.RegistroCheck } }
  />;
};

export default Registros;
