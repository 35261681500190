/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, IconButton, TextField } from "@material-ui/core";
import clsx from "clsx";
import styles from './styles';
import propTypes from 'prop-types';
import Typography from "../Typography";
import { MdOutlineHideImage } from "react-icons/md";
import { MdOutlineBrokenImage } from "react-icons/md";

export const UploadImage = ({ onChange, label, url, className }) => {
  const classes = styles();
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const inputRef = useRef(null);

  //Funcion que permite cargar un archivo de tipo imagen al dar click al IconButton
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      //setFile carga la imagen completa de lo que el usuario haya seleccionado
      setFile(file);
      //Se carga el nombre del archivo (imagen) a image para visualizar el nombre
      setImage(file.name);
      //Se carga una previsualización de la imagen, que contiene el URL temporal para verlo
      setPreview(URL.createObjectURL(file));
      //Cambiamos el estado de isDelete a false, puesto que el botón de agregar esta activo
      setIsDelete(false);
    }
  };

  //Método para devolver el nombre de la imagen al obtener una URL que recibe del servidor
  const nombreImagen = (url) => {
    const cadena = url;
    if (cadena === undefined || cadena === null) return;
    //Despejar el nombre de la imagen de la URL
    const resultado = cadena.split('/').pop();
    return resultado;
  }

  //Método para que se pueda abrir la pestaña de  dialogo para seleccionar imagenes
  //en caso de dar click al boton agregar imagen, haciendo referencia al input con inputRef
  const handleButtonClick = () => {
    inputRef.current.click();
  };

  //Método para cambiar el estaod de isDelete a true, para poder proceder a eliminar la imagen
  const handleDeleteImage = () => {
    setIsDelete(true);
    inputRef.current.value = '';
  };

  //Hook para realizar dos procesos dependientes a file e isDelete
  useEffect(() => {
    //si file existe e isDelete es false
    if (file && isDelete === false) {
      //cargamos el archivo completo a la función que se recibe como prop
      //para que al momento de registrar en formdata se mande por separado y no directamente
      onChange(file);
    }else{
      //en caso de ser falso la condición del if
      //el url, image, preview se actualiza (como si no hubieran seleccionado imagen)
      url = null;
      setImage(undefined);
      setPreview(null);
      //el onChange lo mandamos como cadena vacia para validar en back los posibles casos
      //(agregar, editar y eliminar imagen)
      onChange('');
    }
  }, [file, isDelete]);

  //Se encarga de establecer la previsualización de la imagen y el nombre
  //al momento de editar un registro
  useEffect(() => {
    setPreview(url);
    setImage(nombreImagen(url));
  }, [url]);

  return (
    <Grid>
      <Box >
        <input
          accept="image/*"
          className={classes.inputFile}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
        />

        <div className={clsx(classes.root, className)}>
          {label && (
            <Typography className={classes.label}>
              {label}
            </Typography>
          )}
          <TextField
            className={classes.input}
            variant="standard"
            placeholder="URL"
            value={(image === undefined) ? '' : `${image}`}
            InputProps={{
              endAdornment: (
                <div className={classes.endAdornment}>
                  <IconButton onClick={handleDeleteImage} className={classes.iconDelete}>
                    <MdOutlineHideImage />
                  </IconButton>
                  <IconButton onClick={handleButtonClick} className={classes.iconAdd}>
                    <MdOutlineBrokenImage />
                  </IconButton>
                </div>
              ),
              disableUnderline: true,
            }}
          />
        </div>
        {preview && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <img src={preview}
                alt="Vista Previa"
                className={classes.image} />
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
}

UploadImage.propTypes = {
  onChange: propTypes.func,
  label: propTypes.string,
  name: propTypes.string,
  url: propTypes.string,
  className: propTypes.string,
}

UploadImage.defaultProps = {
  label: 'Logo',
}

export default React.memo(UploadImage);;
