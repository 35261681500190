import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import paleta from '../configuraciones/paleta';

let theme = createMuiTheme({
  typography: {
    fontSize: 12,
    fontFamily: '"Avenir", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiTypography: {
      root: {
        color: paleta.bar.icono,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        maxHeight: 45,
        height: 45,
        boxShadow: 'none !important',
        textTransform: 'none'
      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important',
      },
      rounded: {
        borderRadius: 8,
      }
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: 'inherit !important',
      },
    },
    MuiTabs: {
      indicator: {
        top: 0,
        backgroundColor: paleta.bar.primary,
      },
      root: {
        paddingTop: 1,
        '& .MuiTab-root': {
          color: paleta.bar.secondary,
          textTransform: 'none',
          fontWeight: 600,
        },
        '& .Mui-selected': {
          color: `${paleta.bar.primary} !important`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-checked': {
          color: `${paleta.bar.user} !important`,
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        color: `${paleta.switch.default} !important`,
      },
      colorSecondary: {
        "&$checked": {
          color: `${paleta.switch.checked} !important`,
        }
      },
      track: {
        opacity: 0.2,
        backgroundColor: paleta.switch.track,
        "$checked$checked + &": {
          opacity: 0.7,
          backgroundColor: paleta.switch.track,
        },
      },
    }
  },
});

theme = responsiveFontSizes(theme);

export default theme;