import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
    empleadoBox: {
        backgroundColor: '#EAF0F6',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '3rem',
    },
    empleadoHeader: {
        display: 'flex',
        gap: '1rem',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    infoEmpleado: {
        display: 'flex',
        gap: '1.4rem',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    textBlue: {
        fontWeight: 'bold',
        color: '#3b6175'
    },
    textGray: {
        color: '#a4a8aa'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
        justifyContent: 'flex-start',
        alignItems: 'justify',
        marginLeft: '20px',
        marginRight: '20px',
    },
}));

export default styles;