import React from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone'
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from './componentes/Spinner';
import Routes from './configuraciones/rutas';
import TemaDefault from './temas/default';

import store from './ducks';

moment.locale('es-mx');

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={TemaDefault}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <ToastContainer />
          <Routes />
          <Spinner />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
