import React, { useEffect, useState } from 'react'
import RightDrawer from '../RightDrawer';
import Typography from '../Typography';
import Boton from '../Button'
import { Grid } from '@material-ui/core';
import styles from './styles';
import Table from '../Table';
import Estatus from '../Estatus';
import moment from 'moment-timezone';
import Modal from '../Modal'
import axios from '../../configuraciones/axios';
import { DISPOSITIVOS } from '../../configuraciones/endpoints';
import { capitalizar } from '../../utilidades/functions';

export const EmpleadoDispositivoDrawer = ({
  isOpen, dispositivo, onClose
}
) => {
  const classes = styles();
  const [show, setShow] = useState(false)
  const [dispositivoInfo, setDispositivoInfo] = useState()

  const cabeceros = [
    { label: 'Modelo', transform: ({ Modelo }) => Modelo },
    { label: 'Mac Addres', transform: ({ MacAdress }) => MacAdress ? MacAdress : null },
    {
      label: 'Período activo', transform: ({ FechaInicio, FechaFin }) => {
        if (FechaInicio && FechaFin) {
          return (`${capitalizar(moment(FechaInicio).format('DD MMMM YYYY'))} /
          ${capitalizar(moment(FechaFin).format('DD MMMM YYYY'))}`
        );
        } else if (FechaInicio) {
          return (`${capitalizar(moment(FechaInicio).format('DD MMMM YYYY'))} / Actual`)
        } else
          return null;
      }
    },
    { label: 'Estatus', transform: ({ Habilitado }) => <Estatus activo={Habilitado} /> },
  ]

  const modalOpen = () => {
    setShow(true);
  };
  const modalClose = () => {
    setShow(false);
  };

  const cancelarInhabilitar = () => {
    setShow(false)
  }
  const inhabilitar = async () => {
    const obj = ({ ...dispositivoInfo, Habilitado: false, FechaFin: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ') });
    setDispositivoInfo(obj);
    await axios.put(DISPOSITIVOS, obj);
    setShow(false);
    onClose();
  }

  useEffect(() => {
    setDispositivoInfo(dispositivo)
  }, [dispositivo])

  return (
    <>
      <RightDrawer
        isOpen={isOpen}
        header={
          <Grid
            className={classes.HeaderBox}
          >

            <Typography
              component="h2"
            >
              Detalle de dispositivo
            </Typography>
            <Boton
              onClick={onClose}
              label="Cancelar"
              classesCustom={{ boton: classes.btn }}
              border
            />
          </Grid>
        }
        children={
          <Grid>
            <Grid item className={classes.tableBox}>
              <Table
                headers={cabeceros}
                rows={[dispositivo]}
                showActions={false}
                customStyles={{ minHeight: 'revert' }}
                hidePaginator
              />
            </Grid>
            <Grid item className={classes.buttonbox}>
              {dispositivo.Habilitado && <Boton
                onClick={modalOpen}
                label="Inhabilitar dispositivo"
                classesCustom={{ boton: classes.btn }}
              />}
            </Grid>
          </Grid >

        }
      />
      <Modal
        titulo={'¿Está seguro que desea inhabilitar el dispositivo?'}
        open={show}
        width={'xs'}
        onClose={modalClose}
        children={

          <Grid className={classes.SiNoButtons} >
            <Boton
              onClick={inhabilitar}
              label="Si"
              classesCustom={{ boton: classes.btn }}
            />
            <Grid className={classes.BotonNo}>
              <Boton
                onClick={cancelarInhabilitar}
                label="No"
                classesCustom={{ boton: classes.btn }}
                border
              />
            </Grid>
          </Grid>}
      />
    </>

  )
}
