import { makeStyles } from '@material-ui/core';


const styles = makeStyles((theme) => ({

  box: {
    display: 'grid',
    gridTemplateColumns: '4fr 49px 49px',
    alignItems: 'center',
    width: '95%',
  },
  actionContainer: {
       display: 'grid ',
       gridTemplateColumns: '100px 100px',
       justifyContent: 'flex-end ',
  },
  Seleccione: {
      marginTop: '60px'
  },
  boxFechas: {
    marginTop: '60px',
    display: 'grid ',
       gridTemplateColumns: '150px 20px 150px',
  },
  comentario: {
    marginTop: 30,
    width: '85%'
  },
  motivo: {
    marginTop: 60,
    width: '45%'
  },
  
}));

export default styles;