import { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import moment from 'moment-timezone';

import RightDrawer from '../../RightDrawer';
import Button from '../../Button';
import Typography from '../../Typography';
import EmpleadoInfo from '../../EmpleadoInfo';
import Modal from '../../Modal';
import Estatus from '../../Estatus';
import Table from '../../Table';

import styles from '../styles';
import axios from '../../../configuraciones/axios';
import endpoints, { ASISTENCIA, REGISTROS } from '../../../configuraciones/endpoints';
import { constanteEvaluaciones } from '../../../constantes/Evaluaciones';
import { capitalizar } from '../../../utilidades/functions';

const cabeceros = [
    { label: 'Check', transform: ({ HoraCheck }) => HoraCheck? moment(HoraCheck, 'HH:mm:ss').format('HH:mm') : null },
    { label: 'Horario', transform: ({ HoraHorario }) => HoraHorario? moment(HoraHorario, 'HH:mm:ss').format('HH:mm') : null },
    { label: 'Movimiento', transform: ({ Movimiento }) => Movimiento? Movimiento : null },
    { label: 'Evaluación', transform: ({ Evaluacion }) => Evaluacion? Evaluacion?.Nombre : null },
    { label: 'Estatus', transform: ({ Estatus: estatus }) => <Estatus Cadena={estatus?.Nombre} Color={`#${estatus?.ColorCodigo}`} Habilitado={false} /> },
];

const EmpleadoAsistencia = ({
    isOpen,
    asistenciaId,
    onClose,
}) => {
    const [asistencia, setAsistencia] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const classes = styles();

    const [rowsCheck, setRowsCheck] = useState(null);

    const consultarDatos = useCallback(async () => {
        const { Asistencia } = await axios.get(endpoints.base.url(ASISTENCIA, `asistenciasCheck/${asistenciaId}`));
        setAsistencia(Asistencia);

        const { registrosCheck } = await axios.get(endpoints.base.url(REGISTROS, asistenciaId));
        setRowsCheck(registrosCheck);
    }, [asistenciaId]);

    const handleModalClose = () => setIsModalOpen(false)

    useEffect(() => {
        if (isOpen) {
            consultarDatos();
        }
    }, [consultarDatos, isOpen]);

    return (
        <>
            <Modal
                titulo="ModalAsistencias"
                open={isModalOpen}
                handleClose={handleModalClose}
                showActions
            >

            </Modal>
            <RightDrawer isOpen={isOpen}>
                <>
                    <Box display='flex' className={classes.header} justifyContent="space-between" alignItems="center">
                        <Typography component='h2'>Detalle de asistencia</Typography>
                        <Button  label='Cerrar' onClick={onClose} border />
                    </Box>

                    <EmpleadoInfo empleado={asistencia?.Empleado} />

                    <Box display="flex" mt={2}>
                        <Box display="flex" flexDirection="column" p={2} mr={5}>
                            <Typography bold>Día</Typography>
                            <Typography>{
                                capitalizar(moment(asistencia?.FechaAsistencia).format('dddd'))
                            }</Typography>
                        </Box>

                        <Box display="flex" flexDirection="column" p={2} mr={5}>
                            <Typography bold>Fecha</Typography>
                            <Typography>{
                                asistencia?.FechaAsistencia &&
                                capitalizar(moment(asistencia?.FechaAsistencia)
                                .format('DD MMMM YYYY'))
                            }</Typography>
                        </Box>

                        <Box display="flex" flexDirection="column" p={2} mr={5}>
                            <Typography bold>Evaluación</Typography>
                            <Typography>{asistencia?.Evaluacion?.Nombre}</Typography>
                        </Box>

                        <Box display="flex" flexDirection="column" p={2} mr={5}>
                            <Typography bold>Estatus</Typography>
                            <Typography><Estatus Cadena={asistencia?.Estatus?.Nombre} Habilitado={false} /></Typography>
                        </Box>
                    </Box>
                    {(asistencia?.Evaluacion?.Nombre !== constanteEvaluaciones.descanso) &&
                    (<Table
                        headers={cabeceros}
                        showActions={false}
                        rows={rowsCheck?.docs || []}
                        count={rowsCheck?.totalDocs || 0}
                        hidePaginator
                    />)}
                </>
            </RightDrawer>
        </>
    )
}

export default EmpleadoAsistencia;
