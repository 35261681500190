import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';


const styles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    maxHeight: '75vh',
    overflow: 'auto',
    minHeight: '400px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      overflow: 'auto',
      height: '55vh',
    },
    border: `1px solid rgba(36, 36, 36, 0.1)`,
    position: 'relative',
  }, table: {
    borderCollapse: 'separate',
    width: '100%',
    borderSpacing: '0px',
    overflow: 'auto',
    maxHeight: '75vh',
    '& th, td': {
      borderBottom: `1px solid ${paleta.tabla.bordertd}`,

    },
    '& th': {
      padding: theme.spacing(1),
      backgroundColor: paleta.sidebar.active,
      top: 0,
      zIndex: 10,
      userSelect: 'none',
      '& span': {
        fontSize: 15,
        textTransform: 'uppercase',
      },
    },

  },
  label: {
    fontWeight: 600,
    fontSize: 16,
  },
  checkbox: {
  },
  titulosTabla: {
    textAlign: 'left',
    padding: '5px 90px 5px 7px',
  },
  margenSeccion: {
    borderRight: '1px solid #EAF0F6',
    borderLeft: '2px solid #EAF0F6',
    verticalAlign: 'top',

  },
  seccion: {
    textAlign: 'left',
    padding: '5px 0px 5px 0',
    marginTop: '0',
    marginBottom: '0',

  },
  active: {
    backgroundColor: '#EAF0F6',
    boxSizing: 'border-box',
    width: '100%',
    borderLeft: '4px solid #667497',

    textAlign: 'left',
    padding: '5px 0px 5px 0',
    marginTop: '0',
    marginBottom: '0',
  },
  activeModulo: {

    backgroundColor: '#EAF0F6',
    boxSizing: 'border-box',
    width: '100%',
    borderLeft: '4px solid #667497',

    textAlign: 'left',
    display: 'Block',
    padding: '3px 90px 5px 7px',

    marginTop: '0',
    marginBottom: '0',

    borderTop: '0px',
    borderBottom: '2px solid #EAF0F6',
    borderRight: '2px solid #EAF0F6',


  }

  ,
  margenModulo: {
    border: '1px solid #EAF0F6',
    padding: '0',
    verticalAlign: 'top',
    minWidth: '250px'

  },
  modulo: {
    textAlign: 'left',
    display: 'Block',
    padding: '3px 90px 5px 7px',

    marginTop: '0',
    marginBottom: '0',

    borderTop: '0px',
    borderLeft: '2px solid #EAF0F6',
    borderBottom: '2px solid #EAF0F6',
    borderRight: '2px solid #EAF0F6',


  },
}));

export default styles;