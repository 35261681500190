import { useEffect, useRef, useState } from "react";
import { useDropzone } from 'react-dropzone';
import Boton from '../Button';
import styles from './styles';
import Typography from "../Typography";
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import canvasPreview from "../../utilidades/canvasPreview";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { FiArrowLeft } from "react-icons/fi";
import propTypes from 'prop-types';
import { toast } from "react-toastify";
import { LIMITE_MAYOR_A_1_MB } from "../../configuraciones/mensajes";



export const Drag = (
  { onChange }
) => {
  const [files, setFiles] = useState([]);                              //Almacena la imagen elegida
  const [open, setOpen] = useState(false);                             //Determina si se abre el modal 
  const [dimensiones, setDimensiones] = useState();                    //Modelo de dimensiones de la imagen
  const [initialCrop, setInitialCrop] = useState();                    //Maneja el Crop inicial de la imagen ajustando el centro y dimensiones
  const [crop, setCrop] = useState({});                                //Cambios en el crop
  const [completedCrop, setCompletedCrop] = useState();                //Crop elegido
  const [saved, setSaved] = useState();                                //Flag de guardado
  const [avatarImg, setAvatarImg] = useState();                        //Imagen que se enviará al padre
  const imageCropRef = useRef(null);                                   //Imagen con crop
  const imgRef = useRef(null);


  const classes = styles();

  /** Toma el archivo droppeado y lo convierte */
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles: 1,
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      if(file.size > 1000000){
        return(toast.error(LIMITE_MAYOR_A_1_MB));
      }
      setFiles(Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setOpen(true);
    }
  });

  /** Centra y da dimension al crop inicial de la imagen */
  const centerAspectCrop = (
    mediaWidth, mediaHeight, aspect
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  /** Toma dimensiones de la imagen */
  const onImageLoad = (e) => {
    URL.revokeObjectURL(files.preview)
    const { width, height } = e.currentTarget
    setDimensiones({ width, height })
    setInitialCrop(centerAspectCrop(width, height, 1 / 1))
    setAvatarImg();
  }

  /** Realiza la correcion del recorte inicial */
  useEffect(() => {
    if (initialCrop && dimensiones) {
      const Widthheight = {
        unit: 'px',
        x: initialCrop.x / 100 * dimensiones.width,
        y: initialCrop.y / 100 * dimensiones.height,
        width: initialCrop.width / 100 * dimensiones.width,
        height: initialCrop.height / 100 * dimensiones.height,
      }
      setCrop(Widthheight)

    }
  }, [dimensiones, initialCrop])

  /** Crop de imagen */
  useDebounceEffect(
    async () => {
      setCompletedCrop(crop);
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        imageCropRef.current
      ) {
        canvasPreview(
          imgRef.current,
          imageCropRef.current,
          completedCrop,
        )
      }
    },
    100,
    [completedCrop, crop],
  )

  /** Cerrar camara */
  const closeDialog = () => {
    setOpen(!open);
  }

  /**Guardar foto de repositorio */
  const saveCropPhoto = () => {
    const canvas = document.getElementById('my-canvas');
    const img = new Image();
    img.src = canvas.toDataURL();
    setSaved(true)
    setAvatarImg(img.src);
  };

  /** Se envia la imagen recortada al padre */
  useEffect(() => {
    if (saved) {
      onChange(avatarImg);
      setOpen(false);
      setSaved(false)
    };
  }, [avatarImg, onChange, saved]);


  return (
    <div>
      <section>
        <div {...getRootProps({ className: 'dropzone' })} className={classes.DragDrop}>
          <input {...getInputProps()} />
          <Typography variant='h5' className={classes.arrastreCentrado}>
            Arrastra una foto aquí
          </Typography>
          <Typography variant='h5' className={classes.oCentrado}>
            ó
          </Typography>

        </div>
        <aside
          className={classes.thumbsContainer}
        >
          <Dialog
            open={open}
            styles={{ width: '600px' }}
          >
            <DialogTitle
              style={{ textAlign: 'center', padding: '16px 0px 20px 0px', }}
            >
              <IconButton
                onClick={closeDialog}
              >
                <FiArrowLeft />
              </IconButton>
              {"Regresar"}
            </DialogTitle>
            <DialogContent>
              <div>
                <ReactCrop
                  circularCrop
                  maxWidth={450}
                  crop={crop}
                  aspect={1}
                  onChange={setCrop}
                  onComplete={setCompletedCrop}
                >
                  <img
                    ref={imgRef}
                    src={files.preview}
                    alt=''
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
                <div>
                  <canvas
                    hidden
                    id={'my-canvas'}
                    ref={imageCropRef}
                  />
                </div>

              </div>
            </DialogContent>
            <DialogActions
              className={classes.botonCentrado}
            >
              {files && (
                <Boton
                  label="Guardar foto"
                  onClick={saveCropPhoto}
                />
              )}
            </DialogActions>
          </Dialog>
        </aside>
      </section>
    </div>
  );
}
  Drag.propTypes = {
      /* Mandará la imagen al padre en base 64*/
    onChange: propTypes.func
  }
export default Drag;