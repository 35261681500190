import { useSelector } from 'react-redux';

import Default from '../../contenedores/Default'
import Chat from '../../componentes/Chat';

const ChatSoporte = ({ history, path }) => {
  const { user: { cliente } } = useSelector(({ user }) => user);

  return <Default
    titulo='Chat de soporte'
  >
    <Chat tipoMensaje='E' cliente={cliente} />
  </Default>
}

export default ChatSoporte;
