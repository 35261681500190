import React, { useCallback, useRef, useState } from 'react';

import moment from 'moment-timezone';

import Listados from '../../contenedores/Listados';
import Estatus from '../../componentes/Estatus/index';
import EmpleadoAsistencia from '../../componentes/EmpleadoInfo/EmpleadoAsistencia';

import tipoEvaluaciones from '../../constantes/Evaluaciones';
import { ASISTENCIA } from '../../configuraciones/endpoints';
import { tipoEstatus } from '../../constantes/estatus';
import { capitalizar } from '../../utilidades/functions';

const cabeceros = [
  { label: 'Nombre', transform: ({Empleado}) => Empleado ? `${Empleado?.Nombres} ${Empleado?.Apellidos}` : null },
  { label: 'Empresa', transform: ({Empleado}) => Empleado ? Empleado?.Empresa?.Nombre : null },
  { label: 'Sucursal', transform: ({Empleado}) => Empleado ? Empleado?.Sucursal?.Nombre : null },
  { label: 'Fecha Asistencia', transform: ({ FechaAsistencia }) => FechaAsistencia ? capitalizar(moment(FechaAsistencia).format('DD MMMM YYYY')) : null },
  { label: 'Evaluación', transform: ({Evaluacion}) => Evaluacion ? Evaluacion?.Nombre : null },
  { label: 'Estatus', transform: ({ Estatus: estatusAsistencia }) => estatusAsistencia ? <Estatus Cadena={estatusAsistencia.Nombre} Habilitado={false} /> : null },
];

const Asistencias = ({ history, location }) => {
  const [isSolicitudOpen, setIsSolicitudOpen] = useState(false);
    const [asistencias, setAsistencias] = useState('');

    const listadoRef = useRef(null);

    const handleInfoClick = useCallback(({ _id }) => {
        setAsistencias(_id);
        setIsSolicitudOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSolicitudOpen]);

    const handleDrawerClose = () => {
        setIsSolicitudOpen(false);
        if (listadoRef.current) listadoRef.current.refrescarListado();
    };

  return(
    <>
      <Listados
        titulo="Asistencias"
        placeHolder="Búsqueda por nombre"
        history={history}
        location={location}
        mostrar={{
          Subtitulo: true,
          SideBar: true,
        }}
        filtrosBusqueda={{
          fechaInicioFinal: true,
          evaluacion: true,
          estatus: true,
          busqueda: true,
          tipoEvaluacion: tipoEvaluaciones?.Asistencia,
          tipoEstatus: tipoEstatus?.RegistroCheck,
        }}
        cabeceros={cabeceros || []}
        apiKey={ASISTENCIA}
        customDetailAction={handleInfoClick}
        showActions
        actionDetail
      />
      <EmpleadoAsistencia
        isOpen={isSolicitudOpen}
        asistenciaId={asistencias}
        onClose={handleDrawerClose}
      />
    </>
  );
};

export default Asistencias;
