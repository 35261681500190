import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  contenedorContacto: {
    marginTop: 8,
    marginLeft: 0,
    backgroundColor: paleta.sidebar.active,
    paddingLeft: 15,
    paddingBottom: 30,

  },
  contenedorResponsable: {
    marginTop: 8,
    marginLeft: 0,
    backgroundColor: paleta.sidebar.active,
    paddingLeft: 15,
    paddingBottom: 122,
  },
  container: {
    paddingRight: 40
  },
  principales: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingBottom: 30,
  },
  smallText: {
    fontSize: 12,
    fontWeight: 'bold'
  }
}));

export default styles;
