import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { BsPersonLinesFill } from "react-icons/bs";

import Reporte from '../Reporte';
import axios from '../../configuraciones/axios';
import endpoints  from '../../configuraciones/endpoints';
import ReportePersonalDetalle from '../../componentes/ReportePersonalDetalle';
import { DescargarDocumento } from '../../utilidades/functions';
import { SIN_EMPLEADOS_SELECCIONADOS } from '../../configuraciones/mensajes';

const ReportePersonal = ({
  history, location, cabeceros, titulo, apiKey, cabecerosDrawer, apiKeyDrawer, tituloReporte
}) => {
  const [periodo, setPeriodo] = useState({});
  const [isDetalleOpen, setIsDetalleOpen] = useState(false);
  const [empleadoDetalle, setEmpleadoDetalle] = useState({});

  const empleadosSeleccionados = useRef({});

  const handleSelect = useCallback((seleccionado) => {
    const { _id } = seleccionado;
    let estaSeleccionado = true
    if (empleadosSeleccionados.current[_id]) {
      estaSeleccionado = false;
    }
    empleadosSeleccionados.current[_id] = estaSeleccionado;
  }, [empleadosSeleccionados]);

  const handleSelectAll = useCallback((empleados) => {
    const estanTodosSeleccionadoas = empleados.every(({ isSelected }) => isSelected);
    empleados.forEach(({ _id }) => {
      empleadosSeleccionados.current[_id] = estanTodosSeleccionadoas;
    });
  }, [])

  const handlePdf = useCallback((filtros) => {
    const { fechaInicio, fechaFin } = filtros;
    const seleccionados = Object.keys(empleadosSeleccionados.current)
      .filter((key) => !!empleadosSeleccionados.current[key]);

    if (!seleccionados.length) return toast.warn(SIN_EMPLEADOS_SELECCIONADOS);

    const promesas = seleccionados.map(async (empleado) => {
      const response = await axios.get(
        endpoints.base.url(apiKey, `empleado/${empleado}/pdf`),
        {
          responseType: 'arraybuffer',
          params: {
            fechaInicio,
            fechaFin,
          }
        }
      );
      DescargarDocumento({ response, tipoArchivo: 'pdf' });
    });

    Promise.all(promesas);
  }, [apiKey]);

  const handleDetalleClose = useCallback(() => {
    setIsDetalleOpen(false);
  }, []);

  const handleFiltrosChange = useCallback(({ fechaInicio, fechaFin }) => {
    setPeriodo({ fechaInicio, fechaFin });
  }, []);

  const onClearSelections = useCallback(() => {
    empleadosSeleccionados.current = {};
  }, []);

  const handleDetail = useCallback((empleado) => {
    setEmpleadoDetalle(empleado);
    setIsDetalleOpen(true);
  }, []);

  return (
    <>
      <Reporte
        titulo={titulo}
        history={history}
        location={location}
        filtrosBusqueda={{
          empresa: true,
          empleado: true,
        }}
        mostrar={{
          txtBusqueda: false,
          Agregar: false,
          SideBar: true,
        }}
        cabeceros={cabeceros || []}
        apiKey={apiKey}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        actionDetail={true}
        iconDetail={BsPersonLinesFill}
        customDetailAction={handleDetail}
        onFiltrosChange={handleFiltrosChange}
        customPdfAction={handlePdf}
        onClearSelections={onClearSelections}
        showActions
        cabeceroReporte
        seleccionable
        busquedaPorEmpleado
        pdf
      />
      <ReportePersonalDetalle
        isOpen={isDetalleOpen}
        onClose={handleDetalleClose}
        empleado={empleadoDetalle}
        fechaInicio={periodo.fechaInicio}
        fechaFin={periodo.fechaFin}
        cabeceros={cabecerosDrawer}
        apiKey={apiKeyDrawer}
        tituloReporte={tituloReporte}
      />
    </>
  );
};

export default ReportePersonal;
