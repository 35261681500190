import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
  chip: {
    backgroundColor: 'rgba(130, 189, 178, 0.35) !important',
    borderRadius: '5px !important',
    color: '#82BDB2 !important',
    paddingLeft: '15px !important',
    paddingRight: '15px !important',
  },
  chipSinSub: {
    backgroundColor: 'rgba(227, 165, 20, 0.35) !important',
    borderRadius: '5px !important',
    color: '#e3a514 !important',
    paddingLeft: '15px !important',
    paddingRight: '15px !important',
  },
  planActualBoton: {
    width: 'min-content',
    borderRadius: 8,
    backgroundColor: '#82BDB2',
    borderColor: '#82BDB2',
    '&:hover': {
      backgroundColor: '#638f87',
      borderColor: '#638f87',
    }
  },
  planBoton: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#38A5F4',
    borderColor: '#38A5F4',
    '&:hover': {
      backgroundColor: '#2e83bf',
      borderColor: '#2e83bf',
    }
  },
}));

export default styles;
