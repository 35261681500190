import { useCallback, useState, useEffect, useRef, memo } from "react"

import Switch from '@material-ui/core/Switch';
import propTypes from 'prop-types';
import { Box, Grid, List, Paper } from "@material-ui/core";
import { RiAddLine } from 'react-icons/ri'

import Button from "../Button";
import ConfiguracionPeriodo from "../ConfiguracionPeriodo";
import Typography from "../Typography";
import styles from './styles';
import { FRACCIONADO } from "../../constantes/tiposHorario";
import { PERIODO_HORARIO_INTERCEPTADO } from "../../configuraciones/mensajes";


const ConfiguracionHorario = ({ dia, onChange, errors, onError, value, tipoHorario }) => {

    const [errorPeriodoInterceptado, setErrorPeriodoInterceptado] = useState(false);
    const [numeroPeriodos, setNumeroPeriodos] = useState(value.Periodos.length || tipoHorario.NumeroPeriodos || 1);
    const [maxPeriodos, setMaxPeriodos] = useState(tipoHorario.NumeroPeriodos);

    const contenedorPeriodos = useRef(null);

    const classes = styles();

    //Maneja el cambio del switch
    const handleSwitch = useCallback(() => {
        onChange({ ...value, Habilitado: !value.Habilitado })
    }, [value, onChange])

    //Agrega un périodo en un horario de tipo fraccionado
    const handleClick = () => setNumeroPeriodos(current => {
        onChange({ ...value, Periodos: [...value.Periodos, { Periodo: current + 1, Dia: dia }] })
        return current + 1;
    })

    //valida que la configuración del horario sea correcta
    const validarConfiguracion = useCallback((configuracion) => {
        const { Periodos: periodos } = configuracion;

        let periodoInterceptado = false;
        periodos.some(({ Salida: SalidaAnterior, Periodo: PeriodoAnterior }) => {
            if (!SalidaAnterior) return false;

            periodoInterceptado = periodos.some(
                ({ Entrada, Periodo }) =>
                    Entrada && Periodo > PeriodoAnterior && (Entrada.isBefore(SalidaAnterior) || SalidaAnterior.format('HH:mm') === Entrada.format('HH:mm'))
            );

            return periodoInterceptado;
        });

        onError(current => ({ ...current, [dia]: periodoInterceptado }));
        setErrorPeriodoInterceptado(periodoInterceptado);
    }, [dia, onError]);

    //maneja el cambio del componente de configuración de périodo
    const handleConfiguracionPeriodoChange = useCallback((periodo) => {
        const nuevosPeriodos = value.Periodos.filter(({ Periodo }) => Periodo !== periodo.Periodo);
        nuevosPeriodos.push(periodo);
        const nuevaConfiguracion = { ...value, Periodos: nuevosPeriodos };
        validarConfiguracion(nuevaConfiguracion);
        onChange(nuevaConfiguracion);
    }, [value, onChange, validarConfiguracion]);

    useEffect(() => {
        if (tipoHorario.Clave !== FRACCIONADO) return;

        contenedorPeriodos.current && contenedorPeriodos.current.scrollTo({
            top: 0,
            left: contenedorPeriodos.current.scrollWidth,
            behavior: 'smooth',
        });
    }, [numeroPeriodos, tipoHorario]);

    useEffect(() => {
        setMaxPeriodos(() => {
            tipoHorario.NumeroPeriodos && onChange({ ...value, Periodos: value.Periodos.filter(({ Periodo }) => Periodo <= tipoHorario.NumeroPeriodos) })
            setNumeroPeriodos(tipoHorario.NumeroPeriodos || value.Periodos.length || 1);
            return tipoHorario.NumeroPeriodos;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoHorario]);

    return (
        <>
            <Grid container alignItems="center" spacing={2}>
                <Grid item lg={1}>
                    <Switch
                        checked={value.Habilitado}
                        onChange={handleSwitch}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </Grid>
                <Grid item lg={2}>
                    <Typography
                        component="h2">
                        {dia}
                    </Typography>
                </Grid>
            </Grid>
            <Paper square variant="outlined" >
                <Box alignItems="center" p={2}>
                    <Grid container spacing={1}>
                        <Grid item lg={10}>
                            <List ref={contenedorPeriodos} className={classes.scrollableListContainer}>
                                {[...Array(maxPeriodos || numeroPeriodos).keys()].map((periodo) =>
                                    <ConfiguracionPeriodo
                                        dia={dia}
                                        key={periodo + 1}
                                        habilitado={value.Habilitado}
                                        numeroPeriodo={periodo + 1}
                                        errors={errors}
                                        onError={onError}
                                        onChange={handleConfiguracionPeriodoChange}
                                        value={value.Periodos.find(({ Periodo }) => Periodo === periodo + 1) || { Periodo: periodo + 1, Dia: dia }}
                                        tipoHorario={tipoHorario.Clave}
                                        border={!tipoHorario.NumeroPeriodos}
                                    />
                                )}
                            </List>
                        </Grid>
                        {!maxPeriodos && <Grid container item alignItems="center" lg={1}>
                            <Button
                                variant="outlined"
                                icono={<RiAddLine className={classes.iconoAdd} size={20} />}
                                onClick={handleClick}
                                disabled={!value.Habilitado}
                                classesCustom={{ boton: classes.addButton }}
                            />
                        </Grid>}
                    </Grid>
                    {value.Habilitado && errorPeriodoInterceptado && <Typography className={classes.error}>
                       {PERIODO_HORARIO_INTERCEPTADO}
                    </Typography>}
                </Box>
            </Paper>
        </>
    )
}

ConfiguracionHorario.propTypes = {
    /** Dia de la configuración */
    dia: propTypes.string.isRequired,
    /** Callback para manejar el cambio de la configuración */
    onChange: propTypes.func.isRequired,
    /** Objeto donde se guardan los errores de la configuración */
    errors: propTypes.object.isRequired,
    /** Callback que reacciona a los errores */
    onError: propTypes.func.isRequired,
    /** Valor inicial de la configuración */
    value: propTypes.object.isRequired,
    /** Tipo de horario de la configuración */
    tipoHorario: propTypes.object.isRequired,
}

export default memo(ConfiguracionHorario);
