import propTypes from 'prop-types';
import { makeStyles, Grid, Button } from '@material-ui/core'
import { useCallback, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import TextField from '../TextField';
import IconoSend from './iconoSend.svg';

import useSocket from '../../hooks/useSocket';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 15,
  },
  container: {
    position: 'relative',
  },
  input: {
    '& input': {
      width: '100% !important',
      height: '56px !important',
    },
  },
  btnEnviar: {
    backgroundColor: '#3F8CFF !important',
    boxShadow: '0px 6px 6px 0px #3F8CFF43 !important',
    boxSizing: 'border-box',
    backdropFilter: 'blur(5px) !important',
    width: '55px !important',
    height: '44px !important',
    minWidth: '56px !important',
    minHeight: '43px !important',
    position: 'absolute',
    borderRadius: '14px !important',
    right: 5,
    bottom: 6,
    border: 'none !important'
  },
}));

const Input = ({ cliente, tipoMensaje }) => {
  const styles = useStyles();
  const { user: { nombre } } = useSelector(({ user }) => user);

  console.log('the name', nombre);

  const [mensaje, setMensaje] = useState({ mensaje: '', nombre });
  const { socket } = useSocket(cliente._id);

  const onEnviar = useCallback((e) => {
    e.preventDefault();
    if (mensaje.mensaje.trim() === '') return
    socket.emit('enviar_mensaje', `cliente-${cliente._id}`, {
      ...mensaje,
      Cliente: cliente._id,
      TipoMensaje: tipoMensaje,
      Contenido: mensaje.mensaje,
      FechaCreacion: moment(),
      cliente: {
        nombre: nombre,
        logo: cliente.logo,
      },
    });
    setMensaje((current) => ({ ...current, mensaje: '' }))
  }, [mensaje, socket, cliente, tipoMensaje, nombre]);

  return (
    <Grid container justifyContent="center" className={styles.root}>
      <Grid item xs={11}>
        <div className={styles.container}>
          <form noValidate onSubmit={onEnviar}>
            <TextField
              placeHolder="Ingresa tu mensaje aquí…"
              className={styles.input}
              name="mensaje"
              value={mensaje.mensaje}
              onChange={setMensaje}
              isHandleChange
            />
            <Button className={styles.btnEnviar} type="submit" disabled={mensaje.mensaje.trim() === ''}>
              <img alt="send" src={IconoSend} />
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  )
};

Input.propTypes = {
  /** Indica el tipo de mensaje (entrada / salida) */
  tipoMensaje: propTypes.oneOf(['E', 'S']).isRequired,
};

export default Input;
