import { makeStyles } from '@material-ui/core/styles';
import paleta from '../../configuraciones/paleta';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: 'auto',
    maxHeight: '75vh',
    overflow: 'auto',
    minHeight: props.disableStyles ? '100px' : '400px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      overflow: 'auto',
      height: '55vh',
    },
    border: props.disableStyles ? 'none' : `1px solid rgba(36, 36, 36, 0.1)`,
    position: 'relative',
    margin: props.disableStyles ? '50px' : 'none',
  }),
  border: {
    border: `1px solid rgba(36, 36, 36, 0.1)`,
  },
  noPaginator: {
    [theme.breakpoints.only('xs')]: {
      height: '70vh',
    },
  },
  table: (props) => ({
    borderCollapse: 'separate',
    width: '100%',
    borderSpacing: '0px',
    overflow: 'auto',
    maxHeight: '75vh',
    '& th, td': {
      borderBottom: props.disableStyles ? 'initial' : `1px solid ${paleta.tabla.bordertd}`,
      padding: theme.spacing(0, 1),
      '&:last-child': {
        borderRight: 'none',
      },
    },
    '& th': {
      padding: theme.spacing(1),
      position: 'sticky',
      backgroundColor: props.disableStyles ? 'initial' : paleta.sidebar.active,
      top: 0,
      zIndex: 10,
      userSelect: 'none',
      '& span': {
        fontSize: 12,
        textTransform: 'uppercase',
      },
    },
    '& td': {
      padding: theme.spacing(0.5, 2),
    },
  }),
  tablev2: {
    '& th, td': {
      borderRight: 'none',
    },
  },
  label: {
    marginLeft: 10,
    fontWeight: 600,
    fontSize: 16,
  },
  btnAdd: {
    backgroundColor: '#F4F9FD',
    color: '#1F2129',
    textTransform: 'none',
    boxShadow: 'none',
  },
  btnAction: {
    margin: theme.spacing(0.5),
  },
  actions: {
    minWidth: 100,
    textAlign: 'center',
  },
  rowHover: {
    cursor: 'pointer',
    backgroundColor: 'initial',
    '&:hover': {
      backgroundColor: '#F4F9FD',
    },
  },
  rowError: {
    backgroundColor: `${paleta.tabla.rowError}AA`,
    '&:hover': {
      backgroundColor: `${paleta.tabla.rowError}77`,
    },
  },
  showActionsFalse: {
    padding: '10px 16px !important',
  },
  rowAdicional: {
    backgroundColor: '#82bdb2',
    '&:hover': {
      backgroundColor: '#82bdb2',
    },
  },
  rowsAdicional: {
    width: '8%',
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      minWidth: 150,
      maxWidth: 177,
    },
    borderBottom: 'none !important'
  },
}));

export default useStyles;
