/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';

import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import Typography from '../../componentes/Typography';

import axios from '../../configuraciones/axios';
import endpoints, {
  EMPRESAS,
} from '../../configuraciones/endpoints';

import { findPropertysEmpty, objToFormData, requiredFIle } from '../../utilidades/functions';
import { EMAIL_REGEX, RFC_REGEX, NUMBER_REGEX, LETTERSSPECIALCHARACTER } from '../../utilidades/regex';

import estatus from '../../constantes/estatus';

import styles from './styles';
import { toast } from 'react-toastify';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';
import { UploadImage } from '../../componentes/ViewImage/UploadImage';

const Empresa = ({ history, match }) => {
  const classes = styles();

  const [formErrors, setFormErrors] = useState({});
  const [Empresa, setEmpresa] = useState({});
  const [image, setImage] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const { params: { _id } } = match;

  //funcion que se envía al componente hijo (UploadImage) para extraer la imagen (file)
  const handleUpload = (logo) => {
    setImage(logo);
    //Si logo es una cadena vacia, el usuario ha eliminado la imagen
    if(logo === ''){
      //hacemos que LogoURL sea '' para eliminar el archivo que existe en el registro
      setEmpresa({...Empresa, LogoURL: logo});
    }
  }

  //Método para regresar a la pantalla principal de empresas (tabla)
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/empresas',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  //Método para cancelar la operacion de agregar/editar un regristro
  const cancelar = useCallback(regresar, []);

  //Método para cargar los datos de un registro
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (_id) promesas.push(axios.get(endpoints.base.url(EMPRESAS, _id)));
    Promise.all(promesas).then((resultadoPromesas) => {
      const [EmpresaBD] = resultadoPromesas;
      //si el _id es undefined, no se cargan los datos y retornamos
      if (_id === undefined) {
        return
      }
      //Si el _id no existe en EmpresaBD, alertamos de que no existe el registro y regresa
      if (!EmpresaBD._id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }
      //Se cargan los datos del registro en los campos del formulario
      setEmpresa(EmpresaBD);
      resolve();
    })
      .catch(reject)
  });

  //Método para enviar datos al back (nuevo registro y editar)
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    //Control de errores del formulario
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmEmpresa'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;
    //forma en la que va a actuar la petición HTTP de axios
    const metodo = _id ? 'put' : 'post';
    //Constante para ingresar los datos de empresa, y parsear los datos para
    //poder recibirlo en el back
    const formData = objToFormData(Empresa);
    //Se agrega la imagen al formData
    formData.append('Imagen', image);

    try {
      //Se envían los datos al back con los datos del formulario
      await axios[metodo](endpoints.base.url(EMPRESAS), formData);
      regresar();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    consultarDatosIniciales();
  }, [_id]);

  return <Default
    titulo={Boolean(_id) ? 'Editar empresa' : 'Nueva empresa'}
    placeHolder={''}
    mostrarCabeceroFormulario={false}
    guardar={handleSubmit}
    cancelar={cancelar}>
    <Grid className={classes.container} container spacing={8} id="frmEmpresa">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid className={classes.formEmpresa} container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setEmpresa}
              name="Nombre"
              value={Empresa.Nombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
              error={formErrors.Nombre}
              helperText={requiredFIle(isSubmit, formErrors, 'Nombre')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Razón social"
              onChange={setEmpresa}
              name="RazonSocial"
              value={Empresa.RazonSocial}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="RFC"
              onChange={setEmpresa}
              name="RFC"
              value={Empresa.RFC}
              isHandleChange
              inputProps={{ regex: RFC_REGEX, maxLength: 14, regexonsubmit: 'true' }}
              error={formErrors.RFC}
              helperText={formErrors.RFC}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadImage
              onChange={handleUpload}
              label="Logo"
              url={Empresa?.Logo?.secure_url || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              placeHolder="Seleccione"
              name="Habilitado"
              onChange={setEmpresa}
              value={Empresa.Habilitado}
              options={estatus}
              isHandleChange
              error={formErrors.Habilitado}
              required
              helperText={requiredFIle(isSubmit, formErrors, 'Habilitado')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Typography className={classes.heading}>Datos de contacto</Typography>
        <Grid className={classes.formData} container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Calle y número"
              onChange={setEmpresa}
              name="CalleNumero"
              value={Empresa.CalleNumero}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Colonia"
              onChange={setEmpresa}
              name="Colonia"
              value={Empresa.Colonia}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Ciudad / Municipio / Estado"
              onChange={setEmpresa}
              name="CiudadMunicipioEstado"
              value={Empresa.CiudadMunicipioEstado}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Teléfono"
              onChange={setEmpresa}
              name="Telefono"
              value={Empresa.Telefono}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true'}}
              error={formErrors.Telefono}
              helperText={formErrors.Telefono}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo electrónico"
              onChange={setEmpresa}
              name="Correo"
              value={Empresa.Correo}
              isHandleChange
              inputProps={{ regex: EMAIL_REGEX, regexonsubmit: 'true', maxLength: 50 }}
              error={formErrors.Correo}
              helperText={formErrors.Correo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Typography className={classes.heading}>Responsable</Typography>
        <Grid className={classes.formData} container spacing={4} style={{ paddingBottom: 140 }}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setEmpresa}
              name="ResponsableNombre"
              value={Empresa.ResponsableNombre}
              isHandleChange
              inputProps={{ regex: LETTERSSPECIALCHARACTER, maxLength: 50, regexonchange: 'true' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Puesto"
              onChange={setEmpresa}
              name="ResponsablePuesto"
              value={Empresa.ResponsablePuesto}
              isHandleChange
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Celular"
              onChange={setEmpresa}
              name="ResponsableCelular"
              value={Empresa.ResponsableCelular}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, maxLength: 10, regexonsubmit: 'true'}}
              error={formErrors.ResponsableCelular}
              helperText={formErrors.ResponsableCelular}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo electrónico"
              onChange={setEmpresa}
              name="ResponsableCorreo"
              value={Empresa.ResponsableCorreo}
              isHandleChange
              inputProps={{ regex: EMAIL_REGEX, regexonsubmit: 'true', maxLength: 50 }}
              error={formErrors.ResponsableCorreo}
              helperText={formErrors.ResponsableCorreo}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Empresa);
